$loyalty-faq__font-color: $loyalty-gray-dark;

.loyalty-faq {
    &__heading {
        margin: 0 0 32px;
        color: $black;
        font-size: 24px;
        line-height: normal;
    }

    .dashboard__dropdown {
        .dropdown-list {
            &__label {
                padding: $spacer--medium 0;
                font-weight: $font-weight-base;
                background-color: $white;
                color: $loyalty-faq__font-color;

                &:after {
                    background-color: transparent !important; // sass-lint:disable-line no-important
                }

                &:hover {
                    &:after {
                        background: transparent;
                    }
                }
            }

            &__icon {
                display: none;
                fill: $loyalty-faq__font-color !important; // sass-lint:disable-line no-important

                @include mq($screen-m) {
                    display: flex;
                }
            }

            &__content {
                background-color: $white;
                color: $loyalty-faq__font-color;
            }

            &__item {
                &--open {
                    .dropdown-list__content {
                        padding: $spacer--extra-large 0 $spacer--extra-large;

                        @include mq($screen-m) {
                            padding: $spacer--extra-large 80px $spacer--extra-large 0;
                        }
                    }
                }
            }
        }
    }
}
