$container__max-width           : $max-content-width;
$container__margin              : $spacer;
$container__margin\large        : $spacer--large;
$container__margin\xlarge       : $spacer--extra-large;

.container {
    max-width: $container__max-width;
    margin: 0 $container__margin;

    @include mq($screen-l) {
        margin: 0 $container__margin\large;
    }

    @include mq($screen-xl) {
        margin: 0 $container__margin\xlarge;
    }

    @include mq($container__max-width + (2 * $container__margin\xlarge)) {
        margin: 0 auto;
    }
}
