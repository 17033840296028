.dashboard {
    @include mq($screen-m) {
        padding: 30px 0;
    }

    &__section {
        margin-bottom: $spacer--large;
        max-width: 100%;
    }

    &__sidebar {
        display: none;
        @include mq($screen-m) {
            padding-left: $spacer;
            padding-bottom: $spacer--extra-large;
        }
        @include mq($screen-l) {
            display: block;
        }

        &:first-child {
            @include mq($screen-m) {
                padding-left: 5px;
            }
        }

        &:last-child {
            @include mq($screen-m) {
                padding-right: 5px;
            }
        }

        &--loyalty {
            margin-top: 75px;
        }
    }

    &__content {
        @include mq($screen-m) {
            padding-right: $spacer;
        }
    }

    &__loyalty {
        padding: 0;

        .loyalty-awards,
        .loyalty-invoices {
            .pager {
                &__item {
                    &--current {
                        .button {
                            background: $loyalty-orange;
                        }
                    }

                    .button {
                        &:hover,
                        &:focus {
                            color: $loyalty-orange;
                        }
                    }

                    .button--icon {
                        &:hover,
                        &:focus {
                            .icon {
                                fill: $loyalty-orange;
                            }
                        }
                    }
                }

                &__link-nav {
                    &:hover,
                    &:focus {
                        color: $loyalty-orange;

                        .icon {
                            fill: $loyalty-orange;
                        }
                    }
                }
            }
        }
    }
}
