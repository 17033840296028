$summary__padding                   : $spacer--extra-large $spacer--medium;
$summary__list-item-margin--unfixed : $spacer--large;
$summary__label-margin              : 0 0 $spacer--medium 0;
$summary__text-margin               : 0 0 0 0;
$summary__col2-margin               : $spacer--large 0;
$summary__col2-padding              : $spacer--large 0;
$summary__heading-margin            : 0 0 $spacer--large 0;
$summary__breakpoint                : $screen-xl;

.summary {
    display: flex;
    flex-flow: row wrap;
    padding: $summary__padding;

    @include mq($summary__breakpoint) {
        flex-flow: row nowrap;
    }

    &__col {
        display: flex;
        flex: 1 1 100%;
        flex-wrap: wrap;

        &:nth-child(2) {
            padding: $summary__col2-padding;
            margin: $summary__col2-margin;
            border-bottom: solid 1px $gray-lightest;

            @include mq($summary__breakpoint) {
                margin: 0;
                padding: 0;
                border: none;
            }
        }

        @include mq($summary__breakpoint) {
            flex: 1 1 33.333%;
            flex-wrap: nowrap;
        }

        &--sum {
            flex: 0 1 100%;
            @include mq($screen-m) {
                flex-basis: 320px;
            }
            @include mq($summary__breakpoint) {
                flex: 0 0 400px;
            }
            .summary__list {
                flex-wrap: wrap;
            }

            .summary__list-item {
                margin: 0 0 $spacer--medium 0;
                padding: 0 0 $spacer--medium 0;

                &:nth-child(1),
                &:nth-child(2) {
                    @include mq($summary__breakpoint) {
                        border-bottom: solid 1px $gray-lightest;
                    }
                }
            }
        }

        &--discount {
            @include mq($summary__breakpoint) {
                flex: 0 0 210px;
            }
        }
        &--delivery {
            flex-wrap: wrap;
            .select {
                margin-bottom: $spacer--large;
                color: $gray-dark;
                flex-basis: 100%;
                &__label {
                    line-height: 1.15;
                    color: $gray-dark;
                    text-transform: inherit;
                    font-weight: $font-weight-thin;
                }
            }
            .choices {
                z-index: initial;
                &__item {
                    &--disabled {
                        display: none;
                    }
                }
            }
        }
    }

    &__list {
        display: flex;
        align-content: flex-start;
        flex: 1 1 100%;
        list-style: none;
        padding: 0;
        margin: 0;

        &--unfixed {
            .summary__list-item {
                flex: 0 0 auto;

                &:nth-child(2n-1) {
                    margin: 0 $summary__list-item-margin--unfixed 0 0;
                }
                &:nth-child(2n) {
                    margin: 0 0 0 $summary__list-item-margin--unfixed;
                }
            }
        }
    }

    &__list-item {
        display: flex;
        flex-direction: column;
        flex: 1 1 50%;
        padding: 0;
        margin: 0;
    }

    &__label {
        display: flex;
        justify-content: flex-end;
        margin: $summary__label-margin;
        font-family: $font-family-base;
        font-size: $font-size-base;
        font-weight: $font-weight-thin;
        color: $gray-dark;
    }

    &__tooltip {
        width: 14px;
        height: 14px;
        margin: 0 $spacer;
        fill: #afafaf;
        cursor: pointer;
    }

    &__text {
        margin: $summary__text-margin;
        font-family: $font-family-base;
        font-size: $font-size-extra-large;
        font-weight: $font-weight-bold;
        line-height: 1.5;
        letter-spacing: 0.7px;
        text-align: right;
        color: $gray-darker;

        &--normal {
            font-weight: normal;
        }

        &--gray {
            color: $gray-dark;
        }

        &--small {
            font-size: 16px;
        }
    }

    &__heading {
        font-family: $font-family-base;
        font-size: $font-size-extra-large;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: 0.7px;
        text-align: left;
        color: $gray-darker;
        padding: 0;
        margin: $summary__heading-margin;

        @include mq($summary__breakpoint) {
            margin: 25px 0 25px $spacer--extra-large;
        }
    }

    &__select {
        display: flex;
        flex-basis: 100%;
        .select {
            flex-basis: 50%;
            padding: 0 $spacer;
            &__label {
                text-transform: inherit;
                font-weight: $font-weight-thin;
                color: $gray-dark;
            }
        }
    }

    &__delivery-item {
        width: 100%;
        margin-bottom: $spacer--medium;

        .select__field-item {
            padding: 0 30px 0 $spacer--medium;
        }
    }

    &__delivery-form {
        display: none;

        &--is-Open {
            $grid__gutter: 4px;

            display: flex;
            margin-left: -($grid__gutter * 0.5);
            margin-right: -($grid__gutter * 0.5);

            .grid__column {
                padding-left: ($grid__gutter * 0.5);
                padding-right: ($grid__gutter * 0.5);
            }

            .input {
                margin-bottom: 4px;
            }
        }
    }
}
