.loading {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    min-height: 400px;
    z-index: 10;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $white;
        opacity: 0.8;
        z-index: 1;
    }

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 999;
        width: 46px;
        height: 50px;
        margin: -23px 0 0 -25px;
        background-image: url('../images/icon-sprite.svg#loader-view');
        background-size: 46px 50px;
        transition: $transition-base;
        animation: rotating 400ms linear infinite;
    }

    &--fixed {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999;
    }

    &--small {
        min-height: 0;

        &:before {
            background: transparent;
        }

        &:after {
            width: 30px;
            height: 30px;
            margin: -15px 0 0 -15px;
            background-size: 30px 30px;
        }
    }
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
