.header-button {
    @extend .button;
    @extend .button--icon;

    height: 100%;
    min-width: $header__size;

    padding: 0 13px;
    margin: 0;

    background-color: $white;
    color: $font-color-base;
    font-weight: $font-weight-base;

    &:focus,
    &:hover {
        background-color: transparent;
        color: $font-color-base;

        .icon {
            fill: $color-primary;
        }
    }

    @include mq($screen-xl) {
        min-width: $header__size;
    }

    .icon {
        fill: $color-primary;
    }

    &__open {
        &.icon {
            fill: $color-accent;
        }
    }

    &__text {
        display: none;
        color: $gray-darker-secondary;
        font-weight: $font-weight-bold;
        text-transform: none;

        @include mq($screen-m) {
            display: inline;
            margin-left: $spacer--medium;
        }

        &--account {
            max-width: 36px;
        }
    }

    &__back {
        @include mq($screen-m) {
            display: none;
        }

        .icon {
            width: 24px;
            height: 24px;
        }
    }

    &--side-menu {
        height: 48px;

        @include mq($screen-xl) {
            display: none;
        }

        .header-button__close {
            display: none;
        }
    }

    &--search {
        position: absolute;
        top: 0;
        right: 0;
        width: 55px;
        height: 54px;
        min-width: auto;
        padding: 0;
        background-color: $color-primary;

        @include mq($screen-m) {
            position: static;
            top: auto;
            right: auto;
            height: 100%;
            width: 100%;
            flex: 0 0 44px;
            order: 3;
        }

        @include mq($screen-m) {
            flex-basis: 56px;
        }

        .icon {
            fill: $white;
        }
    }
}
