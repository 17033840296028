.loyalty-regulations {
    &__content {
        h4 {
            text-align: center;
            font-size: 25px;
            margin-bottom: $spacer--extra-large;
        }
        h5 {
            text-align: center;
            font-size: $font-size-medium;
        }
        h6 {
            text-align: center;
        }
        ol {
            color: black;
        }
        ul {
            color: black;
            list-style: none;
        }
        .simplebar-content {
            margin-right: 30px !important; // sass-lint:disable-line no-important
        }
    }

    &__button-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include mq($screen-s) {
            flex-direction: row;
        }
    }

    &__button {
        &:first-child {
            margin: 0 0 $spacer 0;
        }

        @include mq($screen-s) {
            flex-basis: 50%;

            &:first-child {
                margin: 0 $spacer 0 0;
            }
        }
    }
}
