.registration {
    padding: $spacer--large 0;
    margin: 0;
    @include mq($screen-l) {
        padding: 30px 0;
    }

    &__form {
        display: block;
        position: relative;
        width: 100%;
    }

    &__title {
        padding: 0 $spacer--large;
        text-transform: uppercase;
    }

    .fieldset__wrapper {
        padding: 20px 15px;
        max-width: 584px;
        @include mq($screen-m) {
            padding: 25px 15px;
        }
    }

    &__fieldset-inner {
        margin-top: 25px;

        .fieldset__legend {
            padding: 10px 0;
        }
    }

    &__fieldset-grid {
        margin-bottom: 25px;
        margin-left: -5px;
        margin-right: -5px;
        .grid__column {
            padding-right: $spacer;
            padding-left: $spacer;
        }
    }

    &__legend-required-text {
        &:after {
            content: '*:';
        }
    }

    .input {
        margin-bottom: 10px;
    }

    .select {
        margin-bottom: 25px;
    }

    .select__label {
        color: $gray-dark;
        font-weight: $font-weight-thin;
        text-transform: none;
    }

    .file-upload {
        &:last-of-type {
            margin-bottom: 30px;
        }
    }

    &__info-icon {
        position: relative;
        display: inline-block;
        top: 2px;
        width: 14px;
        height: 14px;
        fill: $gray-lighter;
    }

    &__required-note {
        padding: 15px;
        color: $gray-dark;
        font-weight: $font-weight-thin;
    }

    &__button {
        width: calc(100% - 30px);
        margin: 0 15px 20px 15px;
        @include mq($screen-l) {
            width: 100%;
            margin: 0 0 15px 0;
        }
    }

    &__label-marked {
        font-weight: $font-weight-bold;
        color: $color-primary;
        cursor: pointer;
    }

    &__tooltip {
        width: 15px;
        height: 15px;

        @include mq($max-screen: $screen-m) {
            &:focus,
            &:hover {
                .hint__content {
                    transform: translateX(-90%) translateY(-8px);
                }
            }
        }

        .hint__content {
            min-width: 200px;
            max-width: 300px;
            white-space: normal;
            line-height: 1.3;
        }
    }

    &__tooltip-icon {
        position: relative;
        width: 15px;
        height: 15px;
        fill: $gray-light;
    }

    &__print-only {
        display: none;
    }

    &__customer-type-wrapper {
        margin-bottom: 0 !important;
    }

    &__customer-type-info,
    &__phone-info {
        display: block;
        padding: $spacer 0;
        font-size: 12px;
        font-weight: $font-weight-bold;
        color: $red-dark;
    }

    &__customer-type-info {
        margin-bottom: $spacer--medium;
    }

    &__vat-number-wrapper {
        position: relative;
        .loader {
            position: absolute;
            top: 0;
            right: 0;
            width: 50px;
            height: 50px;
            transform: scale(0.5);
        }
    }

    @media print {
        .button {
            display: none;
        }

        .file-upload {
            height: 30px;

            &__name {
                height: 30px;
                padding: $spacer--medium;
            }
        }

        .registration__print-only {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            width: 100%;
            padding: $spacer--large;
            color: $gray-dark;
            font-weight: $font-weight-thin;
        }
        .registration__print-info {
            width: 100%;
            line-height: 1.4;
        }
        .registration__print-field {
            width: 40%;
            margin-top: 120px;
            padding-top: $spacer--large;
            border-top: 1px solid $border-color-base;
            text-align: center;
        }

        div#_hj_feedback_container { //sass-lint:disable-line no-ids
            display: none;
        }
    }
}
