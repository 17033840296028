$filter-menu__font-color-dark: #252525;

.filter-menu {
    padding: 0;
    margin-bottom: $spacer--large;
    border: solid 1px $border-color-base;

    &__list {
        display: block;
        width: 100%;
        list-style-type: none;
        padding: 0;
        margin: 0;
        transition: $transition-base;
        max-height: 640px;
        overflow: auto !important; // sass-lint:disable-line no-important
    }

    &__item {
        position: relative;
        color: $font-color-base;
        cursor: pointer;
    }

    &__label {
        @extend .link;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 45px;
        padding: 0 $spacer--extra-large 0 $spacer--medium;
        color: $filter-menu__font-color-dark;
        font-size: $font-size-small;
        text-transform: uppercase;
        transition: all 0.5s;

        &:hover,
        &:focus {
            color: $filter-menu__font-color-dark;
            text-decoration: none;
            background-color: $gray-lightest;
        }

        &--level0,
        &--level1 {
            padding: 0 30px 0 $spacer--medium;
            border-bottom: 1px solid $border-color-base;

            .filter-menu__item:last-child > &:not(.filter-menu__label--open) {
                border-bottom: 0;
            }
        }

        &--level1,
        &--level2 {
            padding: 0 30px 0 $spacer--medium;
            color: $gray;
            text-transform: none;
            &:focus,
            &:hover {
                color: $filter-menu__font-color-dark;
                background-color: $white;
            }
        }

        &--level2 {
            &.filter-menu__label--open {
                font-weight: $font-weight-bold;
            }
        }

        &--level3 {
            color: $gray;
            text-transform: none;
            &:focus,
            &:hover {
                background-color: $color-primary;
                color: $white;
            }
        }

        &--current {
            background-color: $color-primary;
            color: $white;
        }
    }

    &__label-main-icon {
        margin: 0 $spacer--large;
        width: 20px;
    }

    &__icon {
        @extend .icon;
        position: absolute;
        right: $spacer--extra-large;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 10px;
        height: 10px;
        fill: $filter-menu__font-color-dark;
        transition: all 0.3s;
        max-width: none;

        .filter-menu__inner-list & {
            fill: $gray-light;
            right: 25px;
        }

        .filter-menu__label:hover > &,
        .filter-menu__label:focus > & {
            fill: $filter-menu__font-color-dark;
        }
        .filter-menu__label--open:not(.filter-menu__label--level1) & {
            transform: rotate(180deg);
        }
        .filter-menu__label--open.filter-menu__label--level1 & {
            transform: rotate(45deg);
        }
        .filter-menu__label--current:not(.filter-menu__label--level0) > & {
            fill: $white;
        }
    }

    &__inner-list {
        @extend .filter-menu__list;
        max-height: none;
        padding-left: 20px;
        // overwriting inline style from scrollbar
        &--level1 {
            max-height: none;
            padding-left: 30px;
            padding-right: $spacer--extra-large;
            width: calc(100% + 20px) !important; // sass-lint:disable-line no-important
            border-bottom: 1px solid $border-color-base;
        }

        &--level3 {
            padding-left: $spacer--large;
            @include mq($screen-xxl) {
                padding-left: $spacer--extra-large;
            }
        }
    }

    .vb >
    .vb-dragger,
    .vb-visible:after {
        right: 0;
    }
}
