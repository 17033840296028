.data-dialog {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: flex-end;
    &__trigger {
        display: flex;
        align-items: center;
        border: 0 none;
        padding: 0 $spacer;
        background-color: transparent;
        font-weight: $font-weight-bold;
        font-size: $font-size-base;
        color: $color-primary;
        white-space: nowrap;
        text-transform: lowercase;
        cursor: pointer;

        &:disabled {
            color: $gray-light;
            cursor: not-allowed;

            .data-dialog__trigger-icon {
                fill: $gray-light;
            }
        }
    }
    &__trigger-icon {
        width: 10px;
        height: 10px;
        margin-left: $spacer;
        fill: $color-primary;
    }
    &[gray] {
        .data-dialog__trigger {
            color: $gray-light;
        }
        .data-dialog__trigger-icon {
            fill: $gray-light;
        }
    }
    &[green] {
        .data-dialog__trigger {
            color: $color-success;
        }
        .data-dialog__trigger-icon {
            fill: $color-success;
        }
    }
    &[left] {
        justify-content: flex-start;
        .data-dialog__trigger {
            padding: 0;
        }
    }
    &[uppercase] {
        .data-dialog__trigger {
            text-transform: uppercase;
        }
    }
    &[medium] {
        .data-dialog__trigger {
            font-size: $font-size-small;
        }
    }
    &[small] {
        .data-dialog__trigger {
            font-size: $font-size-extra-small;
        }
    }
    &[extra-small] {
        .data-dialog__trigger {
            font-size: $font-size-extra-small;
            @include mq($screen-m) {
                padding: 0;
                font-size: 12px;
            }
        }
    }
}
