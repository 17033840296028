$list__style                      : none;
$list__margin                     : 0;
$list__padding                    : 0;
$list__item-padding               : 5px 0;
$list__content-padding            : 10px;
$list__label-padding              : 0 5px 5px 0;
$list__icon-color                 : $white;

// Horizontal
$list__padding--horizontal        : 10px;

// Horizontal\@medium
$list__padding--horizontal\@medium: 10px;

// With icon
$list__padding--with-icon         : 10px;
$list__icon-size--icon            : 30px;

// With image
$list__width--image               : 16px;

// Divided
$list__border--divider            : 1px solid $gray-dark;

.list {
    margin: $list__margin;
    padding: $list__padding;
    list-style: $list__style;

    &--center {
        justify-content: center;
        text-align: center;
    }

    &--horizontal {
        display: flex;
        flex-flow: row wrap;
        .list__item {
            padding: $list__padding--horizontal;

            &:first-child {
                padding-top: $list__padding--horizontal;
            }
            &:last-child {
                padding-bottom: $list__padding--horizontal;
            }
        }
    }

    &--horizontal\@medium {
        @include mq($screen-m) {
            display: flex;
            flex-flow: row nowrap;

            .list__item {
                padding: $list__padding--horizontal\@medium;

                &:first-child {
                    padding-top: $list__padding--horizontal\@medium;
                }
                &:last-child {
                    padding-bottom: $list__padding--horizontal\@medium;
                }
            }
        }
    }

    &--with-icon {
        justify-content: center;

        .list__item {
            display: flex;
            align-items: center;
            padding: 0;

            &:first-child {
                padding-top: 0;
            }
            &:last-child {
                padding-bottom: 0;
            }
        }
        .list__icon-link {
            padding: $list__padding--with-icon;
        }
        .list__icon {
            fill: $list__icon-color;
            width: $list__icon-size--icon;
            height: $list__icon-size--icon;
        }
    }

    &--with-image {
        .list__item {
            display: flex;
        }
        .list__image {
            width: $list__width--image;
            height: auto;
        }
    }

    &--divided {
        .list__item {
            border-bottom: $list__border--divider;
            &:last-child {
                border-bottom: none;
            }
        }

        &.list--horizontal {
            .list__item {
                border-bottom: none;
                border-right: $list__border--divider;
                &:last-child {
                    border-right: none;
                }
            }
        }

        &.list--horizontal\@medium {
            .list__item {
                @include mq($screen-m) {
                    border-bottom: none;
                    border-right: $list__border--divider;
                }
                &:last-child {
                    @include mq($screen-m) {
                        border-right: none;
                    }
                }
            }
        }
    }

    &--description,
    &--description-columns {
        .list__label,
        .list__value {
            padding: 0;
            line-height: 1.53;
            font-size: $font-size-base;
        }
        .list__label {
            margin-right: $spacer;
            font-weight: $font-weight-bold;
            color: $gray-darker;
        }
        .list__value {
            color: $gray-dark;
            font-weight: $font-weight-normal;
        }
    }

    &--description {
        display: inline-block;

        .list__label,
        .list__value {
            float: left;
        }
        .list__label {
            clear: left;
        }
    }

    &--description-columns {
        display: flex;
        flex-flow: row wrap;

        .list__item {
            display: flex;
            flex-flow: row wrap;
            flex: 1 1 100%;
            justify-content: space-between;
            align-items: center;
            padding: 0;
        }

        .list__label,
        .list__value {
            flex: 1 0 auto;
        }

        .list__value {
            text-align: right;
        }

        .list__item--narrow {
            justify-content: flex-start;

            .list__label,
            .list__value {
                flex: none;
            }
        }
    }

    &--even-odd {
        > .list__item {
            &:nth-of-type(n) {
                background-color: $gray-lightest;
            }
            &:nth-of-type(2n) {
                background-color: $white;
            }
        }
    }

    &--posts {
        display: flex;
        flex-direction: column;

        @include mq($screen-xxl) {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .list__item {
            display: flex;
            flex-direction: column;
            padding: 0;
            margin-bottom: 25px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.07);

            @include mq($screen-xxl) {
                flex-basis: calc(50% - 50px);
            }
        }

        .list__pre-title {
            font-size: $font-size-small;
            line-height: 30px;
            color: $gray-dark;

            @include mq($screen-m) {
                font-size: $font-size-medium;
            }
        }

        .list__title {
            font-weight: $font-weight-bold;
            font-size: $font-size-medium;
            line-height: 30px;
            margin-bottom: $spacer--medium;
            color: $gray-darker;

            @include mq($screen-m) {
                font-size: $font-size-extra-large;
            }
        }

        .list__sub-title {
            font-size: $font-size-small;
            line-height: 30px;
            color: $gray-dark;
            text-transform: uppercase;
            margin-bottom: $spacer--large;
        }

        .list__description {
            font-size: $font-size-small;
            line-height: 24px;
            color: $gray-dark;
            margin-bottom: $spacer--extra-large;
        }

        .list__link-wrapper {
            align-self: flex-start;
            margin-bottom: 25px;

            @include mq($screen-xxl) {
                flex-grow: 1;
                display: flex;
                align-items: flex-end;
            }
        }

        .list__link {
            width: auto;
        }

        .list__link-icon {
            width: 12px;
            height: 12px;
        }
    }

    &--ct-data {
        color: $gray-dark;
        margin-bottom: 45px;

        .list__item {
            line-height: 30px;
            font-size: $font-size-medium;
            padding-top: 0;
        }

        .list__value {
            font-weight: $font-weight-bold;

            a {
                color: $gray-dark;
            }
        }
    }

    &__item {
        display: list-item;
        padding: $list__item-padding;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
        }
    }

    &__content {
        padding-left: $list__content-padding;
    }
}
