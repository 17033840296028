$dashboard-document-details__columns-margin: 0 $spacer--medium $spacer--medium $spacer--medium;
$dashboard-document-details__heading-margin: 0 0 $spacer--extra-large $spacer--medium;

.dashboard-document-details {
    &__heading {
        flex: 0 0 auto;
        font-size: 23px;
        font-weight: $font-weight-bold;
        color: $gray-darker;
        background-color: $white;
        text-transform: none;
        padding: 0;
        margin: $dashboard-document-details__heading-margin;
    }

    &__actions {
        flex: 0 0 auto;
        order: 1;
    }

    &__columns {
        margin: $dashboard-document-details__columns-margin;
        order: 2;
    }

    &__action {
        flex: 1 1 auto;
        margin: 0 $spacer--extra-large;
        padding: 0;
        justify-content: center;

        &:nth-child(n+2):before {
            display: block;
            content: '';
            position: relative;
            left: -20px;
            height: 11px;
            width: 0;
            border-left: 1px solid $gray-lighter;
        }
        &:nth-child(1) {
            margin-left: $spacer--medium;
        }
    }

    &__input {
        font-weight: $font-weight-bold;
        &:not(:disabled) {
            min-width: 200px !important; // sass-lint:disable-line no-important
        }
    }

    &__list {
        flex: 1 1 33.333%;

        &:nth-child(5) {
            flex: 1 1 100%;
        }
    }
}
