/* sass-lint:disable-all */

/**
 * source: hint-rounded.scss
 *
 * Defines rounded corner tooltips.
 *
 * Classes added:
 * 	1) hint--rounded
 *
 */

.#{$hintPrefix}rounded {
	&:after {
		border-radius: 4px;
	}
}
