.notification {
    min-width: 100%;
    padding: $spacer--large;
    margin-bottom: $spacer--medium;
    background-color: $gray-lightest;
    &__content {
        background-color: $white;
        padding: $spacer--large;
        display: flex;
        flex-flow: row wrap;
        @include mq($screen-m) {
            flex-flow: row nowrap;
        }
    }
    &__col-1 {
        flex: 1 1 100%;
        @include mq($screen-m) {
            flex: 0 0 50%;
            order: 2;
        }
    }
    &__col-2 {
        flex: 1 1 100%;
        @include mq($screen-m) {
            flex: 0 0 50%;
            order: 1;
        }
    }
    &__date {
        width: 100%;
        padding-bottom: $spacer--large;
        @include mq($screen-m) {
            text-align: right;
        }
    }
    &__title {
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
        padding-bottom: $spacer--large;
    }
    &__description {
        font-size: $font-size-base;
        padding-bottom: $spacer--large;
    }
    &__body {
        font-size: $font-size-base;
    }
}
