.orders-list {
    position: relative;

    .dashboard-document-details,
    .dashboard-tab,
    .summary {
        margin: 0 -8px;
    }
    .search-bar {
        width: 100%;
        padding: 0 0 $spacer--large;
        margin: 0;
        @include mq($screen-m) {
            max-width: 300px;
            float: right;
        }
    }
    &__button-back {
        display: inline-flex;
        margin: 0;
        padding: 0;
        .icon {
            width: 7px;
            height: 12px;
        }
    }

    .document-filters {
        padding: $spacer--large 0;
        margin-bottom: $spacer--large;
        border: 1px solid $border-color-base;
        border-width: 1px 0;
    }
}
