.loyalty-brochures {
    display: flex;
    flex-flow: row wrap;
    padding: $spacer--extra-large 0;

    &__brochure {
        flex: 0 1 50%;
        padding: $spacer;
        @include mq($screen-l) {
            flex: 0 1 25%;
        }
    }
}
