.dashboard {
    &__promotion-list {
        font-size: $font-size-large;
        margin-bottom: $spacer--large;
        font-weight: $font-weight-bold;

        .list__item {
            display: flex;
            justify-content: space-between;
            height: 37px;
            align-items: center;

            span {
                color: $gray-light;
                font-weight: $font-weight-normal;
            }

            &--right {
                color: $green;
            }
        }
    }
}
