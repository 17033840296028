.mega-menu {
    &--product {
        position: absolute;
        display: flex;
        top: 0;
        flex-direction: column;
        width: 270px;
        padding: 25px;
        min-height: 450px;
        height: 100%;
        background: $white;

        .mega-menu__product-wrapper {
            position: relative;
        }

        .mega-menu__product-badges {
            position: absolute;
            top: 5px;
            left: 5px;
        }

        .mega-menu__product-heading {
            margin: $spacer--medium 0;
            font-size: $font-size-medium;
            font-weight: $font-weight-bold;
            line-height: 20px;
            color: $gray-darker;
        }

        .image {
            max-height: 200px;
        }

        .mega-menu__product-list {
            margin-bottom: $spacer--medium;
            .list__item {
                line-height: 1;
            }
            .list__label {
                font-size: $font-size-small;
                font-weight: $font-weight-normal;
            }
            .list__value {
                color: $gray-darker;
                font-size: $font-size-small;
                font-weight: $font-weight-bold;
            }
            &--price {
                .list__item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                .list__value {
                    font-size: $font-size-extra-large;
                }
            }
        }

        & > .mega-menu__inner-label {
            display: none;
        }
    }
}
