$range__padding: 80px $spacer--extra-large $spacer--extra-large;
$range__margin: 0 25px;
$range__height: 2px;
$range__handler-width: 17px;
$range__handler-height: 17px;
$range__handler-bg-color: $color-primary;
$range__handler-border: none;
$range__handler-border-radius: 50%;
$range__handler-box-shadow: none;
$range__handler-offset-top: -1 * $range__handler-width * 0.5;
$range__track-bg-color: $gray-light;
$range__track-bg-color--active: $color-primary;
$range__track-border-radius: $border-radius;
$range__track-border: none;
$range__track-box-shadow: none;
$range__dash-padding: 0 $spacer--medium 0 $spacer;
$range__suffix-padding: 0 $spacer;

.range-filter {
    padding: $range__padding;
    overflow: auto;

    &__slider {
        margin: $range__margin;
    }

    &__inputs-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0;
    }

    &__dash {
        text-align: center;
        padding: $range__dash-padding;
    }

    &__suffix {
        padding: $range__suffix-padding;
    }

    &__label {
        @include visually-hidden();
    }

    &__input {
        width: auto;
        flex-grow: 1;
        margin-bottom: 0;
    }

    &__field {
        padding: $spacer--medium;
    }

    // Disable spin buttons in number input type
    input[type="number"]::-webkit-outer-spin-button, // sass-lint:disable-line no-vendor-prefixes
    input[type="number"]::-webkit-inner-spin-button { // sass-lint:disable-line no-vendor-prefixes
        -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes
        margin: 0;
    }
    input[type="number"] { // sass-lint:disable-line no-vendor-prefixes
        -moz-appearance: textfield; // sass-lint:disable-line no-vendor-prefixes
    }

    &--simple {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        @include mq($screen-m) {
            flex-flow: row nowrap;
        }
        .range-filter__inputs-wrapper {
            flex: 0 1 80px;
        }
        .range-filter__inputs-wrapper--right {
            @include mq($screen-m) {
                order: 1;
            }
        }
        .range-filter__slider  {
            flex: 1 1 100%;
            margin: 0;
            @include mq($screen-m) {
                flex: 1 1 auto;
                margin-left: $spacer--medium;
                margin-right: $spacer--large;
            }
            .noUi-tooltip {
                display: none;
            }
        }
    }
}

.noUi-connect {
    background-color: $range__track-bg-color--active;
}

.noUi-horizontal .noUi-tooltip {
    bottom: 200%;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    padding: 10px 15px;
    color: $gray-dark;
    font-weight: $font-weight-thin;

    &:after {
        content: '';
        position: absolute;
        bottom: -11px;
        left: 50%;
        transform: translateX(-50%) rotate(-45deg);
        width: 20px;
        height: 20px;
        background: $white;
        box-shadow: inset 1px -1px 0 0 rgba(0, 0, 0, 0.24);
    }
}

.noUi-target {
    background-color: $range__track-bg-color;
    box-shadow: $range__track-box-shadow;
    border: $range__track-border;
    border-radius: $range__track-border-radius;
    height: $range__height;
}

.noUi-handle {
    &:hover {
        cursor: pointer;
    }
    .noUi-horizontal & {
        border-radius: $range__handler-border-radius;
        background: $range__handler-bg-color;
        width: $range__handler-width;
        height: $range__handler-height;
        border: $range__handler-border;
        box-shadow: $range__handler-box-shadow;
        top: $range__handler-offset-top;
        &:before,
        &:after {
            display: none;
        }
        &:focus {
            outline: none;
            .noUi-tooltip {
                display: block;
            }
        }
    }
}

.noUi-extended {
    padding-right: 17px;
}

.noUi-extended .noUi-handle {
    left: 0;
}

.noUi-extended .noUi-origin {
    right: -24px;
}
