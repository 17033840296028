.loyalty-special-actions {
    margin: 0 0 32px;

    &__buttons {
        display: flex;
        justify-content: flex-end;
    }

    &__wrapper {
        display: grid;
        grid-template-columns: 1fr;
        gap: $spacer--semi-large; //sass-lint:disable-line  no-misspelled-properties
        margin: 0 0 $spacer--extra-large;

        @include mq($screen-m) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    img {
        max-width: 458px;
        margin: 0 0 $spacer--extra-large;
    }
}
