$cms-section__header-font-size             : 24px;
$cms-section__header-line-height           : 1.667;
$cms-section__header-line-height\@medium   : 2;
$cms-section__header-margin-bottom         : $custom-cms-spacer--large;

$cms-section__text-margin-bottom          : 64px;

$cms-section-margin                       : 0 $custom-cms-spacer--medium 110px;

.cms-section {
    margin: $cms-section-margin;

    @include mq($custom-cms-section-max-width) {
        margin: auto;
        max-width: $custom-cms-section-max-width;
    }

    &__header {
        margin-bottom: $cms-section__header-margin-bottom;
        font-size: $cms-section__header-font-size;
        line-height: $cms-section__header-line-height;

        @include mq($screen-m) {
            line-height: $cms-section__header-line-height\@medium;
        }
    }

    &__text {
        margin-bottom: $cms-section__text-margin-bottom;
        line-height: 1.6;
        color: $gray-dark;
    }

    &--full-width {
        width: 100%;
        max-width: 100%;
    }
}
