$toolbar__offset               : $spacer;
$toolbar__secondary-text-color : #a09fa6;
$toolbar__icon-color           : #a09fa6;
$toolbar__icon-color--active   : $black;
$toolbar__select-border        : 1px solid $black;
$toolbar__select-border-radius : $form-elements-radius;

.toolbar {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    @include mq($screen-xl) {
        justify-content: space-between;
    }

    &--bottom {
        margin-bottom: 30px;
    }
    &__filters-switcher,
    &__sort-switcher {
        justify-content: flex-start;
        position: relative;
        width: calc((100% - 100px) / 2);
        padding: 0 $spacer--medium;
        background-color: $color-primary;
        color: $white;
        font-weight: $font-weight-normal;
        text-transform: initial;

        @include mq($screen-xl) {
            display: none;
        }

        .icon {
            position: absolute;
            right: 10px;
            top: 40%;
            width: 12px;
            height: 12px;
            fill: $white;
        }

        &:hover,
        &:focus {
            color: $gray-darker;

            .icon {
                fill: $gray-darker;
            }
        }
    }

    &__sorter {
        display: none;
        margin: 0;
        min-width: 320px;
        max-width: 420px;

        @include mq($screen-xl) {
            display: block;
        }
    }

    &__limiter {
        display: none;
        margin: 0 20px 0 0;
        @include mq($screen-xl) {
            display: block;
        }
    }
    &__popup {
        top: 50px;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 150;
        @include mq($screen-xl) {
            z-index: 0;
            display: none;
        }

        .popup__close-button {
            top: 0;
            right: 0;
        }
        .popup__close-button-icon {
            fill: $gray-light;
        }
        .popup__content {
            padding: 50px $spacer--extra-large 0;
        }
    }
    .popup-trigger--active {
        background-color: $white;
        color: $gray-darker;

        .icon {
            fill: $gray-darker;
            transform: rotate(180deg);
        }
    }

    &--catalog {
        display: none;

        @include mq($screen-xl) {
            display: flex;
        }
    }

    &--sidebar {
        @include mq($screen-s) {
            .toolbar__popup {
                width: 400px;
            }
        }
        @include mq($screen-m) {
            .toolbar__popup {
                width: 500px;
            }
        }
        @include mq($screen-xl) {
            position: static;

            .popup__close-button,
            .toolbar__view-switcher {
                display: none;
            }
            .toolbar__popup[data-popup="sidebar-popup"] {
                position: static;
                display: block;
                box-shadow: none;
            }
            .popup__content {
                padding-top: 0;
            }
        }

        .filter-menu {
            display: none;

            @include mq($screen-xl) {
                display: block;
            }
        }
    }
}
