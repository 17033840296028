.beprofi-accept {
    display: flex;
    flex-flow: row wrap;

    &__agreements-list {
        flex: 1 1 100%;
        list-style: none;
        padding: 0;
        margin: 15px 0 0 0;
    }

    &__validation-label {
        flex: 1 1 100%;
        padding: 25px 0;
    }

    &__submit {
        flex: 0 1 295px;
    }
}
