.shopping-lists-view {
    &__actions-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        @include mq($screen-m) {
            flex-flow: row wrap;
        }
    }
    &__primary-actions {
        display: flex;
        flex-direction: column;
        @include mq($screen-m) {
            flex-direction: row;
            justify-content: flex-end;
            width: 100%;
        }
        .button {
            margin: $spacer--large 0;
        }
    }
    &__secondary-actions {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 0;
    }

    &__button-back {
        display: inline-flex;
        margin: 0;
        .icon {
            width: 7px;
            height: 12px;
        }
    }
}
