.home-slider {
    &--main {
        min-height: 415px;
        margin-bottom: 32px;

        @include mq($screen-m) {
            min-height: 490px;
        }

        @include mq($screen-xl) {
            min-height: 640px;
        }

        .slider {
            margin: 0;

            &__prev,
            &__next {
                margin: 0;
                transform: translate(0, -50%);
            }

            &--nav {
                margin-top: $spacer--medium;

                .slick-list {
                    min-height: 0;
                }
            }
        }

        .home-slider {
            &__item {
                min-height: 320px;
                max-height: 500px;
                margin: auto;

                > * {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                }

                img {
                    max-height: 500px;
                    object-fit: cover;
                }
            }
        }

        .slick-slide {
            margin: 0;
        }

        .slick-list {
            min-height: 320px;
        }

        .slick-dots {
            display: grid !important; //sass-lint:disable-line no-important
            grid-template-columns: repeat(4, 1fr);
            gap: $spacer--medium; //sass-lint:disable-line no-misspelled-properties
            margin: 0;
            padding: 32px $spacer--large;
            list-style-type: none;

            @include mq($screen-m) {
                gap: $spacer--semi-large; //sass-lint:disable-line no-misspelled-properties
                padding: 32px 0;
            }

            @include mq($screen-xl) {
                gap: $spacer--large; //sass-lint:disable-line no-misspelled-properties
                padding: 32px;
            }

            li {
                min-height: 64px;
                min-width: 64px;
                cursor: pointer;

                .image {
                    max-height: 88px;
                    object-fit: cover;

                    @include mq($screen-m) {
                        max-height: 110px;
                    }
                }

                &:hover,
                &:focus {
                    opacity: 0.8;
                }
            }

            .slick-active {
                outline: 2px solid $blue;
                outline-offset: 3px;
            }
        }
    }

    &--brands {
        padding: 64px 0;
        background-color: $gray-lightest;
        margin-bottom: 64px;

        .home-slider {
            &__heading {
                text-align: center;
            }

            &__item {
                margin: auto;
                max-width: 188px;
                box-shadow: 0 0 $spacer--medium $spacer rgba(0, 0, 0, 0.1);
            }

            &__container {
                max-width: $max-content-width;
            }
        }
    }

    &__heading {
        margin-bottom: 32px;
        font-size: 26px;
        font-weight: $font-weight-bold;
    }

    &__container {
        margin: auto;
        max-width: $max-content-width;
    }
}
