.header-auth {
    display: flex;
    gap: $spacer; //sass-lint:disable-line no-misspelled-properties

    &__button {
        padding: 0 $spacer--semi-large;
        height: 48px;
        color: $gray-darker;
        font-size: $font-size-small;
        font-weight: $font-weight-bold;
        text-transform: none;
        background-color: transparent;

        @include mq($screen-m) {
            height: 50px;
        }

        @include mq($screen-xl) {
            height: 55px;
        }
    }

    &__icon {
        margin: 0 $spacer--medium 0 $spacer;
        width: 18px;
        height: 18px;
        fill: $color-primary;
    }
}
