.search-bar {
    width: 100%;
    margin: 0;
    padding: $spacer--medium;
    background-color: $color-primary;

    &__text {
        color: $white;
    }

    &__container {
        position: relative;
        margin: $spacer--medium 0 0;
    }

    &__button {
        position: absolute;
        top: 0;
        right: 0;
    }

    &__input {
        margin-bottom: 0;
        .input__field {
            border: none;
            color: $gray-dark;
            &--filled {
                padding-top: 0;
            }
        }
    }
    &--secondary {
        background-color: $white;
        .search-bar__container {
            margin: 0;
        }
        .search-bar__text {
            color: $gray;
        }
        .input__field {
            border: 1px solid $gray-lighter;
            border-right: none;
        }
    }
    &--placeholder {
        .input__field {
            &::placeholder {
                font-size: inherit;
            }
        }
    }
}
