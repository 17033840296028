$loyalty-register-form__width: 360px;
$loyalty-register-form__indent-depth: 39px;
$loyalty-register-for__double-input-row-gap: 10px;

.loyalty-register-form {
    width: 100%;
    padding: 31px 18px;
    border: solid 1px $form-elements-border-color;

    &__content {
        margin: 0;

        .input,
        .button {
            margin-bottom: $spacer;
        }
    }

    @include mq($screen-m) {
        .loyalty-register-form {
            width: $loyalty-register-form__width;
        }
    }

    &__input {
        max-width: $loyalty-register-form__width;
    }

    &__select-input {
        margin-bottom: 30px;
        // to cover some input labels
        z-index: 2;
    }

    &__select-label {
        margin-bottom: 14px;
    }

    &__agreement-checkbox {
        margin-bottom: 44px;

        &:first-of-type {
            margin-top: 47px;
        }
    }

    &__note {
        font-size: $font-size-base;
        font-weight: $font-weight-thin;
        line-height: $font-line-height;
        color: $gray-dark;
        padding: 0 $loyalty-register-form__indent-depth;
        margin-bottom: $spacer--extra-large;
        white-space: pre-line;
    }

    &__submit {
        margin: 0 $loyalty-register-form__indent-depth;
        width: calc(100% - 2 * #{$loyalty-register-form__indent-depth});
    }

    &__validation-label {
        color: $gray-light;
        padding: 0 $loyalty-register-form__indent-depth;
        margin-bottom: $spacer--extra-large;
    }

    .account-form__content {
        width: 100%;
    }

    &__address {
        margin-bottom: $spacer--large;
    }

    &__address-label {
        font-weight: $font-weight-bold;
        margin: 31px 0 $spacer--extra-large;
    }

    &__double-input-row {
        column-gap: $loyalty-register-for__double-input-row-gap;
        display: grid;
        grid-template-columns: calc(70% - #{$loyalty-register-for__double-input-row-gap}) 30%;
        max-width: $loyalty-register-form__width;

        &--inverted {
            grid-template-columns: 30% calc(70% - #{$loyalty-register-for__double-input-row-gap});
        }
    }

    &__individual-settle {
        margin: $spacer--medium 0;
    }

    &__individual-settle-label {
        font-weight: $font-weight-bold;
    }

    &__individual-settle-note {
        font-size: $font-size-extra-small;
        color: $gray;
    }
}
