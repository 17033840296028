$badge__padding: $spacer 8px;

.badge {
    display: inline-flex;
    padding: $badge__padding;
    font-size: 10px;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    color: $white;
    background: $color-primary;
    white-space: nowrap;

    &--dark {
        background-color: $color-secondary;
    }

    &--lowercase {
        text-transform: none;
    }

    &--with-highlight {
        padding: 0;

        .badge__text {
            padding: $badge__padding;
        }

        .badge__highlight {
            padding: $badge__padding;
            background-color: $color-danger;
        }
    }

    &--besmart {
        background-color: $color-danger;
        font-weight: $font-weight-bold;

        span {
            text-transform: initial;
            font-weight: $font-weight-thin;
        }
    }

    &--with-icon {
        display: inline-flex;
        align-items: center;

        .badge__icon {
            fill: $white;
            .icon {
                width: 12px;
                height: 12px;
                margin: 0 $spacer;
            }
        }
    }

    &__popover {
        max-width: 150px;
    }

    &--loyalty {
        background-color: $loyalty-orange;
    }

    &--discount {
        position: absolute;
        right: 20px;
        top: -29px;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 58px;
        height: 58px;
        border-radius: 100%;
        padding: 10px 15px;
    }

    &--discount-number {
        font-size: $font-size-base;
        font-weight: $font-weight-bold;
    }
}
