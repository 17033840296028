.mega-menu {
    &--one-column {
        .mega-menu__inner-list {
            border-right: none;
        }
        .mega-menu__inner-list--level1 {
            display: flex;
            flex-flow: row wrap;
            align-content: flex-start;
            width: 100%;
            height: 550px;
            padding: 25px 25px 50px;
        }
        .mega-menu__inner-list--level2 {
            display: block;
            position: static;
            width: auto;
            height: auto;
            min-height: inherit;
            padding: 0 !important; // sass-lint:disable-line no-important
            background: transparent;
        }
        .mega-menu__inner-label {
            display: block;
            border-bottom: none;
            height: auto;
            padding: 0;
            &:hover,
            &:focus {
                background: none;
            }
        }
        .mega-menu__inner-item--level1 {
            width: 200px;
            padding-bottom: 20px;

            & > .mega-menu__inner-label {
                padding-bottom: 15px;
                color: $gray-darker;
                font-weight: $font-weight-bold;
            }
        }
        .mega-menu__inner-item--level2 {
            width: auto;
            & > .mega-menu__inner-label {
                padding-bottom: 10px;
                color: $gray;
                font-weight: $font-weight-base;
            }
        }
        .mega-menu--button {
            .mega-menu__inner-label {
                display: flex;
                justify-content: center;
                position: absolute;
                bottom: 0;
                left: 0;
                height: 50px;
                width: 100%;
                padding-bottom: 0;
                background: $blue;
                color: $white;
            }
        }
    }
}
