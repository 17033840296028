$side-menu__padding         : 0;
$side-menu__top             : 106px;
$side-menu__right           : 100%;
$side-menu__background      : $white;
$side-menu__transition      : $transition-base;
$side-menu__transform--open : translateX($side-menu__right);
$side-menu__link-color      : $gray-dark;
$side-menu__link-color-hover: $gray;
$side-menu__border          : 1px solid rgba(0, 0, 0, 0.09);

.side-menu {
    position: fixed;
    top: $side-menu__top;
    bottom: 0;
    right: $side-menu__right;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    padding: $side-menu__padding;
    z-index: 100;
    background: $side-menu__background;
    opacity: 1;
    transform: translateX(0);
    transition: $side-menu__transition;

    @include mq($screen-m) {
        max-width: 400px;
    }

    @include mq($screen-xl) {
        display: none;
    }

    &--is-open {
        opacity: 1;
        transform: translateX(100%);

        & + .side-menu__bg {
            width: 100%;
            opacity: 1;
        }
    }

    &__wrapper {
        height: 100vh;
    }

    &__link {
        display: block;
        line-height: 3;
        color: $side-menu__link-color;

        &:hover {
            text-decoration: none;
            color: $side-menu__link-color-hover;
        }
    }

    &__additional-link {
        display: block;
        padding: 17px 15px;
        color: $gray-dark;
        font-weight: $font-weight-base;
        border-bottom: $side-menu__border;

        &:focus,
        &:hover {
            color: $font-color-base;
        }
    }

    &__bg {
        position: fixed;
        top: 0;
        bottom: 0;
        width: 0;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: width 0.2s ease, opacity 0.5s ease;
        @include mq($screen-xl) {
            display: none;
        }
    }

    &__dropdown {
        z-index: 2;
        background-color: $white;
        height: auto;

        .dropdown-list {
            &__label {
                background-color: $white;
                font-weight: $font-weight-base;
                color: $gray-dark;
            }

            &__item {
                border-bottom: $side-menu__border;
            }

            &__icon {
                right: 20px;
                width: 12px;
                height: 12px;
                fill: $gray-dark;
            }

            &__inner-item {
                background-color: $white;
                border-bottom: $side-menu__border;
                &:first-of-type {
                    & > .dropdown-list__inner-label {
                        border-top: $side-menu__border;
                    }
                }

                &--level2 {
                    .dropdown-list__inner-label {
                        padding-left: 45px;
                    }
                }
                &--level3 {
                    .dropdown-list__inner-label {
                        padding-left: 60px;
                    }
                }
            }

            &__inner-label {
                color: $side-menu__link-color;
                font-weight: $font-weight-thin;
                padding: 15px 15px 15px 30px;
            }
        }
    }

    .dropdown-switcher__label {
        .icon {
            right: 20px;
        }
    }
}
