.dashboard-status {
    display: flex;
    justify-content: center;

    .icon {
        width: 16px;
        height: 16px;
        margin-right: $spacer--medium;
    }

    &--ready {
        color: $green;
        .icon {
            fill: $green;
        }
    }

    &--failed {
        color: $red;
        .icon {
            fill: $red;
        }
    }

    &--inactive {
        color: $gray-light;
        .icon {
            fill: $gray-light;
        }
    }
}
