@use "sass:math";

@mixin grid-column-width($width, $count) {
    $basis: math.div(100%, $count) * $width;
    $adjustment: math.div(($count - $width) * $grid__gutter, $count);

    @if ($grid__gutter > 0) {
        flex-basis: calc(#{$basis} - #{$adjustment});
        max-width: calc(#{$basis} - #{$adjustment});
    }
    @else {
        flex-basis: $basis;
        max-width: $basis;
    }
}
