.account-details {
    @include mq($screen-m) {
        display: flex;
        justify-content: space-between;
    }

    &__action-fields {
        display: flex;
        flex-direction: column;

        @include mq($screen-m) {
            justify-content: space-between;
        }
    }

    &__remove-button {
        background: transparent;
        color: $color-secondary;

        .icon {
            fill: $color-secondary;
        }

        &:hover,
        &:focus {
            background: transparent;
            color: $color-secondary--active;

            .icon {
                fill: $color-secondary--active;
            }
        }

        @include mq($screen-m) {
            justify-content: flex-end;
        }
    }

    &__status-note {
        @include mq($screen-m) {
            align-self: flex-end;
        }
    }

    .list {
        margin-bottom: $spacer--medium;
        @include mq($screen-m) {
            margin-bottom: 0;
        }

        .list__value {
            float: none;
            padding-bottom: $spacer;
            font-weight: $font-weight-bold;

            @include mq($screen-m) {
                float: left;
            }
        }

        .list__label {
            float: none;
            font-weight: $font-weight-thin;

            @include mq($screen-m) {
                min-width: 170px;
                float: left;
            }
        }
    }
}
