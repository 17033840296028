.cart {
    &__availability {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__availability-wrapper {
        display: flex;
        position: relative;
        .loading--small {
            position: relative;
        }
    }

    &__availability-label {
        display: flex;
        align-items: center;
        margin: 0 $spacer 0 0;
    }

    &__availability-qty {
        font-size: $font-size-small;
        font-weight: $font-weight-bold;
        line-height: 16px;
    }

    &__availability-badge {
        display: inline-flex;
        width: 16px;
        height: 16px;
        margin: 0 8px 0 0;

        &--green {
            background-color: $green;
        }

        &--red {
            background-color: $red;
        }
    }

    &__availability-date-wrapper {
        display: flex;
        flex-direction: column;
        font-size: $font-size-extra-small;
        font-weight: $font-weight-base;
    }

    &__availability-date {
        color: $gray;
    }

    &__availability-status {
        display: inline-flex;
        font-size: $font-size-small;
        font-weight: $font-weight-bold;
        margin: 0 0 8px;
    }
}
