.section {
    position: relative;

    &--images {
        .section__icon {
            position: absolute;
            bottom: 96px;
            left: 40px;
            fill: $color-primary;
        }

        .section__item-title {
            position: absolute;
            bottom: 56px;
            left: 40px;
            text-transform: none;
            font-size: $font-size-large;
            color: $color-primary;
        }

        .section__anchor {
            position: absolute;
            bottom: 32px;
            left: 40px;
            font-weight: $font-weight-normal;
            font-size: $font-size-small;
            color: $gray-darkest;
        }

        .section__item-link {
            &::before {
                position: absolute;
                display: block;
                content: '';
                top: 16px;
                right: 16px;
                bottom: 16px;
                left: 16px;
                border: 8px solid $white;
                opacity: 0.6;
                z-index: 1;
            }
        }
    }

    &--icons {
        .section__heading-icon {
            fill: $color-primary;
            opacity: 0.3;
        }

        .section__icon {
            transition: $transition-base;
            width: 60px;
            height: 60px;
            margin-bottom: 20px;
            fill: $gray;

            @include mq($screen-xl) {
                margin-bottom: 25px;
            }

            @include mq($max-content-width) {
                width: 72px;
                height: 72px;
            }
        }

        .section__item {
            transition: $transition-base;
            background-color: $white;
            box-shadow: $box-shadow-base;
            padding: 0;

            @include mq($screen-s) {
                flex: 0 0 100%;
            }

            @include mq($screen-l) {
                flex: 0 0 18%;
                margin: 0;
            }

            &:hover,
            &:active,
            &:focus {
                background-color: $color-secondary;

                .section__icon {
                    fill: $white;
                }

                .section__item-title {
                    color: $white;
                }

                .section__description {
                    color: $white;
                }

                .section__anchor {
                    text-decoration: none;
                }
            }
        }

        .section__item-link {
            display: block;
            padding: $spacer--medium;

            @include mq($screen-m) {
                padding: $spacer--medium $spacer--extra-large;
            }

            @include mq($screen-l) {
                padding: 88px $spacer--medium 48px $spacer--medium;
            }

            @include mq($max-content-width) {
                padding: 98px 24px 56px 24px;
            }
        }

        .section__item-title {
            transition: $transition-base;
            text-transform: none;
            font-size: $font-size-large;
            font-weight: $font-weight-bold;

            @include mq($screen-l) {
                line-height: 24px;
                margin-bottom: $spacer--medium;
            }
        }

        .section__anchor {
            color: $gray-darkest;
            font-size: $font-size-small;

            @include mq($screen-l) {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                padding-bottom: $spacer--medium;
                padding-left: $spacer--medium;
            }

            @include mq($max-content-width) {
                padding-bottom: $spacer--large;
                padding-left: 24px;
            }
        }

        .section__heading {
            text-transform: uppercase;

            @include mq($screen-s) {
                font-size: 24px;
            }
        }

        .section__description {
            transition: $transition-base;
            @include mq($screen-l) {
                margin-bottom: 0;
            }
        }

        .section__icon-bg {
            @include mq($screen-l) {
                right: -16px;
                top: 0;
            }

            @include mq($max-content-width) {
                right: -32px;
                top: -20px;
            }

            .section__icon {
                @include mq($screen-l) {
                    width: 144px;
                    height: 144px;
                }

                @include mq($max-content-width) {
                    width: 200px;
                    height: 200px;
                }
            }
        }

        .section__handler {
            max-width: 496px;
            margin: 0 auto;

            @include mq($screen-l) {
                max-width: none;
            }
        }

        .section__heading-content {
            max-width: 496px;
            margin: 0 auto;
        }
    }

    &--images-text {
        .section__item {
            box-shadow: $box-shadow-secondary;
            padding: 8px;

            @include mq($screen-s) {
                padding: 0 0 56px 0;
            }

            @include mq($screen-m) {
                flex: 0 0 32%;
            }

            @include mq($screen-l) {
                flex: 0 0 24%;
            }

            &:nth-child(n+4) {
                @include mq($screen-m) {
                    display: none;
                }

                @include mq($screen-l) {
                    display: inherit;
                }
            }
        }

        .section__image {
            position: absolute;
            top: 8px;
            left: 8px;
            width: 38%;
            height: auto;

            @include mq($screen-s) {
                position: relative;
                top: 0;
                left: 0;
                width: 100%;
                margin-bottom: 12px;
            }

            @include mq($screen-xl) {
                margin-bottom: 24px;
            }
        }

        .section__date {
            font-size: $font-size-small;
            color: $gray;
            padding-left: 43%;
            font-weight: $font-weight-normal;
            display: block;

            @include mq($screen-s) {
                padding: 0 16px;
                margin-bottom: 8px;
            }

            @include mq($screen-xl) {
                padding: 0 32px;
            }
        }

        .section__item-title {
            font-size: $font-size-base;
            color: $gray-darkest;
            padding-left: 43%;
            font-weight: $font-weight-bold;
            text-transform: none;
            line-height: 16px;

            @include mq($screen-s) {
                padding: 0 16px;
                margin-bottom: 8px;
            }

            @include mq($screen-xl) {
                margin-bottom: 24px;
                padding: 0 32px;
            }
        }

        .section__description {
            font-size: $font-size-small;
            color: $gray-darkest;
            padding-left: 43%;
            font-weight: $font-weight-normal;
            line-height: 16px;

            @include mq($screen-s) {
                padding: 0 16px;
                margin-bottom: 8px;
            }

            @include mq($screen-xl) {
                font-size: $font-size-base;
                line-height: 24px;
                padding: 0 32px;
            }
        }

        .section__anchor {
            font-size: $font-size-base;
            color: $color-primary;
            padding-left: 43%;
            font-weight: $font-weight-bold;
            line-height: 48px;

            @include mq($screen-s) {
                position: absolute;
                padding: 0;
                bottom: 8px;
                left: 16px;
            }

            @include mq($screen-xl) {
                left: 32px;
            }
        }
    }

    &--images-text-vertical {
        border-bottom: $border-secondary;

        .section__item {
            padding: $spacer--large 0;
            margin: 0;
            border-top: $border-secondary;

            @include mq($screen-s) {
                flex-basis: 100%;
            }

            @include mq($screen-m) {
                padding-left: 256px;
                min-height: 215px;
            }

            @include mq($screen-xl) {
                padding-left: 336px;
                min-height: 265px;
            }
        }

        .section__image {
            margin-bottom: $spacer--medium;

            @include mq($screen-m) {
                position: absolute;
                margin: 0;
                left: 0;
                top: $spacer--large;
                max-width: 240px;
                max-height: 100%;
            }

            @include mq($screen-xl) {
                max-width: 320px;
            }
        }

        .section__item-title {
            font-size: $font-size-base;
            font-weight: $font-weight-bold;
            margin-bottom: $spacer--medium;

            @include mq($screen-m) {
                font-size: $font-size-large;
                margin-bottom: 24px;
            }
        }

        .section__description {
            font-size: $font-size-base;
            font-weight: $font-weight-normal;
            line-height: 24px;
            color: $gray-darkest;
            margin-bottom: $spacer--medium;

            @include mq($screen-xl) {
                margin-bottom: $spacer--large;
            }
        }
    }

    &--overlapping {
        .section__handler {
            margin-top: -32px;
        }
    }

    &--images-list {
        margin-bottom: 40px;
        position: relative;

        .section__heading-content {
            padding: 16px 0;
            align-items: left;

            @include mq($screen-l) {
                position: absolute;
                left: 0;
                padding: 0;
            }
        }

        .section__heading {
            font-size: 18px;
            font-weight: $font-weight-normal;
            text-transform: uppercase;
            padding: 0;
            text-align: left;

            @include mq($screen-m) {
                font-size: 24px;
            }
        }

        .section__item {
            @include mq($screen-s) {
                flex: 0 0 100%;
            }

            @include mq($screen-l) {
                display: flex;
                justify-content: space-between;
            }
        }

        .section__item-list {
            @include mq($screen-m) {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            @include mq($screen-l) {
                flex: 0 0 50%;
                padding-top: 40px;
                order: 1;
            }

            .list {
                &--description {
                    @include mq($screen-m) {
                        flex: 0 0 35%;
                    }

                    @include mq($screen-l) {
                        flex: 0 0 100%;
                    }

                    .list__label {
                        @include mq($screen-m) {
                            flex: 0 0 65%;
                        }

                        @include mq($screen-l) {
                            flex: 0 0 30%;
                        }
                    }

                    .list__value {
                        @include mq($screen-m) {
                            flex: 0 0 35%;
                        }

                        @include mq($screen-l) {
                            flex: 0 0 70%;
                        }
                    }
                }

                &--description-borders {
                    @include mq($screen-m) {
                        flex: 0 0 65%;
                    }

                    @include mq($screen-l) {
                        flex: 0 0 100%;
                        margin-top: 10px;
                    }

                    .list__label {
                        @include mq($screen-m) {
                            flex: 0 0 30%;
                        }
                    }

                    .list__value {
                        @include mq($screen-m) {
                            flex: 0 0 70%;
                        }
                    }
                }
            }
        }

        .section__link {
            @include mq($screen-m) {
                margin-left: 35%;
            }

            @include mq($screen-l) {
                margin-left: 0;
            }
        }

        .section__item-image {
            @include mq($screen-l) {
                flex: 0 0 48%;
                order: 2;
            }
        }
    }

    &--borders {
        &:after {
            @include mq($screen-l) {
                display: block;
                position: absolute;
                content: '';
                width: calc(50% - 8px);
                height: 1px;
                background-color: $gray-lighter;
                bottom: 0;
                left: 0;
            }

            @include mq($screen-xl) {
                width: calc(50% - 16px);
            }
        }

        .section__item {
            padding: $spacer--large $spacer;
            border-top: $border-secondary;
            margin-bottom: 0;

            @include mq($screen-s) {
                flex: 0 0 100%;
            }

            @include mq($screen-m) {
                padding: $spacer--large 0;
            }

            @include mq($screen-l) {
                flex-basis: calc(50% - 8px);
            }

            @include mq($screen-xl) {
                flex-basis: calc(50% - 16px);
            }

            &:last-child {
                border-bottom: $border-secondary;
            }
        }

        .section__item-title {
            font-size: $font-size-base;
            line-height: 24px;
            margin-bottom: $spacer;
            font-weight: $font-weight-bold;

            @include mq($screen-m) {
                font-size: $font-size-large;
                line-height: 32px;
                margin-bottom: $spacer--medium;
            }
        }

        .section__description {
            line-height: 24px;
        }
    }

    &--steps {
        @include mq($screen-m) {
            padding: $spacer--large 0;
        }

        &:before {
            @include mq($screen-m) {
                position: absolute;
                display: block;
                width: 1px;
                top: 0;
                bottom: 0;
                left: 50%;
                content: '';
                background-color: $blue;
            }
        }

        .section__item {
            padding: $spacer--large $spacer--medium;
            margin: 0;

            @include mq($screen-s) {
                flex: 0 0 100%;
            }

            @include mq($screen-s) {
                padding-top: $spacer--extra-large;
                padding-bottom: $spacer--extra-large;
            }

            &--circle {
                .section__item-title {
                    font-size: 24px;
                    line-height: 32px;
                    width: 176px;
                    height: 176px;
                    display: block;
                    margin: 0 auto;
                    position: relative;
                    border: 1px solid $blue;
                    border-radius: 50% 50%;
                    text-align: center;
                    background-color: $white;
                }

                .section__item-title-text {
                    position: absolute;
                    display: block;
                    color: $white;
                    width: 160px;
                    height: 160px;
                    background-color: $blue;
                    top: 50%;
                    left: 50%;
                    margin: -80px 0 0 -80px;
                    z-index: 1;
                    border-radius: 50% 50%;
                    padding-top: 49px;
                }
            }

            &--invert {
                .section__icon-number {
                    @include mq($screen-m) {
                        left: 53%;
                        right: auto;
                    }
                }

                .section__item-title,
                .section__item-subtitle,
                .section__description,
                .section__anchor {
                    @include mq($screen-m) {
                        margin-right: 50%;
                        margin-left: 0;
                        padding-right: $spacer--large;
                        padding-left: 0;
                        text-align: right;
                    }
                }

                .section__item-subtitle {
                    &:before {
                        @include mq($screen-m) {
                            left: auto;
                            right: -$spacer;
                        }
                    }
                }
            }
        }

        .section__icon-number {
            display: block;
            position: relative;
            width: 144px;
            height: 120px;

            @include mq($screen-m) {
                width: 186px;
                height: 160px;
                position: absolute;
                right: 53%;
            }

            .section__icon {
                position: absolute;
                width: 75px;
                height: 75px;
                top: 17px;
                right: 0;
                z-index: 2;

                @include mq($screen-m) {
                    width: 97px;
                    height: 97px;
                    top: 31px;
                }

                &--primary {
                    fill: $color-primary;
                    right: 6px;

                    @include mq($screen-m) {
                        right: 8px;
                    }
                }

                &--shadow {
                    fill: $gray-lightest;
                    z-index: 1;
                    top: 24px;

                    @include mq($screen-m) {
                        top: 38px;
                    }
                }
            }
        }

        .section__number {
            color: $gray-lightest;
            font-size: 57px;

            @include mq($screen-m) {
                position: absolute;
                top: 12px;
                left: 0;
                font-size: 70px;
            }
        }

        .section__item-title {
            font-weight: $font-weight-bold;
            font-size: $font-size-large;
            line-height: 24px;
            margin-bottom: $spacer;

            @include mq($screen-m) {
                font-size: 24px;
                line-height: 32px;
                margin-left: 50%;
                padding-left: $spacer--large;
            }
        }

        .section__item-subtitle {
            @extend .secondary-heading !optional;
            margin-bottom: $spacer;
            position: relative;
            display: block;

            @include mq($screen-m) {
                margin-left: 50%;
                padding-left: $spacer--large;
            }

            &:before {
                @include mq($screen-m) {
                    position: absolute;
                    content: '';
                    display: block;
                    width: 16px;
                    height: 16px;
                    border: 2px solid $blue;
                    border-radius: 50% 50%;
                    background-color: $white;
                    top: $spacer;
                    left: -$spacer;
                }
            }
        }

        .section__description {
            color: $gray-darkest;
            line-height: 24px;
            font-size: $font-size-base;
            margin-bottom: $spacer;

            @include mq($screen-m) {
                margin-left: 50%;
                padding-left: $spacer--large;
            }
        }

        .section__anchor {
            font-size: $font-size-small;
            display: block;

            @include mq($screen-m) {
                margin-left: 50%;
                padding-left: $spacer--large;
            }
        }
    }

    &__content {
        padding: 0 $spacer--large;

        @include mq($screen-s) {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
        }
    }

    &__item {
        position: relative;
        margin-bottom: 16px;
        overflow: hidden;

        @include mq($screen-s) {
            flex: 0 0 49%;
        }

        @include mq($screen-l) {
            flex: 0 0 24%;
        }
    }

    &__item-link {
        display: block;

        &:hover,
        &:active {
            text-decoration: none;

            .section__item-title {
                text-decoration: none;
            }
            .section__anchor {
                text-decoration: underline;
            }
        }
    }

    &__heading-content {
        display: flex;
        padding: 40px $spacer--large;
    }

    &__heading-icon {
        width: 112px;
        height: 17px;
        margin-bottom: 23px;
    }

    &__heading {
        font-weight: $font-weight-bold;
        font-size: 32px;
        line-height: 1.5;
        text-align: center;
        margin: 0;
    }

    &__heading-subtitle {
        font-size: $font-size-base;
        text-align: center;
        padding-top: 10px;
        color: $gray;
    }

    &__icon-bg {
        position: absolute;
        top: -34px;
        right: -34px;
        opacity: 0.05;

        .section__icon {
            fill: $color-primary;
            width: 200px;
            height: 200px;
            margin: 0;
        }
    }

    &__description {
        color: $gray;
        line-height: 16px;
    }

    &__button {
        display: block;
        width: 100%;
        max-width: 288px;
        margin: 25px auto 0 auto;

        &--link {
            text-align: center;
            line-height: 48px;
        }
    }
}
