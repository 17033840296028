$filters__heading-font-weight        : $font-weight-bold;
$filters__heading-margin             : 0;
$filters__heading-margin\@medium     : 0 0 $spacer--medium 0;
$filters__switcher-margin            : $spacer 0 0 0;
$filters__switcher-padding           : 0;
$filters__switcher-border            : 2px solid $color-primary;
$filters__switcher-border-radius     : 0;
$filters__switcher-background-color  : $bg-color-base;
$filters__switcher-color             : $color-primary;
$filters__switcher-font-size         : $font-size-base;
$filters__switcher-font-weight       : $font-weight-bold;
$filters__switcher-size              : 48px;
$filters__list-margin                : $spacer--medium 0 0 0;


.filters {
    &--cms {
        .filters__heading {
            color: $gray-darkest;
        }

        .filters__option-content {
            margin-bottom: 20px;
        }

        .active-filters__item  {
            display: flex;
        }
    }

    &__heading {
        font-weight: $filters__heading-font-weight;
        font-size: $font-size-large;
        color: $color-primary;
        margin: $filters__heading-margin;
        @include mq($screen-m) {
            margin: $filters__heading-margin\@medium;
        }
        &--mobile-hidden {
            display: none;
            @include mq($screen-m) {
                display: block;
            }
        }
    }

    &__switcher {
        width: 100%;
        border-radius: 8px;

        @include mq($screen-m) {
            display: none;
        }

        &--active {
            .filters__switcher_icon {
                transform: rotate(180deg);
            }
        }
    }

    &__switcher_icon {
        fill: $gray-light;
    }

    &__list {
        margin: $filters__list-margin;
        background-color: transparent;

        &--mobile-hidden {
            display: none;
            @include mq($screen-m) {
                display: block;
            }
        }
    }

    &__options {
        position: relative;
        z-index: 2;
        gap: $spacer--medium; // sass-lint:disable-line no-misspelled-properties

        @include mq($screen-m) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__option-content {
        @include mq($screen-m) {
            flex: 0 0 50%;
        }

        @include mq($screen-l) {
            flex-basis: 25%;
        }
    }

    .active-filters {
        &__list {
            display: flex;
            flex-wrap: wrap;
        }

        &__item {
            display: flex;
            align-items: center;
            padding: 0;
        }

        &__remove {
            display: inline-flex;
            align-items: center;
            min-width: 48px;
            min-height: 48px;
            padding: 0;
            border: none;
            outline: none;
            background: none;
            cursor: pointer;
            word-break: break-all;
        }

        &__remove-icon {
            display: block;
            padding: 0;
            margin: 0 auto;
            width: $font-size-base;
            height: $font-size-base;
            fill: $red;
        }

        &__label {
            font-weight: $font-weight-bold;
        }

        &__actions {
            display: flex;
            justify-content: center;
        }
    }
}

.filters-list {
    &__title {
        font-size: $font-size-large;
        font-weight: $font-weight-base;
    }

    &__link {
        padding-bottom: $spacer--medium;

        &:focus,
        &:hover {
            background: none;
        }
    }
    .filter {
        padding-bottom: $spacer--medium;
    }

    &--category {
        @include mq($screen-m) {
            margin: 0 30px;
        }

        @include mq($screen-xl) {
            margin: 0;
        }

        .filters-list {
            &__title {
                font-family: $font-family-sans-serif;
                font-weight: $font-weight-bold;
                font-size: 12px;
                text-align: center;
                margin: 0 $spacer--extra-large $spacer--extra-large;
                text-transform: uppercase;
                white-space: nowrap;

                @include mq($screen-m) {
                    font-size: $font-size-base;
                }
            }

            &__divider {
                width: 100%;
                display: block;
                border-top: 1px solid black;

                @include mq($screen-m) {
                    margin-top: $spacer--medium;
                }
            }

            &__heading {
                margin-top: $spacer--large;
                display: flex;
                flex-direction: row;
            }

            &__container {
                margin: 0 $spacer $spacer--extra-large;

                @include mq($screen-m) {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: $spacer--extra-large; // sass-lint:disable-line no-misspelled-properties
                }

                @include mq($screen-l) {
                    grid-template-columns: repeat(3, 1fr);
                }

                @include mq($screen-xl) {
                    margin: 0 0 $spacer--extra-large;
                }
            }
        }

        .filter {
            &--active {
                border-radius: $spacer;
                margin: 0 $spacer $spacer--medium;

                @include mq($screen-m) {
                    margin: 0;
                }
            }
        }

        .filters__link {
            display: flex;
            align-items: center;
            min-height: 35px;
            height: 44px;
            margin: $spacer--medium 0;
            padding: $spacer--medium;
            background: $gray-lightest;
            font-size: 14px;
            margin: 0 $spacer $spacer--medium;
            border-radius: $spacer;

            @include mq($screen-m) {
                grid-column-start: 2;
                margin: 0;
            }

            @include mq($screen-l) {
                grid-column-start: 3;
            }

            .button__text {
                flex-grow: 1;
                text-align: center;
            }

            svg {
                width: $spacer--medium;
            }
        }
    }
}
