.returns-history {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    &__heading {
        flex-basis: 100%;
        max-width: 100%;
    }
    &__button-add {
        flex-basis: 100%;
        margin: 0 $spacer--medium;
        @include mq($screen-m) {
            flex-basis: calc(50% - #{$spacer--extra-large});
        }
        @include mq($screen-l) {
            flex-basis: 330px;
            max-width: 330px;
        }
    }
    &__search {
        @include mq($screen-m) {
            flex-basis: 50%;
            padding: 0 $spacer--medium;
        }
        @include mq($screen-l) {
            flex-basis: 330px;
            max-width: 330px;
        }
    }
    &__filters {
        @include mq($screen-l) {
            border-top: 1px solid $border-color-base;
            margin-top: $spacer--extra-large;
        }
    }
    &__table {
        flex-basis: 100%;
        max-width: 100%;
        .dashboard-table__cell--isLink.center {
            align-items: center;
        }
    }
}
