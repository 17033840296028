$account-form__width: 340px;

.account-form {
    width: 100%;

    &__content {
        margin: 0;

        .input,
        .button {
            margin-bottom: $spacer;
        }
    }

    @include mq($screen-m) {
        .account-form__content {
            width: $account-form__width;
        }
    }

    &__tax-office {
        &--invalid {
            border: 1px solid $red;
        }
    }
}
