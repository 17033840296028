.beprofi-accept-agreement {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    padding: $spacer--medium 0;
    &__checkbox {
        margin-right: $spacer--medium;
        flex: 0 0 auto;
    }
    &__label {
        flex: 1;
    }
    &__validation-label {
        flex: 1 1 100%;
        color: $red;
        padding-top: $spacer--medium;
    }
}
