.loyalty-user-details {
    display: flex;
    flex-direction: column;

    &__checkbox {
        margin: $spacer--extra-large 0;
    }

    &__address-label {
        font-weight: $font-weight-bold;
        margin: $spacer--extra-large 0;
    }

    &__submit {
        margin-top: $spacer--extra-large;
    }
}
