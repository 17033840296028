.shopping-lists-view {
    &__content {
        padding: $spacer--medium;
    }

    .summary {
        flex-wrap: nowrap;
        justify-content: space-between;

        &__col {
            width: 33%;

            &:nth-child(1) {
                border-bottom: 0;
            }
        }
    }
}
