.product-view-header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin: 0 -8px;
    padding: $spacer--medium;

    @include mq($screen-l) {
        flex-flow: row nowrap;
        border-bottom: 1px solid rgba(0, 0, 0, 0.09);
        padding: 16px 0;
        margin: 0;
    }

    &__title {
        line-height: 24px;
        margin: 0;
        flex-basis: 100%;
        @include mq($screen-l) {
            flex: 1 1 auto;
            line-height: 26px;
        }
    }

    &__badges {
        flex-flow: row nowrap;
        justify-content: flex-start;
        @include mq($screen-l) {
            flex: 1 0 270px;
            order: 1;
            justify-content: flex-end;
        }
        .list__item {
            padding: $spacer--medium 0;
            margin: 0 $spacer--medium;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
            @include mq($screen-l) {
                &:first-child {
                    margin: 0 $spacer--medium;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
