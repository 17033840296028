.loyalty-info-dropdown.dashboard__dropdown {
    margin: $spacer--large 0;
    .dropdown-list__label {
        height: auto;
        font-size: $font-size-base;
        text-transform: none;
    }
    .dropdown-list__content {
        padding-left: 65px;
        padding-right: 10px;
    }
    .child-table {
        margin-bottom: $spacer--medium;
    }
    .child-table tbody tr {
        padding: $spacer--medium;
    }

    .dropdown-list__label .button__text {
        &.button__text--double-value {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            padding-right: 50px;
            text-align: right;
        }
    }
}
