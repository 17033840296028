.logo {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .icon {
        height: 100%;
        width: 166px;
        height: 55px;

        @include mq($screen-xl) {
            width: 170px;
        }
    }
}
