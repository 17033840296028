.cart {
    &__notes {
        display: flex;
        flex-direction: column;
        margin: $spacer--extra-large 0 0;
        @include mq($screen-m) {
            flex-direction: row;
        }
    }
}
