.dashboard {
    &__buttons {
        display: flex;
        flex-direction: column;
        margin-bottom: $spacer--large;
        .button {
            align-items: center;
            margin-bottom: $spacer;
            height: 40px;
            @media all and (min-width: 768px) {
                width: 19%;
            }
            .icon {
                &--angleright {
                    width: $button__icon-size;
                    height: $button__icon-size;
                    margin-right: 0;
                }
                &--white {
                    fill: $white;
                    stroke: none;
                }
            }
        }
        @include mq($screen-m) {
            flex-direction: row;
            .button {
                margin-right: $spacer--large;
            }
        }
        &--title {
            color: $gray-dark;
            margin-right: $spacer--large;
            font-weight: $font-weight-thin;
            line-height: 40px;
        }
    }
}
