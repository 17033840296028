.loyalty-invoices {
    &__info {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        margin: $spacer--extra-large 0 $spacer--large;
        @include mq($screen-m) {
            flex-flow: row nowrap;
        }
    }

    &__info-col1 {
        flex: 0 0 100%;
        @include mq($screen-m) {
            flex: 0 0 50%;
            padding-right: $spacer--extra-large;
        }
    }

    &__info-col2 {
        flex: 0 0 100%;
        justify-content: flex-end;
        display: flex;
        @include mq($screen-m) {
            flex: 0 0 50%;
        }
    }

    &__filters {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        padding: $spacer--medium 0;
        @include mq($screen-m) {
            justify-content: space-between;
        }

        .document-filters {
            &__filter,
            &__submit {
                @include mq($screen-l) {
                    flex-basis: 33%;
                    max-width: 33%;
                }
            }

            &__submit-button {
                background-color: $loyalty-orange;
                border-radius: $spacer;

                &:hover,
                &:focus {
                    color: $white;
                }
            }

            &__toggle-button {
                font-family: $font-family-sans-serif;
                font-size: 18px;
                font-weight: $font-weight-base;
                color: $loyalty-orange;
                text-transform: none;
                letter-spacing: -0.1px;

                .icon {
                    fill: $loyalty-orange !important; // sass-lint:disable-line no-important
                }
            }
        }
    }

    &__filters-range {
        flex: 1 1 100%;
        @include mq($screen-m) {
            flex: 1;
            display: flex;
            align-items: center;
            padding-top: 9px;
        }
        .document-filter__label {
            margin-right: $spacer--medium;
            white-space: nowrap;
        }
    }

    &__filters-search {
        width: 100%;
        padding: 0;
        margin: $spacer--medium 0;

        @include mq($screen-m) {
            width: 300px;
            padding: $spacer--medium $spacer--large 0;
            margin: 0 0 0 auto;
        }
    }

    &__filters-search-wrapper {
        width: 100%;

        .search-bar {
            &__button {
                background-color: $loyalty-orange;

                &:hover,
                &:focus {
                    .icon {
                        fill: $white;
                    }
                }
            }
        }
    }

    &__button {
        display: inline-flex;
        min-width: 294px;
        color: $loyalty-gray-dark;
        border-radius: $spacer;

        @include mq($screen-m) {
            margin: 0 40px 0 0;
        }
    }

    &__user-details {
        .list__item {
            margin: 0 0 $spacer--semi-large;
        }
    }

    &__points-details {
        width: 100%;

        @include mq($screen-l) {
            display: flex;
            gap: 16px; //sass-lint:disable-line  no-misspelled-properties
        }
    }

    &__label {
        font-size: 24px;
        font-weight: $font-weight-bold;
        color: $loyalty-gray-dark;
    }

    &__points-wrapper {
        flex: 1;
        margin: 0 0 32px;
    }

    &__table {
        margin: 0 0 40px;
    }

    .dashboard-table {
        &__button-expand {
            .button {
                background-color: $loyalty-orange;

                &:hover,
                &:focus {
                    .icon {
                        fill: $white;
                    }
                }
            }
        }

        th {
            font-size: $font-size-medium;
            font-weight: $font-weight-medium;
            color: $loyalty-gray-dark;
        }

        tbody {
            td {
                font-weight: $font-weight-medium;
                color: $loyalty-gray-dark;

                &.dashboard-table {
                    &__button-expand {
                        padding: 0 $spacer--medium 0 0;

                        @include mq($screen-m) {
                            padding: 0;
                        }

                        .button {
                            margin-left: auto;
                            width: 62px;
                        }
                    }
                }

                &:before {
                    font-weight: $font-weight-medium;
                    color: $loyalty-gray-dark;
                }

                &.table__cell--child-content {
                    padding: 0;

                    @include mq($screen-m) {
                        padding: $spacer--large 0;
                    }
                }
            }

            tr {
                padding: $spacer--medium;
            }
        }

        .dashboard-status {
            justify-content: flex-start;
        }
    }
}
