.cart {
    &__order {
        display: flex;
        flex-direction: column;
        padding: 0;
        @include mq($screen-m) {
            flex-direction: row;
            justify-content: space-between;
        }
    }
    &__order-container {
        display: flex;
        flex-direction: column;
        @include mq($screen-m) {
            flex-direction: row;
        }
        .button {
            margin: $spacer;
        }
    }
}
