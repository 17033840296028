.shopping-lists-view {
    &__content {
        padding: 0 0 $spacer--medium;
        max-width: 100%;

        @include mq($screen-m) {
            padding: $spacer--medium;
        }
    }

    &__container {
        position: relative;
        max-width: 100%;
    }

    &__search {
        margin-top: $spacer--extra-large;
    }

    .summary {
        @include mq($screen-m) {
            flex-wrap: nowrap;
            justify-content: space-between;
        }
        &__col {
            width: 100%;
            @include mq($screen-m) {
                width: 33%;
            }
            &:nth-child(1) {
                padding-bottom: $spacer--large;
                border-bottom: 1px solid $border-color-base;
                margin-bottom: $spacer--large;
                @include mq($screen-m) {
                    border-bottom: 0;
                }
            }
            &:nth-child(2) {
                border-bottom: 0;
                margin: 0;
                padding: 0;
            }
        }
    }
}
