$category-header__subcategory-color: #444;
$category-header__subcategory-bg: #f1f2f4;

.category-header {
    margin: $spacer--medium 0;

    @include mq($screen-xl) {
        margin: 0 0 $spacer--medium 0;
    }

    &__banner {
        margin: auto;

        img {
            min-height: 320px;
            max-height: 500px;
        }

        &:hover {
            img {
                opacity: 0.8;
            }
        }
    }

    &__subcategory {
        display: flex;
        align-items: center;
        list-style-type: none;
        padding: $spacer--medium $spacer;
        row-gap: 16px; // sass-lint:disable-line no-misspelled-properties
        column-gap: 24px;
        margin: 0 0 24px;
        max-width: 100vw;
        overflow-x: auto;

        @include mq($screen-m) {
            flex-wrap: wrap;
        }

        @include mq($screen-xl) {
            padding: $spacer--medium 0;
        }
    }

    &__subcategory-item {
        display: flex;
    }

    &__subcategory-link {
        width: max-content;
        padding: $spacer--medium $spacer--large;
        color: $category-header__subcategory-color;
        background-color: $category-header__subcategory-bg;
        font-size: $font-size-medium;
        font-weight: $font-weight-base;
        border: 1px solid $category-header__subcategory-bg;
        border-radius: 5px;

        &:hover,
        &:focus {
            border-color: $black;
            color: $category-header__subcategory-color;
        }

        &--active {
            font-weight: $font-weight-bold;
            border-color: $black;
        }
    }
}

.solr-category-header {
    h1 {
        margin: 0 $spacer;

        @include mq($screen-m) {
            margin: 0 30px;
        }

        @include mq($screen-xl) {
            margin: 0;
        }

        span {
            color: $black;
            font-size: $font-size-base;
            line-height: 58px;

            @include mq($screen-m) {
                font-size: $font-size-extra-large;
            }
        }
    }
}
