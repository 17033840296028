.shopping-lists-add {
    position: relative;

    &__form,
    &__form-msg {
        position: absolute;
        width: calc(100% - 15px);
        padding: $spacer--large;
        margin: 0 $spacer--large 0 0;
        z-index: 11;
        background-color: $white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    }

    &__input-wrap {
        margin-bottom: $spacer--medium;
    }

    &__label {
        text-transform: none;
    }

    &__form-button {
        width: 100%;
    }

    &__error {
        margin-top: $spacer;
        color: $color-danger;
        font-size: 13px;
    }
}
