$base-slider__nav-icon-offset: 10px;

.base-slider {
    width: 100%;
    margin: $spacer--extra-large 0;

    .slider {
        &__prev,
        &__next {
            position: absolute;
            z-index: 10;
            top: 50%;
            border: 1px solid $custom-cms-color-gray-lighter;
        }

        &__prev {
            left: 0;
            transform: translate(-$base-slider__nav-icon-offset, -50%);
        }

        &__next {
            right: 0;
            transform: translate($base-slider__nav-icon-offset, -50%);
        }

        &__navigation-icon {
            border: 1px solid $gray-light;

            &:hover,
            &:focus,
            &:active {
                background-color: $color-primary;
                border-color: $color-primary;
            }
        }

        &__item {
            max-width: 100%;
        }
    }

    .slick-track {
        display: flex;
        column-gap: $spacer--medium;
    }

    .catalog-item__name {
        min-height: 3rem;
    }
}
