.account-section {
    margin-bottom: $spacer--medium;

    &__title {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        padding-left: $spacer--large;
        color: $gray-darker;
        background-color: $gray-lightest;
        font-weight: $font-weight-bold;
    }

    &__expand-button {
        position: absolute;
        top: 0;
        right: 0;
    }

    &__add-button {
        width: 100%;

        .icon {
            width: 12px;
            height: 12px;
        }
    }

    &__content {
        display: none;
        padding: $spacer--medium 0;
    }

    .tab {
        margin-bottom: $spacer--medium;

        &:last-child {
            padding-bottom: 0;
        }

        .tab__title {
            flex: 0 1 75px;
        }

        .tab__edit-details {
            flex: 0 1 auto;
            order: -1;
            background: transparent;
            padding: $spacer--large;
            height: auto;

            &:hover,
            &:focus {
                background: $gray-lightest;
            }
        }

        .tab__content {
            padding: $spacer--large;
            border: 1px solid $form-elements-border-color;
        }

        @include mq($screen-m) {
            .tab__title {
                flex: 0 1 auto;
            }
        }

        &--disabled {

            .account-details__remove-button {
                color: $gray-light;
            }

            .tab__content,
            .tab__title {
                border: 0;
                background: rgba($gray-lightest, 0.5);
                color: $gray-light;
            }

            .list {
                .list__value,
                .list__label {
                    color: $gray-light;
                }
            }
        }
    }

    &--is-open {
        margin-bottom: 0;

        .account-section__expand-button {
            .icon {
                transform: rotate(180deg);
            }
        }

        .account-section__content {
            display: block;
        }
    }
}
