.note {
    padding: $spacer--extra-large $spacer--extra-large $spacer--medium;
    background-color: $gray-lightest;

    &__action {
        position: relative;
        display: none;
        flex-flow: row wrap;
        justify-content: flex-end;

        &--active {
            display: flex;
        }
    }

    &__action-title {
        width: 100%;
        margin-top: $spacer--extra-large;
    }

    &__field {
        width: 100%;
    }

    &__button {
        min-width: 200px;
        margin-top: $spacer--medium;
        width: 100%;
        @include mq($screen-m) {
            width: auto;
        }

        &--remove {
            @include mq($screen-m) {
                margin-right: $spacer--medium;
            }
        }
    }

    &__button-close {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
    }
}
