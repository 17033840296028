.loyalty {
    &__button {
        display: inline-flex;

        &--primary {
            min-width: 270px;
            border-radius: $spacer;
            background: #ff7001;
        }
    }

    &__slider {
        padding: 40px 0;
        margin-bottom: 16px;

        .home-slider__item {
            margin: auto;
            max-width: 188px;
        }
    }

    &__slider-container {
        margin: auto;
        max-width: 1600px;
    }

    &__rewards {
        margin: 0 0 $spacer--extra-large;
    }
}
