$label-margin-top    : 0;
$label-margin-right  : 0;
$label-margin-bottom : $spacer;
$label-margin-left   : 0;
$label-font-size     : $font-size-base;
$label-color         : $font-color-base;
$label-font-family   : $font-family-base;
$label-font-weight   : $font-weight-normal;
$label-line-height   : $font-line-height;
$label-transition    : none;
$label-text-transform: uppercase;

.label {
    display: block;
    margin: $label-margin-top $label-margin-right $label-margin-bottom $label-margin-left;
    font-size: $label-font-size;
    color : $label-color;
    font-family: $label-font-family;
    font-weight: $label-font-weight;
    line-height: $label-line-height;
    text-transform: $label-text-transform;
    transition: $label-transition;

    &--inline {
        margin: 0;
    }

    &--hidden {
        // a11y friendly hiding
        position: absolute;
        clip: rect(0 0 0 0);
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        overflow: hidden;
        border: 0;
    }
}

label {
    @extend .label;
}
