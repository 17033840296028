$radio-card__icon-size: 24px;
$radio-card__icon-before-size: 16px;

.radio {
    &--cards {
        .radio {
            &__fieldset {
                display: flex;
                column-gap: $spacer--large;
            }

            &__legend {
                display: none;
            }

            &__handler {
                width: 50%;
                height: 130px;
                padding: $spacer--large;
                border: 1px solid $gray-lighter;

                &--active {
                    border-color: $color-primary;
                }
            }

            &__field {
                height: $radio-card__icon-size;
                width: $radio-card__icon-size;

                &:checked {
                    & + .radio__label {
                        color: inherit;
                    }
                }
            }

            &__icon {
                border-radius: $radio-card__icon-size;
                width: $radio-card__icon-size;
                height: $radio-card__icon-size;

                &::before {
                    width: $radio-card__icon-before-size;
                    height: $radio-card__icon-before-size;
                    border-radius: $radio-card__icon-before-size;
                }
            }

            &__label {
                height: $radio-card__icon-size;
                line-height: $radio-card__icon-size;
                font-size: $font-size-base;
                text-transform: none;
                margin-bottom: 13px;
            }
        }
    }
}
