$col-item-height: 50px;

.returns-add-product-info {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: $spacer--extra-large;
    @include mq($screen-m) {
        flex-flow: row nowrap;
        padding: 0;
    }
    @include mq($screen-l) {
        padding: $spacer--extra-large;
    }
    &__col {
        display: flex;
        flex: 1 1 100%;
        padding: 0;
        margin: 0;
        font-size: $font-family-base;
        @include mq($screen-m) {
            flex: 1 1 auto;
            padding: 0 $spacer--medium;
            font-size: $font-size-extra-small;
        }
        @include mq($screen-l) {
            margin: 0;
            font-size: $font-size-base;
        }

        // checkbox column
        &:first-child {
            @include mq($screen-m) {
                display: flex;
                align-items: flex-start;
                flex: 0 0 auto;
                padding-left: 0;
                margin: $spacer--extra-large 0 $spacer--extra-large $spacer--extra-large;
            }
            @include mq($screen-l) {
                margin: 0;
            }
        }
        // return-view-details column
        &:nth-child(2) {
            justify-content: space-between;
            @include mq($screen-m) {
                flex: 0 1 320px;
                margin: $spacer--extra-large 0;
            }
            @include mq($screen-l) {
                flex: 0 1 430px;
                margin: 0;
            }
        }
        // total qty column
        &:nth-child(3) {
            padding: $spacer 0;
            @include mq($screen-m) {
                height: $col-item-height;
                flex: 1 0 auto;
                align-items: center;
                justify-content: center;
                padding: 0 $spacer--medium;
                margin: $spacer--extra-large 0;
            }
            @include mq($screen-l) {
                margin: 0;
                height: $col-item-height;
            }
        }
        // qty-input column
        &:nth-child(4) {
            height: auto;
            align-items: center;
            justify-content: center;
            padding: $spacer 0;
            @include mq($screen-m) {
                height: $col-item-height;
                flex: 1 0 auto;
                align-items: center;
                padding: 0 $spacer--medium;
                margin: $spacer--extra-large 0;
            }
            @include mq($screen-l) {
                margin: 0;
                height: $col-item-height;
            }
        }

        // button column
        &:last-child {
            width: 100%;
            @include mq($screen-m) {
                flex: 0 0 $col-item-height;
                margin: 0;
                padding: 0;

            }
            @include mq($screen-l) {
                flex: 0 0 200px;
                padding-right: 0;
            }
        }
    }
    // qty-total
    &__qty-total-label {
        margin-right: auto;
        font-weight: $font-weight-thin;
        white-space: nowrap;
        @include mq($screen-m) {
            margin: 0;
        }
    }
    &__qty-total-value {
        font-weight: $font-weight-bold;
    }

    // qty
    &__qty-label {
        margin-right: auto;
        font-weight: $font-weight-thin;
        white-space: nowrap;
        @include mq($screen-m) {
            margin: 0;
        }
    }
    &__qty-input {
        width: $col-item-height;
        margin: 0 $spacer--medium;
        &--invalid {
            border: 1px solid $red;
        }
        input {
            padding: $spacer--large $spacer $spacer--large $spacer--medium !important; //sass-lint:disable-line no-important
        }
    }
    &__qty-unit {
        font-weight: $font-weight-bold;
    }

    // button
    &__toggle {
        width: 100%;
        height: $col-item-height;
        margin-top: $spacer--medium;
        white-space: nowrap;
        @include mq($screen-m) {
            margin: 0;
            height: auto;
            .button__text {
                display: none;
            }
        }
        @include mq($screen-l) {
            height: $col-item-height;
            .button__text {
                display: block;
            }
        }
        &--hidden {
            svg {
                transform: rotate(180deg);
            }
        }
        svg {
            width: 11px !important;//sass-lint:disable-line no-important
            height: 11px !important;//sass-lint:disable-line no-important
        }
    }
}
