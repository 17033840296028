.products-map {
    position: relative;
    width: 100vw;
    margin-left: 50%;
    left: -50vw;

    &__content {
        position: relative;
    }

    &__points {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &__point {
        position: absolute;
        margin-top: -16px;
        margin-left: -16px;

        @include mq($screen-m) {
            margin-top: -20px;
            margin-left: -20px;
        }
    }
}
