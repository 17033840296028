.cart-noitems {
    &__top {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: $spacer--large;
    }
    &__heading {
        margin: 0;
    }
    &__hint {
        margin-left: $spacer;
    }
    &__hint-content {
        display: block;
        width: 300px;
        white-space: normal;
        line-height: 1.75;
        text-align: center;
    }
}
