.loyalty-partners {
    display: flex;
    flex-flow: row wrap;
    &__item {
        flex: 0 1 50%;
        display: flex;
        &:nth-child(odd) {
            justify-content: flex-end;
            border-right: $border-base;
        }
        &:nth-child(even) {
            justify-content: flex-start;
        }
        @include mq($screen-m) {
            flex: 0 1 33.333%;
            &:nth-child(odd),
            &:nth-child(even) {
                justify-content: center;
                border: none;
            }
            &:nth-child(3n+2) {
                border-left: $border-base;
                border-right: $border-base;
            }
        }
    }
    &__logo {
        width: auto;
    }
}
