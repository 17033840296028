.cms-page {
    padding: $spacer--large $spacer--medium;

    &--loyalty-program,
    &--skalar {
        li {
            font-weight: $font-weight-bold;
            line-height: $font-line-height;
        }
    }

    &--loyalty-program {
        h2 {
            padding: 0 0 $spacer;
            font-size: 34px;
        }
        h3.button {
            margin: $spacer--large 0;
            cursor: default;
            &:hover,
            &:focus {
                background-color: $color-primary;
                color: $white;
            }
        }

        h4 {
            margin: 60px 0 $spacer--medium;
        }
    }

    &--advices-list {
        padding: 0 0 30px;

        .cms-page__content {
            border-bottom: 1px solid $gray-lightest;
            margin: 50px 0;
        }
    }

    &--advices-view {
        padding: 0;

        ul {
            line-height: 1.6;
            margin: 0 0 $spacer--medium 0;
        }

        li {
            padding-bottom: $spacer--medium;
        }

        img {
            width: auto;
            margin-bottom: $spacer--medium;
        }

        .cms-page__primary-image {
            width: 100%;
        }

        .cms-advice-actions {
            margin: 30px 0 50px;
        }
    }

    &--ct {
        padding: 25px $spacer;
        max-width: 750px;
        margin: 0 auto;

        @include mq($screen-xl) {
            max-width: 1020px;
        }

        @include mq($screen-xxl) {
            max-width: 1200px;
        }

        .heading--first-level {
            font-size: 18px;

            @include mq($screen-m) {
                font-size: 23px;
            }
        }
    }

    &--custom-cms {
        figure {
            margin: 0;
        }

        // sass-lint:disable no-important
        .cms-section {
            .pagebuilder-column-group {
                flex-direction: column;
                margin: 0 -#{$spacer--medium};

                @include mq($screen-m) {
                    flex-direction: row;
                }
            }

            .pagebuilder-column,
            .pagebuilder-column:not(.cta-banner__content) {
                width: 100% !important;
                padding: 0 $spacer--medium;
            }

            &--full-width {
                .pagebuilder-column-group,
                .pagebuilder-column:not(.cta-banner__content) {
                    margin: 0;
                }
            }
        }

        .tiles-list,
        .articles {
            .pagebuilder-column-group {
                display: grid !important;
            }

            .pagebuilder-column {
                width: auto !important;
            }
        }

        .tiles-list--surplus-hidden {
            .pagebuilder-column-group:nth-of-type(n + 2) {
                @include mq($max-screen: $screen-xl - 1) {
                    display: none !important;
                }
            }
        }

        .cta-banner {
            &.cms-section {
                &.cms-section--full-width {
                    .pagebuilder-column {
                        @include mq($screen-m) {
                            width: 100% !important; // sass-lint:disable no-important
                        }

                        @include mq($screen-l) {
                            width: 50% !important; // sass-lint:disable no-important
                            padding-left: 0;
                        }
                    }

                    .pagebuilder-column-group {
                        @include mq($screen-m) {
                            flex-direction: column;
                        }

                        @include mq($screen-l) {
                            flex-direction: row;
                        }
                    }
                }
            }
        }
        // sass-lint:enable no-important

        .submenu {
            padding: 32px 0 64px;

            @include mq($screen-m) {
                padding: 48px 0 74px;
            }
        }
    }

    &__heading {
        line-height: 30px;
        padding-bottom: $spacer--extra-large;
        margin-bottom: 34px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.07);

        &--secondary {
            margin-bottom: $spacer--medium;
        }
    }

    &__ct-subtitle {
        line-height: 30px;
        text-transform: uppercase;
        margin-bottom: 25px;
        color: $gray-dark;
    }

    &__ct-paragraph {
        font-size: $font-size-medium;
        margin-bottom: $spacer--medium;
        line-height: 30px;
        color: $gray-dark;

        &--dark {
            color: $gray-darker;
        }

        &--secondary {
            margin-bottom: $spacer--extra-large;
        }
    }

    &__ct-content {
        font-size: $font-size-small;
        color: $gray-dark;

        .paragraph {
            font-size: $font-size-small;
            margin-bottom: $spacer--large;
            color: $gray-dark;
        }
    }

    &__ct-columns {
        @include mq($screen-l) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__ct-column-left,
    &__ct-column-right {
        @include mq($screen-l) {
            flex-basis: calc(50% - 50px);
        }
    }

    &__ct-map {
        width: 100%;
        height: 300px;
    }
}
