$partner-list__width         : 50%;
$partner-list__width\@medium : 33.33%;

.partner-list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    flex-wrap: wrap;
    padding: $spacer--extra-large 0;

    &__item {
        flex-basis: $partner-list__width;
        max-width: $partner-list__width;
        height: 100px;
        padding: 0 25px;
        border-style: solid;
        border-color: $gray-lighter;
        border-width: 0 1px 0 0;

        &:nth-of-type(even) {
            border-width: 0;
        }

        @include mq($screen-m) {
            flex-basis: $partner-list__width\@medium;
            max-width: $partner-list__width\@medium;
            height: 125px;
            padding: 0 50px;

            &:nth-of-type(even) {
                border-width: 0 1px 0 0;
            }

            &:nth-child(3n) {
                border-width: 0;
            }
        }

        @include mq($screen-xl) {
            height: 150px;
        }
    }

    &__image {
        height: 100%;
        margin: 0 auto;
        width: initial;
        max-width: initial;
    }
}
