.footer {
    &--print {
        display: block;
        background: none;
        height: 140px;
        margin-top: -140px;
    }

    &__handler {
        display: block;
        text-align: center;
        padding-top: 15px;
        border-top: 1px solid $gray-lightest;

        p {
            color: $gray-dark;
            font-size: 13px;
            margin-bottom: $spacer;
        }
    }
}
