.document-filters {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    position: relative;
    z-index: 3;

    &__toggle {
        flex: 0 0 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include mq($screen-m) {
            flex-direction: row;
            justify-content: flex-end;
        }
    }

    &__toggle-button {
        background-color: $white;

        &--active {
            .icon {
                transform: rotate(180deg);
            }
        }

        &:hover,
        &:focus {
            background-color: $gray-lightest;
            color: $color-secondary;
        }

        .icon {
            fill: $color-secondary !important; // sass-lint:disable-line no-important
            width: 12px;
            margin-left: $spacer;
            transition: $transition-base;
        }
    }

    &__wrapper {
        display: flex;
        flex-flow: row wrap;
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: flex-start;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__filter,
    &__submit {
        flex: 0 0 100%;
        max-width: 100%;
        padding: $spacer 0;
        @include mq($screen-m) {
            flex-basis: 50%;
            max-width: 50%;
            padding: $spacer $spacer--large;
        }
        @include mq($screen-l) {
            flex-basis: 25%;
            max-width: 25%;
            padding: $spacer $spacer--large;
        }
    }

    &__submit {
        display: flex;
        align-items: flex-start;
        margin-left: auto;
    }

    &__submit-button {
        @extend .button;
        width: 100%;
        margin-top: 22px;
    }

    &__reset {
        flex: 0 0 100%;
        display: flex;
        justify-content: flex-end;
        .document-filters__reset-icon {
            width: 12px;
        }
    }

    &__heading {
        flex: 0 0 100%;
        color: $gray-darker;
        font-size: $font-size-base;
        line-height: $font-line-height;

        @include mq($screen-m) {
            padding: $spacer $spacer--large;
        }
    }

    .filter {
        &--active {
            margin: 0;
        }
    }
}
