.returns-view-items {
    &__row {
        display: flex;
        flex-flow: row wrap;
        background-color: $gray-lightest;
        padding: $spacer--large;
        @include mq($screen-m) {
            padding: $spacer--extra-large $spacer--medium;
        }
        @include mq($screen-l) {
            padding: $spacer--extra-large;
        }
        &:nth-child(n+2) {
            padding-top: $spacer--large;
            border-top: 1px solid $gray-lighter;
            @include mq($screen-m) {
                padding-top: $spacer--extra-large;
            }
        }
    }
    &__column {
        flex: 1 1 100%;
        @include mq($screen-m) {
            flex-grow: 1;
            flex-shrink: 0;
            &:nth-child(1) {
                flex-basis: 45%;
            }
            &:nth-child(2) {
                flex-basis: 55%;
            }
        }
    }
}
