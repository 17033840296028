.loyalty-list {
    padding: $spacer--semi-large $spacer--extra-large;

    .list__label,
    .list__value {
        line-height: 1.7;
    }
    .list__label {
        max-width: 50%;
        font-weight: $font-weight-base;
        color: $loyalty-gray-dark;

        &.loyalty-list__label {
            &--bold {
                font-weight: $font-weight-bold;
            }
            &--large {
                font-size: $font-size-extra-large;
            }
        }
    }
    .list__value {
        font-weight: $font-weight-bold;
        color: $loyalty-gray-dark;


        &.loyalty-list__value {
            &--large {
                font-size: 24px;
                color: $loyalty-orange;
            }
        }
    }
    .list__item {
        padding: 0 0 $spacer--semi-large;

        &--swap-order {
            order: 1;
        }
    }

    &__note {
        display: block;
        position: relative;
        margin: $spacer 0 $spacer--medium;
        font-weight: $font-weight-bold;
        font-size: 10px;
        line-height: 1.25;

        &--warning {
            &::before {
                content: '!';
                position: absolute;
                left: -5px;
                color: $red;
                font-weight: $font-weight-bold;
            }
        }
    }

    &__heading {
        padding: 0;
        border-bottom: 1px solid #ededed;
    }

    &--double-value {
        padding-right: 75px;
        .list__item {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &--bg {
        padding: $spacer--extra-large;
        background-color: $gray-lightest;

        .list__item {
            padding: 0;
        }
    }
}
