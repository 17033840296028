.returns-add-return-info {
    padding: $spacer--extra-large;
    &__row {
        display: flex;
        flex-flow: row wrap;
        @include mq($screen-l) {
            flex-flow: row nowrap;
        }
    }
    &__separator {
        border-top: 1px solid $gray-lighter;
        margin: $spacer--extra-large 0 $spacer--large 0;
    }
    &__col {
        display: flex;
        flex-direction: column;
        flex: 1 1 100%;

        &--bottom-align {
            @include mq($screen-s) {
                margin-top: 40px;
            }
        }

        @include mq($screen-s) {
            flex: 1 1 50%;
            &:first-child {
                padding: 0 $spacer--large 0 0;
            }
            &:nth-child(2) {
                padding: 0 0 $spacer--large 0;
            }
            &:last-child {
                padding: 0 0 0 0;
            }
        }
        @include mq($screen-m) {
            flex: 1 1 25%;
            &:nth-child(2) {
                padding: 0 $spacer--large 0 $spacer--large;
            }
            &:last-child {
                padding: 0 0 0 $spacer--large;
            }
        }
        textarea {
            flex: 1;
        }
    }
    &__col-item {
        width: 100%;
        margin-bottom: $spacer--medium;
        &--input {
            height: 50px;
            min-height: 50px;
            max-height: 50px;
            box-sizing: border-box;
            border: $input__field-border;
            .input__field {
                height: 48px;
                min-height: 48px;
                max-height: 48px;
                border: none;
            }
        }
        &--label {
            height: 20px;
            min-height: 20px;
            max-height: 20px;
            margin: $spacer--medium 0;
            white-space: nowrap;
        }
        &--invalid {
            border: 1px solid $red;
        }
    }
    &__subrow {
        display: flex;
        flex-flow: row wrap;
        @include mq($screen-m) {
            flex-flow: row nowrap;
        }
    }
    &__subcol {
        flex: 1 0 100%;
        @include mq($screen-m) {
            flex: 1 0 50%;
        }
    }
    .label {
        z-index: unset;
    }
    .vdp-datepicker {
        input {
            height: 48px;
            min-height: 48px;
            max-height: 48px;
            border: none;
        }

        input,
        input::placeholder {
            font-weight: $font-weight-thin;
            font-family: $font-family-sans-serif;
            color: $gray-dark;
            font-weight: $font-weight-thin;
            font-size: $font-size-base;
        }
    }
    .multiselect {
        &__single,
        &__element {
            font-size: $font-size-base;
            font-weight: $font-weight-thin;
            white-space: nowrap;
        }
        &__tags {
            height: 48px;
            min-height: 48px;
            max-height: 48px;
            border: none;
        }
        &__content-wrapper {
            border: $input__field-border;
            width: calc(100% + 2px);
            left: -1px;
        }
        &__select {
            height: 48px;
            top: 0;
        }
        &--disabled {
            .multiselect__tags,
            .multiselect__single {
                background: $gray-lightest;
                color: $black;
            }
            .multiselect__select {
                background: $gray-lightest;
            }
        }
    }
}
