$pim-icons__icon-size : 30px;
$pim-icons__margin    : $spacer;

.pim-icons {
    display: flex;
    gap: $spacer; // sass-lint:disable-line no-misspelled-properties

    &__icon-wrapper {
        .hint__content {
            min-width: min-content;

            &--offset {
                @include mq($max-screen: $screen-l) {
                    transform: translateX(-50%);
                }
            }
        }
    }

    &__icon {
        min-width: $pim-icons__icon-size;
        width: $pim-icons__icon-size;
        height: $pim-icons__icon-size;
    }
}
