.cart {
    &__description {
        margin-bottom: $spacer;

        @include mq($screen-m) {
            display: flex;
            .list {
                align-self: center;
            }
        }

        .input {
            &__field {
                min-width: 250px;
                &--filled {
                    padding-top: 0;
                }
            }
        }

        &--button {
            position: absolute;
            right: 0;
            top: 0;
            background: transparent;

            .icon {
                fill: $gray-light;
            }

            &:hover,
            &:focus {
                background: transparent;

                .icon {
                    fill: $blue;
                }
            }
        }
    }

    &__list {
        font-size: $font-size-small;
        margin-bottom: $spacer--large;
        @include mq($screen-m) {
            margin-bottom: 0;
            margin-right: $spacer--large;
        }

        .list__label {
            font-weight: $font-weight-bold;
        }
        .list__value {
            color: $gray-dark;
            font-weight: $font-weight-thin;
        }
    }
}
