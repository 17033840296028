.link {
    color: $color-primary;
    font-weight: 700;
    text-decoration: none;
    transition: $transition-base;

    &:hover {
        color: $color-secondary;
        text-decoration: none;
    }

    // visible only for screen readers and when focused
    &--visually-hidden {
        @include visually-hidden($usePseudo: true);
    }

    &--invert {
        color: $gray-dark;

        &:hover,
        &:focus {
            color: $color-primary;
        }
    }
}

a {
    @extend .link;
}
