$checkbox__margin               : $spacer--medium;
$checkbox__line-height          : $font-line-height;
$checkbox__label-padding        : 40px;
$checkbox__label-before-size    : 30px;
$checkbox__label-color          : $gray-dark;
$checkbox__icon-size            : 20px;
$checkbox__gap                  : 0;
$checkbox__icon-border-radius   : $form-elements-radius;
$checkbox__icon-check-mark-color: $white;
$checkbox__icon-fill            : $color-primary;
$checkbox__icon-transition      : $transition-base;
$checkbox__border               : 1px solid $form-elements-border-color;
$checkbox__color--fake          : $gray-dark;

.checkbox {
    position: relative;
    margin-bottom: $checkbox__margin;

    &--link {
        display: block;
        text-decoration: none;
        color: $checkbox__color--fake;

        &:hover {
            text-decoration: underline;
        }
    }
    &--link-checked {
        display: block;
        .checkbox__icon {
            opacity: 1;
        }
    }

    &__field {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;

        &:checked + .checkbox__icon {
            opacity: 1;
        }
    }

    &__label {
        display: block;
        padding-left: $checkbox__label-padding;
        line-height: $checkbox__line-height;
        color: $checkbox__label-color;
        font-weight: $font-weight-thin;
        text-transform: none;
        cursor: pointer;
        &:before {
            content: '';
            position: absolute;
            top: $checkbox__gap;
            left: $checkbox__gap;
            width: $checkbox__label-before-size;
            height: $checkbox__label-before-size;
            border: $checkbox__border;
            border-radius: $checkbox__icon-border-radius;
        }
    }

    &__icon {
        position: absolute;
        top: 5px;
        left: 5px;
        width: $checkbox__icon-size;
        height: $checkbox__icon-size;
        fill: $checkbox__icon-fill;
        background: $checkbox__icon-check-mark-color;
        cursor: pointer;
        opacity: 0;
        transition: $checkbox__icon-transition;
    }
}
