.modal-content {
    padding: 0;
    margin: 0;
    position: relative;

    &__close {
        position: absolute;
        right: 6px;
        top: 17px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover,
        &:focus {
            .icon {
                fill: $gray;
            }
        }

        .icon {
            width: 15px;
            height: 15px;
            fill: $gray-light;
        }
    }

    &__title {
        font-size: 23px;
        padding: 20px 15px;
        margin-bottom: 0;
        border-bottom: 1px solid $border-color-base;
    }

    &__text {
        padding: 0 15px 0 30px;
        line-height: 1.6;
        color: $gray-light;
    }

    &__text-wrapper {
        width: 100%;
        height: 200px;
        max-height: 200px;
        padding-top: 15px;
    }

    &__actions {
        width: 100%;
        border-top: 1px solid $border-color-base;
        padding: 15px;
        @include mq($screen-l) {
            display: flex;
            justify-content: flex-start;
        }
    }

    &__button {
        width: 100%;
        margin-bottom: 10px;
        margin-right: 15px;

        @include mq($screen-l) {
            width: auto;
            min-width: 25%;
        }

        &:last-child {
            margin-right: 0;
        }

        &--print {
            background-color: $white;

            @include mq($screen-l) {
                justify-content: flex-start;
                min-width: auto;
                margin-right: auto;
            }

            &:focus,
            &:hover {
                background-color: $white;
                color: $color-secondary;
                &.button--secondary.button--icon {
                    .icon {
                        fill: $color-secondary;
                    }
                }
            }
        }
    }
}
