.loyalty-about {
    margin-top: $spacer--extra-large;
    &__row {
        display: flex;
        flex-flow: row wrap;
    }
    &__col1,
    &__col2 {
        flex: 1 1 100%;
        @include mq($screen-l) {
            flex: 0 1 50%;
        }
    }
    &__col1 {
        @include mq($screen-l) {
            padding-right: $spacer--medium;
        }
    }
    &__col2 {
        @include mq($screen-l) {
            padding-left: $spacer--medium;
        }
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        border-top: 1px solid $border-color-base;
        margin: $spacer--large 0;
        padding: $spacer--large 0;
        @include mq($screen-l) {
            flex-direction: row;
        }
    }
    &__actions-button {
        justify-content: flex-start;
        @include mq($screen-m) {
            justify-content: center;
        }
    }
}
