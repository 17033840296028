.returns-add-search {
    max-width: 360px;
    height: auto;
    &__caret {
        position: absolute;
        right: 0;
        width: 50px;
        height: 50px;
        padding: $spacer--large;
        top: 50%;
        transform: translateY(-50%);
        background-color: $color-primary;
        fill: $white;
        cursor: pointer;
    }
    &__option {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        width: 100%;
    }
    &__option-vbeln {
        flex-basis: 100%;
        display: flex;
        align-items: flex-end;
        font-weight: $font-weight-bold;
        font-size: $font-size-base;
    }
    &__option-created-at {
        flex-basis: 100%;
        font-size: 12px;
        color: $gray;
    }
    &__option-type {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-weight: $font-weight-bold;
        font-size: $font-size-extra-small;
        color: $color-primary;
        text-transform: uppercase;
    }

    //box for placeholder, search input, selected option
    .multiselect__tags {
        display: flex;
        align-items: stretch;
        padding: 0 50px 0 $spacer--large;

        & > span,
        input,
        .multiselect__single {
            display: flex;
            align-items: stretch;
            padding: 0;
            margin: 0;
            line-height: 1.2;
        }

        .multiselect__placeholder {
            display: flex;
            align-items: center;
            font-size: $font-size-medium;
            margin: 0;
            padding: 0;
        }
    }

    // box for options on list
    .multiselect__element {
        .multiselect__option {
            display: flex;
            padding: 0 0 0 $spacer--large;
        }
    }
}
