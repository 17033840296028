.loyalty-summary {
    &__heading {
        margin: 0;
        font-size: 24px;
    }

    &__heading,
    &__date {
        color: $loyalty-gray-dark;
    }
}
