$catalog-banner__button-color: #e51b2b;
$catalog-banner__button-color--active: #f94144;
$catalog-banner__button-color--green: #038c3e;
$catalog-banner__button-color--green-active: #1ad643;

.catalog-banner {
    width: 100%;
    max-width: 1190px;
    overflow: hidden;
    margin: 0 auto $spacer--large;

    &__link {
        color: $white;
        text-decoration: none;

        &:hover {
            color: $white;

            .catalog-banner__button {
                color: $white;
                background: $catalog-banner__button-color--active;

                &--green {
                    background: $catalog-banner__button-color--green-active;
                }
            }
        }
    }

    &__image {
        display: flex;
        align-items: center;
        height: 25vw;
        width: 100%;
        background-repeat: no-repeat;
        background-size: auto 25vw;

        @include mq($screen-s) {
            height: 150px;
            background-size: auto 150px;
        }

        &--small {
            display: flex;
            align-items: center;
            height: 15vw;
            width: 100%;
            background-repeat: no-repeat;
            background-size: auto 15vw;

            @include mq($screen-s) {
                height: 80px;
                background-size: auto 80px;
            }
        }
    }

    &__container {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding: $spacer--large;
    }

    &__button {
        margin-right: $spacer--extra-large;
        padding: $spacer--medium $spacer--extra-large;
        background: $catalog-banner__button-color;
        font-size: $font-size-small;
        font-weight: $font-weight-bold;
        border-radius: 4px;
        transition: all 0.2s;

        &--green {
            background: $catalog-banner__button-color--green;
        }

        &--desktop {
            display: none;

            @include mq($screen-m) {
                display: block;
            }
        }
    }
}
