// TODO temporary workaround
.requisition-list-requisition-view._has-modal {
    .button.action-close {
        min-width: 30px;
        width: 30px;
        height: 30px;
        padding: 0;
        svg {
            max-width: 18px;
            max-height: 18px;
        }
    }
    .modal-footer {
        display: flex;
        flex-direction: column;
        margin: 0;
        @include mq($screen-m) {
            flex-direction: row;
        }

    }
    button.action {
        @extend .button;
        margin: $spacer;
    }
}
