// Label
$quantity-update__label-color                        : $gray;
$quantity-update__label-font-weight                  : $font-weight-thin;
// Input
$quantity-update__input-width                        : 48px !default;
$quantity-update__input-color                        : $gray-dark !default;

// Button
$quantity-update__button-background                  : transparent;
$quantity-update__button-background-hover--disabled  : transparent;
$quantity-update__button-fill-hover--disabled        : $gray !default;

// Icon
$quantity-updater__icon-fill                         : $color-primary !default;
$quantity-updater__icon-spacing                      : 12px !default;

.quantity-update {
    display: inline-flex;
    align-items: center;

    &__input {
        border: 1px solid $gray-lighter;
        width: 57px;
        height: 40px;
        color: $quantity-update__input-color;
        text-align: center;
        font-weight: $font-weight-bold;

        &:focus {
            outline: none;
        }
    }

    &__icon.icon {
        // Specificity issue
        fill: $quantity-updater__icon-fill;
        width: $quantity-updater__icon-spacing;
        height: $quantity-updater__icon-spacing;
    }

    &__button {
        border: none;
        background: $quantity-update__button-background;
        border-radius: 0;
        margin: 0;
        height: 40px;
        min-width: 40px;

        &--plus {
            border-left: 0;
        }

        &--minus {
            border-right: 0;
        }

        &--disabled {
            pointer-events: none;

            &:hover,
            &:focus {
                background: $quantity-update__button-background-hover--disabled;

                .quantity-update__icon {
                    fill: $quantity-update__button-fill-hover--disabled;
                }
            }

            .quantity-update__icon {
                fill: $quantity-update__button-fill-hover--disabled;
            }
        }
    }

    &__label {
        margin: 0;
        color: $quantity-update__label-color;
        font-weight: $quantity-update__label-font-weight;
        text-transform: none;
        &--hidden {
            display: none;
        }
    }

    &__unit {
        font-weight: $font-weight-bold;
    }

    // Disable spin buttons in number input type
    input[type="number"]::-webkit-outer-spin-button, // sass-lint:disable-line no-vendor-prefixes
    input[type="number"]::-webkit-inner-spin-button { // sass-lint:disable-line no-vendor-prefixes
        -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes
        margin: 0;
    }
    input[type="number"] { // sass-lint:disable-line no-vendor-prefixes
        -moz-appearance: textfield; // sass-lint:disable-line no-vendor-prefixes
    }
}
