$tiles-list-max-width                   : 304px;
$tiles-list-max-width\@medium           : 678px;
$tiles-list-max-width\@xl               : 1041px;

$tiles-list--secondary-max-width\@medium: 656px;

$tiles-list_list-row-gap                : $custom-cms-spacer--extra-large;
$tiles-list_list-row-gap\@medium        : 56px;
$tiles-list_list-column-gap\@medium     : $custom-cms-spacer--extra-large;
$tiles-list_list-column-gap\@xl         : 44px;

$tiles-list-item-max-width              : 304px;

@keyframes tileFadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.tiles-list {
    max-width: $tiles-list-max-width;
    margin: auto;

    @include mq($screen-m) {
        max-width: $tiles-list-max-width\@medium;
    }

    @include mq($screen-xl) {
        max-width: $tiles-list-max-width\@xl;
    }

    &__item {
        max-width: $tiles-list-item-max-width;
        justify-self: center;
        animation: tileFadeIn 1s;

        @include mq($screen-m) {
            max-width: 100%;
        }
    }

    &__list,
    .pagebuilder-column-group {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: $tiles-list_list-row-gap; //sass-lint:disable-line  no-misspelled-properties
        padding: 0;
        margin: 0 auto;
        list-style: none;
        transition: 0.3s ease-in-out;

        @include mq($screen-m) {
            grid-template-columns: repeat(3, 1fr);
            row-gap: $tiles-list_list-row-gap\@medium; //sass-lint:disable-line  no-misspelled-properties
            column-gap: $tiles-list_list-column-gap\@medium;
        }

        @include mq($screen-xl) {
            grid-template-columns: repeat(4, 1fr);
            column-gap: $tiles-list_list-column-gap\@xl;
        }
    }

    &__button {
        width: $tiles-list-max-width;
        margin: 64px auto 0;

        @include mq($screen-m) {
            width: 181px;
        }

        @include mq($screen-xl) {
            display: none;
        }
    }

    &--secondary {
        max-width: 100%;

        @include mq($screen-m) {
            max-width: $tiles-list--secondary-max-width\@medium;
        }

        @include mq($screen-xl) {
            max-width: $custom-cms-section-max-width;
        }

        .tiles-list__list,
        .pagebuilder-column-group {
            grid-template-columns: 1fr;
            row-gap: 64px; //sass-lint:disable-line  no-misspelled-properties

            @include mq($screen-m) {
                grid-template-columns: repeat(2, 1fr);
                column-gap: $custom-cms-spacer--large;
            }

            @include mq($screen-xl) {
                grid-template-columns: repeat(4, 1fr);

                .tiles-list__item {
                    display: list-item;
                }
            }
        }
    }

    &--surplus-hidden {
        li {
            display: none !important;  // sass-lint:disable no-important
        }

        li:nth-child(-n+4) {
            display: list-item !important;  // sass-lint:disable no-important
        }
    }

    .pagebuilder-column-group {
        &:not(:last-of-type) {
            padding: 0 0 $tiles-list_list-row-gap;

            @include mq($screen-m) {
                padding: 0 0 $tiles-list_list-row-gap\@medium;
            }
        }
    }
}
