// Colors
$blue                                      : #0e81c5;
$red                                       : #ff5735;
$red-dark                                  : #B80606;
$green                                     : #73cc87;
$green-dark                                : #4b7b21;

// Grayscale colors
$gray-darker                               : #171717;
$gray-darker-secondary                     : #333;
$gray-dark                                 : #494949;
$gray                                      : #5e5e5e;
$gray-light                                : #afafaf;
$gray-lighter                              : #d7d6da;
$gray-lightest                             : #f0f1f3;

// Semantic color scheme
$color-primary                             : #0e81c5;
$color-primary--active                     : #00649f;
$color-primary--faded                      : #668ab3;

$color-secondary                           : #1c3c91;
$color-secondary--active                   : #0d286f;

$color-accent                              : #003c80;

$color-success                             : $green;
$color-danger                              : $red;

// custom-cms colors
$custom-cms-color-azure                    : #009AAC;
$custom-cms-color-gray-dark                : #49444E;
$custom-cms-color-gray-light               : #d1d2d4;
$custom-cms-color-gray-lighter             : #d8d8d8;

// loyalty program colors
$loyalty-orange                            : #ff7001;
$loyalty-purple                            : #5a0866;
$loyalty-gray-dark                         : #333;

// Borders
$border-color-base                         : rgba(0, 0, 0, 0.15);
$border-color-secondary                    : $gray-lighter;
$border-secondary                          : 1px solid $border-color-secondary;

// Outline
$outline-base                              : none;

// Spacers
$spacer                                    : 5px;
$spacer--medium                            : 10px;
$spacer--semi-large                        : 12px;
$spacer--large                             : 15px;
$spacer--extra-large                       : 20px;

// Typography
$font-family-sans-serif                    : "Roboto", sans-serif;

$font-color-base                           : $gray-darker;
$font-line-height                          : 1.6;

$font-size-extra-large                     : 20px;
$font-size-large                           : 17px;
$font-size-medium                          : 16px;
$font-size-base                            : 15px;
$font-size-small                           : 14px;
$font-size-extra-small                     : 13px;

$font-weight-thin                          : 300;
$font-weight-base                          : 400;
$font-weight-medium                        : 500;
$font-weight-bold                          : 700;

// Layout
$max-content-width                         : 1600px;
$max-content-width--secondary              : 1328px;
$min-content-width                         : 320px;

// Components
$border-radius                             : 0;
$border-radius-secondary                   : 5px;

$form-elements-border-color                : $border-color-base;
$form-elements-radius                      : 0;
$form-elements-radius--small               : 0;

$icon__padding                             : 0;
$grid__gutter                              : $spacer--extra-large;

$password-strength__background             : $gray-lightest;
$password-strength__background--none       : $gray-lightest;
$password-strength__background--weak       : $color-danger;
$password-strength__background--medium     : rgba($color-primary, 0.75);
$password-strength__background--strong     : rgba($green, 0.5);
$password-strength__background--very-strong: $green;

// Layout - breakpoints
$screen-3xl                                : 2560px;

// custom-cms variables
$custom-cms-spacer--medium                 : 8px;
$custom-cms-spacer--large                  : 16px;
$custom-cms-spacer--extra-large            : 24px;

$custom-cms-section-max-width              : 1328px;

$custom-cms-font-size-large                : 18px;
$custom-cms-font-size-extra-large          : 24px;

$custom-cms-font-line-height-large         : 2.677;


// Shadows
$box-shadow-base                    : 0 3px 16px 0 rgba(20, 32, 55, 0.1);
$box-shadow-secondary               : 0 3px 16px 0 rgba(18, 57, 134, 0.1);
$box-shadow-tertiary                : 0 2px 4px 0 rgba(20, 32, 55, 0.3);
