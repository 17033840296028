.infobar {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    background-color: $color-primary;
    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        padding-left: 40px;
        color: $white;
    }
    &__button {
        width: 40px;
        min-width: 40px;
        height: 40px;
        padding: 10px;
        svg {
            width: 10px !important; // sass-lint:disable-line no-important
            height: 10px !important; // sass-lint:disable-line no-important
        }
    }
}
