.home-recommendations {
    @include home-container();

    min-height: 665px;

    @include mq($screen-m) {
        min-height: 608px;
    }

    @include mq($screen-l) {
        min-height: 665px;
    }

    @include mq($max-content-width + 2 * $spacer) {
        min-height: 706px;
    }

    .slider {
        margin: 0 $spacer--large;

        @include mq($screen-m) {
            margin: 0 30px;
        }
    }
}
