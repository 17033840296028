.product-view-document-download {
    @extend .list;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax($min-content-width, 1fr));
    justify-content: center;
    gap: 75px; //sass-lint:disable-line  no-misspelled-properties
    padding: $spacer--extra-large 0;

    @include mq($screen-xl) {
        justify-content: flex-start;
    }

    &__item {
        display: inline-flex;
        flex-flow: column wrap;
        align-items: center;
    }
    &__label {
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
        line-height: 30px;
        text-align: left;
        color: $gray-darker;
        margin: 0 0 $spacer;
    }
    &__icon {
        width: 68px;
        height: 80px;
        margin: 0 0 $spacer--extra-large;
    }
    &__name {
        flex: 1;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        color: $gray-dark;
        font-weight: $font-weight-thin;
        margin: 0 0 $spacer--medium;
    }
    &__link {
        min-width: 142px;
    }
}
