$grid__gutter     : 16px !default;
$grid__side-gutter: false !default;

.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    @if ($grid__gutter > 0 and $grid__side-gutter == false) {
        margin-left: -($grid__gutter * 0.5);
        margin-right: -($grid__gutter * 0.5);
    }

    &__column {
        flex: 1 0 100%;
        @if ($grid__gutter > 0) {
            padding-left: ($grid__gutter * 0.5);
            padding-right: ($grid__gutter * 0.5);
        }

        // 2 cols
        &--1-2 {
            @include mq($screen-m) {
                @include grid-column-width(1, 2);
            }
        }

        // 3 cols
        &--1-3 {
            @include mq($screen-s) {
                @include grid-column-width(1, 2);
            }
            @include mq($screen-m) {
                @include grid-column-width(1, 3);
            }
        }

        &--2-3 {
            @include mq($screen-m) {
                @include grid-column-width(2, 3);
            }
        }

        // 4 cols
        &--1-4 {
            @include mq($screen-s) {
                @include grid-column-width(2, 4);
            }
            @include mq($screen-xl) {
                @include grid-column-width(1, 4);
            }
        }

        &--2-4 {
            @include mq($screen-xl) {
                @include grid-column-width(2, 4);
            }
        }

        &--3-4 {
            @include mq($screen-xl) {
                @include grid-column-width(3, 4);
            }
        }

        // 5 cols
        &--1-5 {
            @include mq($screen-m) {
                @include grid-column-width(1, 5);
            }
        }

        &--2-5 {
            @include mq($screen-m) {
                @include grid-column-width(2, 5);
            }
        }

        &--3-5 {
            @include mq($screen-m) {
                @include grid-column-width(3, 5);
            }
        }

        &--4-5 {
            @include mq($screen-m) {
                @include grid-column-width(4, 5);
            }
        }

        // 6 cols
        &--1-6 {
            @include mq($screen-s) {
                @include grid-column-width(2, 6);
            }
            @include mq($screen-m) {
                @include grid-column-width(1, 6);
            }
        }

        &--2-6 {
            @include mq($screen-s) {
                @include grid-column-width(2, 6);
            }
        }

        &--3-6 {
            @include mq($screen-s) {
                @include grid-column-width(3, 6);
            }
        }

        &--4-6 {
            @include mq($screen-s) {
                @include grid-column-width(4, 6);
            }
        }

        &--5-6 {
            @include mq($screen-m) {
                @include grid-column-width(5, 6);
            }
        }
    }
}
