.shopping-lists-table {
    margin-bottom: $spacer--large;

    thead th:last-child {
        visibility: hidden;
    }
    .dashboard-table__cell--remove {
        @include mq($screen-m) {
            max-width: 70px;
        }
        .button.button--icon.button--link {
            color: $color-secondary;
            & > .icon {
                fill: $color-secondary;
            }
            &:focus,
            &:hover {
                & > .icon {
                    fill: $color-primary;
                }
            }
            @include mq($screen-m) {
                margin: 0 auto;
                .button__text {
                    display: none;
                }
            }
        }
    }

    .dashboard-table__cell--order {
        &:before {
            display: none;
        }
        .button {
            width: 100%;
        }
    }
}
