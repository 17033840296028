$add-to-cart-button__height: 50px;
$add-to-cart-button__option-action-height: 42px;
.add-to-cart-button {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;

    &__primary-action {
        flex-basis: 100%;
        height: $add-to-cart-button__height;
        text-transform: uppercase;
        padding: 0 $spacer;
        font-size: $font-size-extra-small;

        &:hover {
            color: $white;
            background-color: $color-primary--active;
        }

        .icon {
            display: none;
        }

        &:disabled {
            opacity: 40%;
        }
    }

    &__option-action {
        position: relative;
        height: $add-to-cart-button__height;
        width: $add-to-cart-button__option-action-height;
        margin-left: 1px;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: -1px;
            height: 100%;
            width: 1px;
            background-color: $color-primary;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: -1px;
            height: 60%;
            width: 1px;
            margin: auto;
            background-color: $white;
        }

        &:hover {
            background-color: $color-primary--active;
            .icon {
                fill: $white;
            }
        }

        .icon {
            width: 10px;
            height: 10px;
            fill: $white;
            margin: 0 auto;
        }
    }
}
