@media print {
    @page {
        size: 210mm 297mm;
        margin: 0 5mm;
    }

    body {
        margin-top: 5mm;
    }

    nav,
    .header,
    .footer,
    .button,
    .search-bar,
    .my-orders__button-back,
    .dashboard-document-details__actions,
    .dashboard-table__button,
    .dashboard-table__button-expand,
    .invoices-view__tab,
    #invoice-history .tab__title, // sass-lint:disable-line no-ids
    .infobar {
        display: none !important; // sass-lint:disable-line no-important
    }

    .offer-view {
        th:nth-child(2),
        td:nth-child(2),
        .notes-list,
        .dashboard-tab {
            display: none !important; // sass-lint:disable-line no-important
        }
    }

    .icon {
        width: 20px;
        height: 20px;
    }

    .page-wrapper {
        min-height: 100%;
    }

    .page-main {
        padding-bottom: 140px;
    }

    .page-breake-before {
        page-break-before: always;
    }
}
