.v-message {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    color: $white;
    background-color: $green;
    padding: $spacer--medium 45px $spacer--medium $spacer--medium;

    @include mq($screen-m) {
        padding: $spacer--medium;
    }
    &--error {
        background-color: $red;
    }
    &__header {
        display: flex;
        flex-flow: row nowrap;
        flex: 1 1 100%;
        align-items: center;
        padding-bottom: 2px;
        .icon {
            width: 12px;
            height: 12px;
            flex: 0 0 auto;
            margin-right: $spacer;
            fill: $white;
        }
        .v-message__header-label {
            flex: 1 1;
            margin-right: auto;
            font-weight: $font-weight-bold;
        }
    }
    &__text {
        flex: 1 1 100%;
    }
    &__btn {
        position: absolute;
        top: 50%;
        right: 10px;
        width: 30px;
        min-width: 30px;
        height: 30px;
        padding: $spacer;
        margin: 0;
        transform: translateY(-50%);
        color: $white;
        @include mq($screen-m) {
            right: 20px;
        }
        &:hover,
        &:focus {
            background-color: transparent;
            outline: 1px $white solid;
            color: $white;
        }
        .icon {
            fill: $white !important; // sass-lint:disable-line no-important,
        }
    }
    &:nth-child(n+2) {
        margin-top: $spacer;
    }
}
