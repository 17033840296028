.comments {
    &__history {
        @extend .list;
        @extend .list--divided;

        display: flex;
        flex-flow: column nowrap;
        padding: 0;
        margin: $spacer $spacer--extra-large;
        list-style: none;
    }
    &__entry {
        display: flex;
        flex-flow: column nowrap;
        padding-top: $spacer--large;

        &:nth-child(1) {
            padding-top: 0;
        }

        &:nth-child(n+2) {
            border-top: 1px solid $gray-lightest;
        }
    }
    &__entry-info {
        font-size: $font-size-base;
        font-weight: $font-weight-bold;
    }

    &__form {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        background-color: $gray-lightest;
        padding: $spacer--extra-large;
    }
    &__input {
        flex: 1 1 100%;
        padding: 0;
        margin: 0;
    }
    &__label {
        position: relative;
        top: 0;
        left: 0;
        font-weight: $font-weight-bold;
    }
    &__textarea {
        margin: $spacer--medium 0;
    }
    &__button {
        display: inline-flex;
        flex: 0 1 318px;
        max-height: 45px;
    }
}
