$login__max-width                      : 360px;
$login__padding                        : 30px $spacer--medium;
$login__padding\@large                 : 150px $spacer--medium;
$login__link-height                    : 30px;
$login__logout-success-padding         : 30px 0;
$login__logout-success-margin          : 0 auto;
$login__logout-success-title-margin-top: $spacer--large;

.login {
    width: 100%;
    max-width: $login__max-width;
    padding: $login__padding;
    margin: 0 auto;

    @include mq($screen-l) {
        padding: $login__padding\@large;
    }

    &__link {
        @extend .button;
        @extend .button--icon;
        @extend .button--link;
        height: 40px;

        &:focus,
        &:hover {
            background-color: transparent;
        }

        .icon {
            fill: $color-primary;
            width: 12px;
            height: 12px;
            margin-left: 5px;
        }
        &--secondary {
            color: $color-secondary;
            &:focus,
            &:hover {
                color: $color-primary;
                .icon {
                    fill: $color-primary;
                }
            }

            .icon {
                fill: $color-secondary;
            }
        }
        &--back {
            justify-content: flex-start;
            padding-left: 0;

            .icon {
                margin: 0 5px 0 0;
            }
        }
    }

    &__logout-succes {
        padding: $login__logout-success-padding;
        margin: $login__logout-success-margin ;
        text-align: center;

        &-title {
            margin-top: $login__logout-success-title-margin-top;
        }
    }
}
.customer-b2b-login,
.customer-b2b-forgotpassword {
    .grid {
        margin: 0;
    }

    div.mage-error {
        color: $red;
        font-size: $font-size-extra-small;
        margin-top: $spacer;
    }

    .input:has(input.mage-error) {
        input {
            border-color: $red;
        }
    }
}
