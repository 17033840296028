/* sass-lint:disable-all */

/**
 * source: hint-theme.scss
 *
 * Defines basic theme for tooltips.
 *
 */

[class*="#{$hintPrefix}"] {
	/**
	 * tooltip body
	 */
	&:after {
		text-shadow: 0 -1px 0 darken($hintDefaultColor, $hintTextShadowDarkenAmount);
		box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
	}
}
