.cms-partners {
    width: 100%;
    margin: 30px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    &__logo {
        display: flex;
        margin: 0 0 15px;
        padding: 0 10px;
        flex-basis: 50%;
        justify-content: center;
        align-items: center;
        text-align: center;

        @include mq($screen-s) {
            flex-basis: 25%;
        }

        @include mq($screen-l) {
            flex-basis: calc(100% / 6);
        }
    }
}
