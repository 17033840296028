.cart-messages {

    &__wrapper {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    &__container {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 30px;
        width: 460px;
    }

    &__heading {
        text-align: center;
        margin-bottom: $spacer--extra-large;
    }

    &__icon {
        width: 70px;
        height: 70px;
        margin-bottom: $spacer--extra-large;
    }

    &__button-go-back {
        background: none;
        color: $blue;

        &:hover,
        &:focus {
            background: none;
        }

        .icon {
            fill: $blue;
        }
    }

    &__button-print {
        width: 250px;
        margin: 0 auto;
    }

    &__print-frame {
        visibility: hidden;
        width: 1px;
        height: 1px;
        border: 0;
    }

    &--error {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10000;

        .cart-messages__container {
            position: relative;
            background-color: $white;
        }

        .cart-messages__heading {
            margin-bottom: inherit;
        }

        .cart-messages__sub-heading {
            text-align: center;
            margin-bottom: $spacer--extra-large;
        }

        .cart-messages__button-close {
            position: absolute;
            top: 0;
            right: 0;
            background: none;
        }

        .cart-messages__button-confirm {
            width: 200px;
            .button__text {
                margin-right: 0;
            }
        }
    }
}
