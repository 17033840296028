.loyalty-program-tooltip-content {
    &__list {
        list-style: none;
        text-transform: uppercase;
        margin: 0 0 $spacer--medium 0;
        padding: 0;
    }
    &__list-item {
        padding: 0 0 $spacer 0;
    }
    &__link {
        text-transform: uppercase;
    }
}
