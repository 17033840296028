.dashboard {
    &__dropdown {
        margin-bottom: $spacer--large;

        .dashboard-stats {
            &:first-child {
                padding-top: $spacer--medium;
            }
            padding-bottom: $spacer--medium;
        }

        .dropdown-list__label {
            font-size: $font-size-extra-large;
            font-weight: $font-weight-bold;
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                width: 50px;
                height: 100%;
                background-color: $color-primary;
                transition: $transition-base;
            }
            &:hover,
            &:focus {
                .dropdown-list__icon {
                    fill: $blue;
                }
                &:after {
                    background: $gray-lightest;
                }
            }
        }

        .dropdown-list__icon {
            fill: $white;
            z-index: 1;
            right: 16px;
        }

        .dropdown-list__item--open {
            .dropdown-list__content {
                height: auto !important; // sass-lint:disable-line no-important
            }
            .dropdown-list__label {
                &:hover,
                &:focus {
                    & .dropdown-list__icon {
                        fill: $blue;
                    }
                }

                & .dropdown-list__icon {
                    fill: $white;
                }
            }
        }
    }
}
