$cart-badge__width           : 22px;
$cart-badge__height          : 22px;
$cart-badge__border-radius   : 50%;
$cart-badge__font-size       : 11px;
$cart-badge__font-color      : $white;
$cart-badge__background-color: $color-secondary;

.cart-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $cart-badge__width;
    height: $cart-badge__height;
    border-radius: $cart-badge__border-radius;
    font-size: $cart-badge__font-size;
    color: $cart-badge__font-color;
    background-color: $cart-badge__background-color;
    &--large {
        width: 26px;
        height: 25px;
    }
}
