.product-view-stock {
    display: inline-flex;
    align-items: center;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    color: $green;
    &--unavailable {
        color: $red;
        .stock__icon {
            fill: $red;
        }
    }
    &__icon {
        width: 14px;
        fill: $green;
        margin: 0 0 0 6px;
    }
}
