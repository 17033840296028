.home-navigation-container {
    display: flex;
    flex-direction: column;
    max-width: $max-content-width;
    margin: 0;

    @include mq($screen-m) {
        margin: 0 $spacer--large;
    }

    @include mq($screen-xl) {
        flex-direction: row;
        gap: 32px; //sass-lint:disable-line no-misspelled-properties
    }

    @include mq($max-content-width + 2 * $spacer--large) {
        margin: 0 auto;
    }

    > div {
        min-width: 0;
    }

    .filter-menu {
        display: none;
        align-self: flex-start;
        min-width: 300px;
        min-height: 490px;
        max-height: 640px;
        overflow: auto;

        @include mq($screen-xl) {
            display: block;
        }
    }
}
