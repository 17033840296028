$cta-banner-margin                             : 0;
$cta-banner__heading-font-size                 : $custom-cms-font-size-extra-large;
$cta-banner__heading-margin                    : $custom-cms-spacer--large 0;
$cta-banner__heading--secondary-margin\@medium : 0 45px $custom-cms-spacer--large;
$cta-banner__image-margin-bottom               : 50px;
$cta-banner__image-margin-bottom\@medium       : 72px;
$cta-banner__paragraph-margin                  : 0 0 40px;
$cta-banner__actions-gap                       : $custom-cms-spacer--large;
$cta-banner__actions-padding-bottom            : 42px;
$cta-banner__actions-padding-bottom\@medium    : 64px;
$cta-banner__actions-padding-bottom\@xl        : $custom-cms-spacer--medium;
$cta-banner__content-margin                    : 0 $custom-cms-spacer--medium;
$cta-banner__content-margin\@medium            : 0 45px;
$cta-banner__content-margin\@xl                : 0 0 0 115px;
$cta-banner__content-max-width                 : 435px;
$cta-banner__button-width\@large               : 180px;


.cta-banner {
    margin: $cta-banner-margin;

    &__heading {
        margin: $cta-banner__heading-margin;
        font-size: $cta-banner__heading-font-size;
    }

    &__container {
        background-color: $gray-lightest;

        @include mq($screen-xl) {
            display: flex;
        }
    }

    &__content {
        margin: $cta-banner__content-margin;

        @include mq($screen-m) {
            margin: $cta-banner__content-margin\@medium;
        }

        @include mq($screen-xl) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: $cta-banner__content-max-width;
            margin: $cta-banner__content-margin\@xl;
        }
    }

    &__image {
        margin-bottom: $cta-banner__image-margin-bottom;

        @include mq($screen-m) {
            margin-bottom: $cta-banner__image-margin-bottom\@medium;
        }

        @include mq($screen-xl) {
            height: 100%;
            margin: 0;
        }
    }

    &__paragraph {
        margin: $cta-banner__paragraph-margin;
        color: $gray-dark;
    }

    &__actions {
        display: flex;
        flex-direction: column;
        gap: $cta-banner__actions-gap; //sass-lint:disable-line no-misspelled-properties
        padding-bottom: $cta-banner__actions-padding-bottom;

        @include mq($screen-m) {
            flex-direction: row;
            padding-bottom: $cta-banner__actions-padding-bottom\@medium;
        }

        @include mq($screen-l) {
            padding-bottom: 0;
        }

        @include mq($screen-xl) {
            padding-bottom: $cta-banner__actions-padding-bottom\@xl;
        }
    }

    &__button {
        &:hover,
        &:focus {
            background-color: $gray-lighter;
        }

        @include mq($screen-m) {
            width: $cta-banner__button-width\@large;
        }

        &--white {
            background-color: white;
        }
    }

    .pagebuilder-column {
        padding: 0;
    }
}
