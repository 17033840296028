.mega-menu {
    height: 100%;

    &__wrapper {
        display: flex;
        height: 100%;
    }

    &__icon {
        transition: $transition-base;
    }

    &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        min-width: 50px;
        padding: 0 $spacer--large;
        font-size: $font-size-small;
        font-weight: $font-weight-bold;
        color: $font-color-base;
        text-decoration: none;
    }

    &__item {
        &--icon-only {
            .mega-menu__icon {
                width: 18px;
                height: 18px;
            }
        }

        &--icon-text {
            .mega-menu__icon {
                width: 10px;
                height: 10px;
                margin: 0 0 0 6px;
            }
        }
    }

    &__inner-heading {
        display: block;
        width: 100%;
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
        padding: $spacer--extra-large;
    }

    &__inner-item {
        &--level1 {
            & > .mega-menu__inner-label {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 50px;
                padding: 0 25px;
                font-weight: $font-weight-thin;
                color: $gray-darker;
                border-bottom: solid 1px $gray-lightest;
                transition: none;

                &:hover,
                &:focus {
                    color: $gray-darker;
                    background: $gray-lightest;
                }
            }
        }

        &--level2 {
            width: 250px;
            writing-mode: horizontal-tb;

            & > .mega-menu__inner-label {
                display: block;
                padding: $spacer $spacer--extra-large;
                font-size: $font-size-small;
                font-weight: $font-weight-normal;
                line-height: 2;
                background-color: white;
                color: $gray-darker;

                &:hover,
                &:focus {
                    color: $gray-darker;
                    background-color: $gray-lightest;
                }
                &.current {
                    font-weight: $font-weight-bold;
                    text-decoration: underline;
                }
            }
        }

        &--level3 {
            & > .mega-menu__inner-label {
                display: block;
                padding-bottom: 10px;
                color: $gray;
                font-weight: $font-weight-base;
            }
        }

        &:last-child {
            & > .mega-menu__inner-label {
                border-bottom: none;
            }
        }

        &--bold {
            .mega-menu__inner-label {
                font-weight: $font-weight-bold;
            }
        }
    }

    &__inner-list {
        &--level1 {
            flex-direction: column;
            width: 250px;
            border-right: solid 1px $gray-lightest;
        }

        &--level2 {
            display: none;
            position: absolute;
            top: 0;
            left: 250px;
            height: 100%;
            flex-direction: column;
            align-content: flex-start;
            background: $white;
            border-right: solid 1px $gray-lightest;
            z-index: 10;
            &-columns {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                max-height: 100%;
                writing-mode: vertical-lr;
                height: 100%;
                width: min-content;
            }
        }

        &--level3 {
            padding-bottom: 25px !important; // sass-lint:disable-line no-important
        }
    }

    &__container {
        display: none;
        position: absolute;
        opacity: 0;
        z-index: 100;
        width: auto;
        max-width: calc(100% - 100px);
        min-height: 450px;
        left: 50px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
        background: $white;
    }

    &__button {
        &:hover,
        &:focus {
            color: $white;
            background-color: $color-primary--active;
        }
    }

    &--parent {
        &:hover,
        &:focus {
            & > .mega-menu__link {
                background: $blue;
                color: $white;

                .mega-menu__icon {
                    fill: $white;
                }
            }
        }
    }

    &--is-open {
        & > .mega-menu__container {
            opacity: 1;
            display: flex;
        }
        & > .mega-menu__link {
            background: $blue;
            color: $white;

            .mega-menu__icon {
                fill: $white;
                transform: rotate(-180deg);
            }
        }
    }

    &--opened {
        .mega-menu__inner-list--level2 {
            z-index: 5;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        margin: 0;
    }
}
