.dashboard-table {
    thead {
        th {
            &.dashboard-table__button {
                text-align: center;
            }
        }
    }

    tbody {
        td {
            &.dashboard-table__cell--remove,
            &.dashboard-table__cell--order {
                display: none;
            }
        }
    }
}
