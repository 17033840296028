@font-face {
    font-family: 'Roboto';
    src:
    url('../fonts/roboto-regular.woff2') format('woff2'),
    url('../fonts/roboto-regular.woff') format('woff'),
    url('../fonts/roboto-regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src:
    url('../fonts/roboto-light.woff2') format('woff2'),
    url('../fonts/roboto-light.woff') format('woff'),
    url('../fonts/roboto-light.svg') format('svg');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src:
    url('../fonts/roboto-medium.woff2') format('woff2'),
    url('../fonts/roboto-medium.woff') format('woff'),
    url('../fonts/roboto-medium.svg') format('svg');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src:
    url('../fonts/roboto-bold.woff2') format('woff2'),
    url('../fonts/roboto-bold.woff') format('woff'),
    url('../fonts/roboto-bold.svg') format('svg');
    font-weight: 700;
    font-style: normal;
}
