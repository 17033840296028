.articles {
    padding: 0;
    list-style: none;

    .pagebuilder-column-group {
        flex-wrap: wrap;
    }

    @include mq($screen-xl) {
        .pagebuilder-column-group {
            display: grid !important; //sass-lint:disable-line no-important
            grid-template-columns: repeat(3, 1fr);
            gap: 40px; //sass-lint:disable-line  no-misspelled-properties
        }
    }

    &__bottom-button {
        display: inline-flex !important; //sass-lint:disable-line no-important

        a {
            color: $white;
        }
    }

    &__heading {
        font-size: 26px;
        margin-bottom: 32px;
    }

    &__header {
        max-width: $max-content-width;
        margin: 0 $spacer--large;

        @include mq($max-content-width + 2 * $spacer--large) {
            margin: 0 auto;
        }
    }

    &--home {
        @include home-container();

        .articles {
            &__bottom-button {
                width: 100%;

                @include mq($screen-m) {
                    width: auto;
                    min-width: 250px;
                }
            }
        }
    }

    &--pdp {
        @include mq($screen-xl) {
            display: grid !important; //sass-lint:disable-line no-important
            grid-template-columns: repeat(3, 1fr);
            gap: 40px; //sass-lint:disable-line  no-misspelled-properties
        }
    }
}
