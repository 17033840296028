.loyalty-my-data {
    margin-top: $spacer--extra-large;
    &__info {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        @include mq($screen-m) {
            flex-flow: row nowrap;
        }
    }
    &__info-col1 {
        flex: 1 1 100%;
        @include mq($screen-m) {
            flex: 0 1 50%;
            padding-right: $spacer--extra-large;
            border-right: $border-base;
        }
    }
    &__info-col2 {
        flex: 1 1 100%;
        @include mq($screen-m) {
            flex: 0 1 50%;
            padding-left: $spacer--extra-large;
        }
    }
    &__info-list-top {
        padding-bottom: $spacer--medium;
    }
    &__info-list-bottom {
        padding: $spacer--medium 0;
    }

    &__filters {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        margin: $spacer--medium 0;
        padding: $spacer--medium 0;

        @include mq($screen-m) {
            flex-flow: row nowrap;
        }
        .document-filter__label {
            margin-right: $spacer--medium;
        }

        .document-filters {
            &__filter,
            &__submit {
                @include mq($screen-l) {
                    flex-basis: 33%;
                    max-width: 33%;
                }
            }

            &__submit-button {
                background-color: $loyalty-orange;

                &:hover,
                &:focus {
                    color: $white;
                }
            }

            &__toggle-button {
                padding: 0;
                font-family: $font-family-sans-serif;
                font-size: 18px;
                font-weight: $font-weight-medium;
                color: $loyalty-orange;
                text-transform: none;
                letter-spacing: -0.1px;

                @include mq($screen-m) {
                    padding: 0 $spacer--extra-large;
                }

                &:hover,
                &:focus {
                    background-color: transparent;
                }

                .icon {
                    fill: $loyalty-orange !important; // sass-lint:disable-line no-important
                }
            }
        }
    }

    &__filters-col1 {
        flex: 1 1 100%;
        @include mq($screen-m) {
            flex: 0 1 30%;
        }
    }
    &__filters-col2 {
        flex: 1 1 100%;
        @include mq($screen-m) {
            display: flex;
            flex: 0 1 70%;
            justify-content: flex-end;
            align-items: center;
        }
    }
    &__filters-heading {
        padding: 0;
        margin: 0;
        font-size: 18px;
        color: $loyalty-gray-dark;
    }

    &__table {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        margin: $spacer--medium 0 $spacer--extra-large;

        thead {
            border-bottom: 1px solid #ededed;

            th {
                font-size: $font-size-medium;
                font-weight: $font-weight-medium;
                color: $loyalty-gray-dark;
            }
        }


        tbody {
            td {
                font-weight: $font-weight-medium;
                color: $loyalty-gray-dark;

                &.dashboard-table {
                    &__button-expand {
                        padding: 0 $spacer--medium 0 0;

                        @include mq($screen-m) {
                            padding: 0;
                        }

                        .button {
                            margin-left: auto;
                            width: 62px;
                        }
                    }
                }

                &:before {
                    font-weight: $font-weight-medium;
                    color: $loyalty-gray-dark;
                }

                &.table__cell--child-content {
                    padding: 0;

                    @include mq($screen-m) {
                        padding: $spacer--large 0;
                    }
                }

                .sanpol-table-item-image {
                    display: none;

                    @include mq($screen-m) {
                        display: flex;
                    }
                }
            }

            tr {
                padding: $spacer--medium;
                background-color: transparent;
                border-bottom: 1px solid #ededed;
            }
        }
    }

    &__filters-heading-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    &__filters-description {
        color: $loyalty-gray-dark;
    }
}
