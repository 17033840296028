.loyalty-awards {
    width: 100%;
    &__info {
        display: flex;
        flex-flow: row wrap;
        margin-bottom: $spacer--large;
        @include mq($screen-m) {
            flex-flow: row nowrap;
        }
    }
    &__info-col1 {
        flex: 0 0 100%;
        border-bottom: $border-base;
        @include mq($screen-m) {
            flex: 0 0 50%;
            padding-right: $spacer--extra-large;
            border-bottom: none;
        }
    }
    &__info-col2 {
        flex: 0 0 100%;
        @include mq($screen-m) {
            flex: 0 0 50%;
            padding-left: $spacer--extra-large;
        }
    }

    &__filters {
        display: flex;
        flex-flow: row wrap;
        padding: $spacer--medium 0;
        @include mq($screen-m) {
            flex-flow: row nowrap;
        }
    }
    &__filters-col1 {
        flex: 0 0 100%;
        @include mq($screen-m) {
            flex: 0 0 50%;
        }
    }
    &__filters-col2 {
        flex: 0 0 100%;
        padding: $spacer--extra-large $spacer--large $spacer--large;
        @include mq($screen-m) {
            flex: 0 0 50%;
        }
    }
    &__filters-range {
        padding: 80px 80px 20px 0;
        border: none;
        .noUi-tooltip {
            padding: $spacer--medium $spacer--medium;
        }

        .noUi-handle,
        .noUi-connect {
            background: $loyalty-orange;
        }
    }
    &__filters-checkboxes {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-start;
        @include mq($screen-m) {
            margin: 50px 0 0;
        }
        &[expand="true"] {
            .loyalty-awards__filters-checkbox:nth-child(n+7) {
                display: flex;
            }
        }
    }
    &__filters-checkbox {
        flex: 1 1 100%;
        margin-bottom: $spacer--extra-large;
        @include mq($screen-s) {
            flex: 1 1 50%;
        }
        @include mq($screen-m) {
            flex: 0 1 33.333%;
        }
        &:nth-child(n+7) {
            display: none;
        }
    }
    &__filters-buttons {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
    }
    &__filters-button {
        flex: 1 1 100%;
        margin: 0 0 $spacer--medium;
        border-radius: $spacer;

        @include mq($screen-m) {
            flex: 0 1 calc(50% - 10px);
        }
        &--more {
            justify-content: flex-end;
            color: $loyalty-orange;

            @include mq($screen-m) {
                flex: 0 1 auto;
                justify-content: center;
                margin-left: 50%;
            }

            &:hover,
            &:focus {
                color: $loyalty-orange;
                background-color: $white;
            }

            &.button.button--link.button--icon .icon {
                fill: $loyalty-orange;
            }
        }
        &--clear {
            color: $loyalty-gray-dark;
            background-color: #ededed;

            @include mq($screen-m) {
                margin-left: 0;
                margin-right: $spacer--medium;
            }
        }
        &--search {
            background-color: $loyalty-orange;

            @include mq($screen-m) {
                margin-left: $spacer--medium;
                margin-right: 0;
            }

            &:hover,
            &:focus {
                color: $white;
                background-color: $loyalty-orange;
            }
        }
    }

    &__products {
        display: grid;
        grid-template-columns: 1fr;
    }
    &__products-col1 {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;

        @include mq($screen-xl) {
            padding: 0 80px;
        }
    }
    &__products-col2 {
        min-width: 240px;
        padding-top: 30px;
    }
    &__products-heading {
        flex: 1 1 100%;
        margin-bottom: $spacer--large;
    }
    &__products-grid {
        margin: 0;
    }
    &__products-grid-item {
        max-width: unset;
        min-width: 188px !important; // sass-lint:disable-line no-important
    }
    &__products-message-heading {
        text-align: center;
        @include mq($screen-l) {
            text-align: left;
        }
    }
    &__products-message-label {
        display: block;
        text-align: center;
        line-height: 1.4;
        @include mq($screen-l) {
            text-align: left;
        }
    }
    &__products-message-input {
        margin: $spacer--medium 0 $spacer--extra-large;
        textarea {
            @include mq($screen-l) {
                height: 324px;
            }
        }
    }
    &__products-message-submit {
        width: 100%;
        margin: 0 auto $spacer--medium;
    }
    &__download {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;

        @include mq($screen-l) {
            flex-direction: row;
        }

        .button {
            background-color: $loyalty-orange;
            color: $white;
            border-radius: $spacer;
            min-width: 260px;

            @include mq($screen-l) {
                min-width: 300px;
            }
        }
    }

    &__download-icon {
        display: none;
        width: 91px;
        height: 91px;
        margin: 0 24px 0 0;

        @include mq($screen-m) {
            display: flex;
        }
    }

    &__download-text {
        flex: 1;
        max-width: 800px;

        @include mq($screen-m) {
            padding: 0 $spacer--extra-large 0 0;
        }

        p {
            padding: 0 0 $spacer--extra-large;
        }
    }

    &__download-heading {
        margin: 0 0 24px;
        font-size: 32px;
        color: $black;
        line-height: normal;

        @include mq($screen-m) {
            margin: 0 0 $spacer--medium;
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
    }
}
