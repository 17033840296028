.registration-success {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1001;

    &--success {
        display: block;
        .registration-success__title-error-msg {
            display: none;
        }
    }

    &--error {
        display: block;
        .registration-success__main-icon {
            display: none;
        }
        .registration-success__title-success-msg {
            display: none;
        }
        .registration-success__title-error-msg {
            display: block;
        }
    }

    &__dialog {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 290px;
        margin: auto;
        background: white;
        text-align: center;
        transform: translate(-50%, -50%);
        @include mq($screen-l) {
            width: 400px;
        }
    }

    &__content {
        position: relative;
        margin: auto;
        padding: 20px;
        @include mq($screen-l) {
            padding: 30px;
        }
    }

    &__button-close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px;

        .icon {
            width: 15px;
            height: 15px;
        }
    }

    &__main-icon {
        width: 70px;
        height: 70px;
        margin: 0 auto;
    }

    &__title {
        display: block;
        margin: 20px 0;
        font-size: 17px;
        @include mq($screen-l) {
            font-size: 18px;
        }
    }

    &__button-back {
        .icon {
            width: 12px;
            height: 12px;
            fill: $white;
        }
    }

    &__button-print {
        margin-top: 10px;
        padding: 0 $spacer--medium;
    }
}
