.loyalty-links {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    margin: 0 0 32px;
    border-bottom: 1px solid $loyalty-orange;

    &__link {
        flex: 1 0 50%;
        max-width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: $spacer--large $spacer;
        text-transform: uppercase;
        text-align: center;
        font-weight: 700;
        font-size: $font-size-extra-small;
        color: $black;
        border-radius: 5px 5px 0 0;
        border: 1px solid #ededed;
        border-width: 1px 1px 0 1px;

        @include mq($screen-m) {
            flex-basis: 20%;
            max-width: 20%;
        }

        @include mq($screen-l) {
            flex-basis: 16.66%;
            max-width: 16.66%;
        }

        &--active,
        &:hover,
        &:focus {
            color: $loyalty-orange;
            border-color: $loyalty-orange;

            .loyalty-links__link-icon {
                fill: $white;
            }
        }

        &--active {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                bottom: -1px;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: $white;
            }
        }

        &--mobile-hidden {
            display: none;

            @include mq($screen-m) {
                display: flex;
            }
        }
    }

    &__link-icon {
        width: 26px;
        height: 26px;
        margin-right: $spacer;
        fill: $blue;
        transition: $transition-base;
    }

    &__show-more {
        @include mq($screen-m) {
            display: none;
        }

        .loyalty-links__link {
            border: none;
            justify-content: flex-start;
            padding: 0 0 24px;
        }
    }

    &__show-more-button {
        background: none;

        @include mq($screen-m) {
            display: none;
        }

        &--active {
            color: $loyalty-orange;
        }

        &:not(&--active) {
            color: $loyalty-gray-dark;
            border-color: #ededed;
        }
    }
}
