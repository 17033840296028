.returns-product-picture {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: $gray-lighter;
    margin: $spacer 0;
    height: 50px;
    &__picture {
        max-width: 50px;
        max-height: 50px;
    }
    &__label {
        margin-left: $spacer--medium;
        color: $gray;
        font-size: $font-size-base;
        text-overflow: ellipsis;
        overflow: hidden;
        @include mq($screen-m) {
            font-size: 12px;
        }
        @include mq($screen-l) {
            font-size: $font-size-base;
        }
    }
}
