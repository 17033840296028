$products-map-item__border: 1px solid $gray-lightest;

.products-map-item {
    display: none;
    width: 100%;
    padding: $spacer--extra-large 0 0;
    background-color: $white;
    border-bottom: $products-map-item__border;

    @include mq($screen-m) {
        width: 208px;
        padding: $spacer--medium 0;
        position: absolute;
        top: 0;
        left: 0;
    }

    &--active {
        display: block;
    }

    &__link {
        display: flex;

        @include mq($screen-m) {
            flex-wrap: wrap;
            margin: 0 $spacer--medium;
        }
    }

    &__image-wrapper {
        flex: 0 0 40%;
        padding: $spacer;
        position: relative;

        @include mq($screen-m) {
            flex-basis: 100%;
            padding: 0 0 $spacer 0;
            border-bottom: $border-secondary;
            max-height: none;
        }
    }

    &__image {
        max-height: 100%;
        height: 100%;
        width: auto;
        margin: 0 auto;

        @include mq($screen-m) {
            max-height: none;
            height: auto;
            width: 100%;
        }
    }

    &__main {
        flex: 0 0 60%;
        padding: $spacer--medium;

        @include mq($screen-m) {
            flex-basis: 100%;
        }
    }

    &__name {
        display: block;
        font-weight: $font-weight-normal;
        font-size: $font-size-medium;
        color: $gray-darker-secondary;
        line-height: 24px;
        overflow: hidden;
        text-transform: none;
        margin-bottom: $spacer--medium;

        @include mq($screen-m) {
            text-align: center;
            font-size: $font-size-base;
        }
    }

    &__price {
        @include mq($screen-m) {
            text-align: center;
        }

        .price-box {
            display: flex;
            justify-content: flex-end;

            @include mq($screen-m) {
                justify-content: center;
            }
        }
    }

    &__manufacturer {
        @include mq($screen-m) {
            display: flex;
            justify-content: center;
        }
    }

    &__manufacturer,
    &__price {
        color: $color-accent;
        font-size: 24px;
    }
}
