.cart-delivery-form {
    &__custom {
        display: flex;
        flex-flow: row wrap;
        min-height: 100px;
    }

    &__input {
        flex: 0 1 100%;
        margin-top: $spacer;

        &--street {
            @include mq($screen-m) {
                flex: 0 1 75%;
                padding-right: $spacer--medium;
            }
        }

        &--number {
            @include mq($screen-m) {
                flex: 0 0 25%;
            }
        }

        &--postcode {
            @include mq($screen-m) {
                flex: 0 0 25%;
                padding-right: $spacer--medium;
            }
        }

        &--city {
            @include mq($screen-m) {
                flex: 0 1 75%;
            }
        }

        &--email {
            @include mq($screen-m) {
                flex: 0 0 50%;
                padding-right: $spacer--medium;
            }
        }

        &--phone {
            @include mq($screen-m) {
                flex: 0 0 50%;
            }
        }
    }
}
