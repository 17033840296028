$home-shop-info__content-gap : 32px;

.home-brief-info {
    @include home-container();

    margin-bottom: 0;

    @include mq($max-content-width + 2 * $spacer--large) {
        margin-bottom: 0;
    }

    &__heading {
        margin: 0;
        font-size: 26px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: $home-shop-info__content-gap; //sass-lint:disable-line no-misspelled-properties
        padding: 32px 0 0 0;

        @include mq($screen-xl) {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    &__paragraph {
        font-size: $font-size-small;
        color: $gray;
        line-height: 26px;

        @include mq($screen-xl) {
            flex-basis: calc(50% - #{$home-shop-info__content-gap} / 2);
        }

        &:first-child {
            @include mq($screen-xl) {
                flex-basis: 100%;
            }
        }
    }
}
