$data-dialog-content__container-min-height : 36px;
$data-dialog-content__container-min-width  : 110px;
$data-dialog-content__container-dot-size   : 14px;

.data-dialog-content {
    &__list {
        text-align: left;
        .list__item {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            padding: 0;
            &:first-child {
                padding-top: $spacer;
            }

            &--warehouse {
                margin-bottom: $spacer--medium;
            }
        }
    }

    &__label {
        font-weight: $font-weight-bold;
        margin-right: $spacer--medium;
    }

    &__value {
        color: $gray;
        font-weight: $font-weight-thin;

        &--warehouse {
            display: flex;
            gap: $spacer; //sass-lint:disable-line no-misspelled-properties
            align-items: center;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: $spacer; //sass-lint:disable-line no-misspelled-properties
        align-items: flex-end;
        min-height: $data-dialog-content__container-min-height;
        min-width: $data-dialog-content__container-min-width;
        border-bottom: 1px solid $gray-lighter;
    }

    &__dot {
        display: inline-block;
        height: $data-dialog-content__container-dot-size;
        width: $data-dialog-content__container-dot-size;
        border-radius: 50%;
        background-color: $gray;

        &--red {
            background-color: $red;
        }

        &--green {
            background-color: $green;
        }
    }

    &__status {
        display: flex;
        align-items: center;
        gap: $spacer; //sass-lint:disable-line no-misspelled-properties
        justify-content: flex-end;
    }
}
