$catalog-grid-item__base-spacing            : $spacer;
$catalog-grid-item__padding                 : 0;

$catalog-grid-item__font-size               : $font-size-small;
$catalog-grid-item__font-size\@large        : $font-size-base;
$catalog-grid-item__line-height             : 16px;

$catalog-grid-item__content-padding         : $spacer;

$catalog-grid-item__name-color              : $font-color-base;
$catalog-grid-item__name-font-weight        : $font-weight-normal;

$catalog-grid-item__action-size             : 40px;
$catalog-grid-item__action-icon-size        : 20px;

.catalog-grid-item {
    position: relative;
    display: flex;
    padding: $catalog-grid-item__padding;
    border-right : 1px solid $gray-lighter;
    border-bottom: 1px solid $gray-lighter;

    &:hover,
    &:active {
        &:before {
            position: absolute;
            display: block;
            border: 8px solid $gray-lighter;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            content: '';
            z-index: 1;
        }
    }

    &__badge-wrapper {
        position: absolute;
        top: 13px;
        left: $spacer;
        z-index: 5;
    }

    &__badge {
        display: block;
        margin-bottom: $spacer;

        @include mq($screen-s) {
            display: inline-block;
            margin-right: $spacer;
        }

        @include mq($screen-m) {
            display: block;
        }

        @include mq($screen-l) {
            display: inline-block;
        }
    }

    &__link {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: calc(100% - 130px);
        padding: 0 $spacer;
        z-index: 2;

        &:hover,
        &:active {
            text-decoration: none;

            .catalog-grid-item__name {
                text-decoration: none;
            }
        }
    }

    &__image-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        padding: $spacer--medium 0 0 0;
    }

    &__image {
        width: auto;
        max-height: 130px;

        @include mq($screen-m) {
            max-height: 180px;
        }

        @include mq($screen-xl) {
            max-height: 240px;
        }
    }

    &__image-badge {
        position: absolute;
        bottom: $spacer;
    }

    &__flex-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    &__main {
        padding: $catalog-grid-item__content-padding 0;
        flex-flow: column nowrap;
        flex-grow: 1;

        @include mq($screen-m) {
            padding: $catalog-grid-item__content-padding;
        }

        @include mq($screen-xl) {
            padding-bottom: $spacer--medium;
        }
    }

    &__name {
        font-weight: $catalog-grid-item__name-font-weight;
        font-size: $catalog-grid-item__font-size;
        line-height: $catalog-grid-item__line-height;
        margin-bottom: $spacer;
        padding-top: $spacer;
        border-top: $border-secondary;
        color: $gray-darkest;
        text-align: left;
        text-transform: none;
        max-height: 2 * $catalog-grid-item__line-height + $spacer;
        min-height: 2 * $catalog-grid-item__line-height + $spacer;
        overflow: hidden;
        flex: 0 0 100%;

        @include mq($screen-xl) {
            font-size: $catalog-grid-item__font-size\@large;
            line-height: 15px;
        }

        &--one-line {
            overflow: hidden;
            .catalog-grid-item__name-text {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .link {
            color: $catalog-grid-item__name-color;
            text-decoration: none;
        }
    }

    &__price {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: flex-end;
        font-size: $catalog-grid-item__font-size;
        line-height: $catalog-grid-item__line-height;
        font-weight: $font-weight-bold;
        text-align: right;
        white-space: nowrap;
        width: auto;
        min-height: 40px;

        .price {
            margin-bottom: $spacer--medium;
        }
    }

    &__old-price {
        @include mq($screen-m) {
            margin-bottom: $spacer;
        }
    }

    &__special-price {
        position: absolute;
        right: 16px;
        top: 20px;
    }

    &__options {
        .swatch {
            margin: $catalog-grid-item__base-spacing 0;
        }
    }

    &__actions {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 5;
    }

    &__secondary-action {
        display: flex;
        align-content: center;
        justify-content: center;
        height: $catalog-grid-item__action-size;
        width: $catalog-grid-item__action-size;
        margin-left: $catalog-grid-item__base-spacing;
        padding: 0;
        background-color: transparent;
        border: none;
        cursor: pointer;

        &:hover,
        &:active {
            .icon {
                fill: $color-primary;
            }
        }

        @include mq($screen-m) {
            width: 48px;
            height: 48px;
        }

        .icon {
            fill: $gray-light;
            width: $catalog-grid-item__action-icon-size;
            height: $catalog-grid-item__action-icon-size;
        }
    }

    &__marketing-info {
        display: block;
        font-size: $font-size-small;
        line-height: $catalog-grid-item__line-height;
        font-weight: $font-weight-normal;
        color: $color-primary;
        white-space: normal;
        text-align: left;
        min-height: fit-content;
        padding-bottom: $spacer;

        @include mq($screen-m) {
            padding-bottom: 0;
        }
    }

    &__manufacturer {
        font-weight: $font-weight-bold;
        font-size: $catalog-grid-item__font-size;
        line-height: $catalog-grid-item__line-height;
        margin-bottom: $spacer;
        color: $color-primary;

        @include mq($screen-l) {
            font-size: $font-size-base;
        }
    }

    &__link-action-parent {
        width: 100%;
    }

    &__add-to-cart-action {
        padding: $spacer--medium;
        padding-top: 0;
        position: relative;
        text-align: center;
        z-index: 2;

        button {
            height: 40px;
            @include mq($screen-m) {
                height: 48px;
            }
        }

        &:hover,
        &:active {
            text-decoration: none;

            .catalog-grid-item__name {
                text-decoration: none;
            }
        }
    }

    &__availability-info {
        display: block;
        font-size: $font-size-small;
        font-weight: $font-weight-normal;
        line-height: $catalog-grid-item__line-height;
        padding-bottom: $spacer;
        padding-top: 0;
        text-align: left;
        white-space: normal;

        @include mq($screen-m) {
            float: left;
            padding-top: 0;
        }

        .availability-label {
            color: $font-color-base;

            &--blue {
                color: $blue;
            }

            &--green {
                color: $green;
            }
        }
    }

    &__price-wrapper {
        display: flex;
        flex-flow: wrap-reverse;
        margin-right: $spacer;
        padding-top: $spacer;
        width: 100%;

        @include mq($screen-m) {
            min-height: 43px;
        }

        div.price {
            margin-bottom: 0;
        }
    }

    &__flex-wrapper-inner-center {
        min-height: 16px;
    }

    &__flex-wrapper-inner-start {
        flex-grow: 1;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    &__flex-wrapper-inner-end {
        display: flex;
        justify-content: flex-end;
        margin: 0;
        margin-top: auto;
        padding: 0;
    }
}
