$pager__list-padding                  : $spacer;
$pager__item-margin                   : 0;
$pager__item-size                     : 48px;
$pager__item-color--current           : $white;
$pager__item-background-color--current: $color-primary;
$pager__item-hover                    : $blue;
$pager__icon-width                    : $spacer--large;
$pager__icon-height                   : $spacer--large;
$pager__icon-fill                     : $gray-darker;

.pager {
    width: 100%;
    text-align: center;

    &__list {
        display: flex;
        align-items: center;
        justify-content: center;
        list-style-type: none;
        padding: $pager__list-padding;
        margin: 0;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: $pager__item-size;
        height: $pager__item-size;
        margin: $pager__item-margin;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &--current {
            position: relative;
            background-color: $pager__item-background-color--current;
            color: $pager__item-color--current;

            .button.button--icon {
                background: $blue;
                color: $white;

                &:hover,
                &:focus {
                    color: $white;
                    .button__text {
                        color: $white;
                    }
                }
            }
        }

        .button--icon {
            background: $white;
            color: $gray-darker;

            &:hover,
            &:focus {
                color: $pager__item-hover;
                .icon {
                    fill: $pager__item-hover;
                }
            }

            .icon {
                fill: $gray-darker;
            }

            .button__text {
                margin-right: 0;
            }
        }
    }

    &__icon {
        width: $pager__icon-width;
        height: $pager__icon-height;
        fill: $pager__icon-fill;
        transition: $transition-base;
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: $pager__item-size;
        text-decoration: none;
        font-weight: $font-weight-normal;

        &[disabled] {
            display: none;
        }

        &:focus,
        &:hover {
            .pager__icon {
                fill: $pager__item-hover;
            }
        }
    }

    &__link-nav {
        @extend .button;
        @extend .button--icon;
        @extend .button--link;

        margin: 0 auto;
        text-transform: none;
        color: $font-color-base;
        font-weight: $font-weight-normal;
        border: 1px solid $border-color-base;
        border-radius: $border-radius-secondary;
        text-transform: uppercase;
        font-weight: $font-weight-bold;

        &:focus,
        &:hover {
            color: $pager__item-hover;
            background-color: transparent;
            border-color: $pager__item-hover;

            .icon {
                fill: $pager__item-hover;
            }
        }

        &-text {
            @extend .button__text;

            @include mq($screen-s) {
                // sass-lint:disable no-important
                margin: 0 $spacer--extra-large !important;
            }
        }

        .icon {
            fill: $font-color-base;
            width: 13px;
            height: 13px;
        }
    }

    &__nav-container {
        display: flex;
        flex-direction: row;
        width: max-content;
        align-items: center;
        margin: auto;
        column-gap: 2px;
        margin-bottom: $spacer;

        @include mq($screen-s) {
            column-gap: $spacer--large;
        }
    }
}
