.loyalty-intro {
    padding: 32px 0 40px;

    &__heading {
        h1,
        p {
            margin: 0;
        }

        p {
            color: $gray;
        }
    }

    &__tabs {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 0 40px;

        @include mq($screen-m) {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    &__tabs-btn {
        display: grid;
        grid-template-columns: minmax(50px, 1fr) 1fr;
        grid-column-gap: $spacer;
        height: initial;
        padding: $spacer--extra-large;
        margin: $spacer;
        border: $spacer--medium solid $gray-lightest;
        background: $white;
        color: $gray-darker;
        text-transform: none;
        font-size: 24px;
        font-weight: $font-weight-normal;

        @include mq($screen-m) {
            flex-basis: calc(25% - 2 * #{$spacer});
            font-size: $font-size-large;
        }
        @include mq($screen-l) {
            font-size: $font-size-extra-large;
        }
        @include mq($screen-xl) {
            min-height: 200px;
            font-size: 24px;
        }

        .icon {
            fill: $blue;
            height: 72px;
            width: 65px;
            justify-self: center;
            @include mq($screen-m) {
                width: 45px;
            }
            @include mq($screen-l) {
                width: 65px;
            }
        }

        .button__text {
            max-width: 100px;
            text-align: left;
            line-height: 1.25;
            margin-left: 0 !important;
        }

        &--active,
        &:hover,
        &:focus {
            background: $loyalty-orange;
            color: $white;

            .icon {
                fill: $white;
            }
        }
    }

    &__content {
        max-width: 900px;
        margin: 0 auto 40px;
    }
}
