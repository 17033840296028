.child-table {
    @extend .table;
    font-size: $font-size-base;

    @include mq($screen-m) {
        font-size: $font-size-small;
    }

    thead {
        tr {
            background: $white;
        }
        th {
            color: $gray-dark;

            @include mq($screen-m) {
                height: 50px;
            }
        }
    }
    tbody {
        tr {
            background: $white;
            border-top: 1px solid #dedede;
            border-bottom: none;
        }
        td {
            padding: $spacer 0;
            @include mq($screen-m) {
                height: 50px;
            }
        }
    }
}
