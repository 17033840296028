.returns-add-uploaded-files {
    margin-top: $spacer--large;
    &__file {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: $gray-lighter;
        margin-top: $spacer--medium;
    }
    &__file-image {
        max-width: 50px;
        max-height: 50px;
    }
    &__file-label {
        color: $gray;
        font-size: $font-size-base;
        @include mq($screen-m) {
            font-size: 12px;
        }
        @include mq($screen-l) {
            font-size: $font-size-base;
        }
    }
    &__file-info {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }
}
