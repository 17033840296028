$catalog-grid__gap: 0;

.catalog-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $catalog-grid__gap;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    border-top: 1px solid $gray-lighter;
    border-left: 1px solid $gray-lighter;

    @include mq($screen-m) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    &--4cols {
        @include mq($screen-xl) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
}
