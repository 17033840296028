$tile-max-width      : 320px;
$tile-font-size      : 18px;
$tile-min-height     : 58px;
$tile-border-bottom  : 1px solid $custom-cms-color-gray-lighter;
$tile-before-width   : 37%;
$tile_image-padding  : 20%;

.tile {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: $tile-max-width;
    padding: 0 0 $custom-cms-spacer--medium 0;
    transition: $transition-base;

    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        width: $tile-before-width;
        height: 1px;
        border-bottom: $tile-border-bottom;
        transition: $transition-base;
        transform: translateX(-50%);
    }

    &:hover,
    &:focus {
        background-color: $color-primary;

        .tile__title,
        .tile__description,
        .tile__description p {
            color: white;
        }

        .tile__image {
            filter: brightness(0) invert(1);
        }

        &:before {
            border-bottom: none;
        }
    }

    &__wrapper {
        position: relative;
    }

    &__title {
        display: flex;
        align-items: center;
        margin: 0;
        min-height: $tile-min-height;
        font-size: $tile-font-size;
        color: $gray-darker;
        text-transform: uppercase;
        font-weight: bold;
        transition: $transition-base;
    }

    &__description,
    &__description p {
        margin-bottom: $custom-cms-spacer--large;
        padding: 0 $custom-cms-spacer--medium;
        color: $gray-dark;
        font-size: $font-size-small;
        line-height: 24px;
        transition: $transition-base;
    }

    &__image {
        width: 100%;
        transition: $transition-base;
    }

    &--secondary {
        .tile__image {
            padding: 0;
            transition: $transition-base;
        }

        .tile__image-hover {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            width: 15%;
            object-fit: contain;
            opacity: 0;
            transform: translateY(-50%);
            transition: opacity 0.3s;
        }

        &:hover,
        &:focus {
            .tile__image {
                filter: none;
                opacity: 0;
            }

            .tile__image-hover {
                opacity: 1;
            }
        }
    }
}
