.returns-view {
    .dashboard-tab__icon {
        fill: $white;
    }
    &__item-label {
        padding: $spacer--medium $spacer--large;
        margin: 0;
        @include mq($screen-m) {
            padding: $spacer--medium;
        }
    }
    &__back {
        float: left;
    }
}
