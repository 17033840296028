$dashboard-toolbar-margin: 15px !default;

.dashboard-toolbar {
    &__amount {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: $dashboard-toolbar-margin;

        @include mq($screen-m) {
            justify-content: flex-start;
        }
    }

    &__limiter {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: $dashboard-toolbar-margin;

        @include mq($screen-m) {
            justify-content: flex-end;
        }
    }

    &__pager {
        margin-bottom: 0;
    }

    &__amount-info {
        margin: 0 $dashboard-toolbar-margin;
    }

    &__select {
        width: 100px;
        margin-bottom: 0;

        .label {
            margin-bottom: 0;
        }
    }
}
