.cart {
    padding: 30px 0;

    &__sidebar {
        display: none;
        @include mq($screen-xl) {
            display: block;
        }
    }

    &__form {
        position: relative;
    }

    &__search {
        margin-top: $spacer--extra-large;
    }
}
