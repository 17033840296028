.shopping-lists-view__table {
    thead th,
    tbody td {
        &:nth-child(2) {
            display: none;
        }
    }

    thead th:nth-last-child(-n+2) {
        display: none;
    }

    tbody {
        td.dashboard-table__cell--stock,
        td.dashboard-table__cell--qty-input {
            padding: 0 $spacer--medium;
        }

        td.dashboard-table__cell--details {
            width: auto;
        }
    }

    .cart-table-details {
        &__title {
            text-align: left;
        }

        &__description {
            display: flex;
        }
    }
}
