.beprofi-rules-text {
    h4 {
        text-align: center;
        font-size: 25px;
        margin-bottom: 20px;
    }
    h5 {
        text-align: center;
        font-size: 16px;
    }
    ol {
        color: black;
    }
    ul {
        color: black;
        list-style: none;
    }
    .simplebar-content {
        margin-right: 30px !important; // sass-lint:disable-line no-important
    }
}
