.header-minicart {
    @extend .header-button;

    text-decoration: none;
    text-align: left;
    transition-property: background;

    &:hover {
        text-decoration: none;
    }

    &__text {
        @extend .header-button__text;

        margin-left: $spacer--medium;
        font-size: $font-size-extra-small;
        color: $gray-darker-secondary;
        font-weight: $font-weight-bold;
        line-height: 15px;
        text-transform: none;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: $spacer--medium;
        width: 30px;
        height: 30px;
        background-color: $gray-lightest;
        border-radius: 50%;
        line-height: 15px;
        text-transform: none;
        overflow: hidden;
    }
    &__counter {
        font-size: 12px;
        font-weight: $font-weight-bold;
        color: $gray-darker-secondary;
        white-space: nowrap;
    }
    &__price {
        font-weight: $font-weight-bold;
        font-size: 13px;
        font-weight: $font-weight-bold;
        white-space: nowrap;
    }
}
