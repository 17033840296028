$quicksearch__image-width: 82px;
$quicksearch__image-height: 60px;
$quicksearch__border: 1px solid rgba(0, 0, 0, 0.09);

.quicksearch {
    position: absolute;
    top: 154px;
    left: 0;
    width: 100%;
    z-index: 90;
    @include mq($screen-m) {
        top: 104px;
    }

    @include mq($screen-xl) {
        top: 45px;
    }
    @include mq($screen-xxl) {
        top: 55px;
    }

    &--products {
        top: 95px;
        padding: 0 $spacer--medium;

        @include mq($screen-m) {
            padding: 0 $spacer--extra-large;
        }
        .quicksearch__wrap {
            @include mq($screen-xl) {
                max-width: 100%;
                left: 0;
            }
        }
        .quicksearch__products {
            @include mq($screen-xl) {
                width: 100%;
                margin-right: 0;
                border-right: 0;
            }
        }

        .quicksearch__product-item {
            @include mq($screen-m) {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                max-width: 100%;
            }
        }

        .quicksearch__product-item-link {
            @include mq($screen-m) {
                flex-flow: row nowrap;
            }
        }

        .quicksearch__product-main {
            @include mq($screen-m) {
                width: 40%;
            }
            @include mq($screen-xl) {
                width: 50%;
            }
        }
        .quicksearch__info {
            @include mq($screen-m) {
                width: 40%;
            }
            @include mq($screen-xl) {
                width: 35%;
            }
        }

        .quicksearch__product-price {
            @include mq($screen-m) {
                width: 20%;
            }
        }
    }

    &__wrap {
        position: relative;
        width: 100%;
        background-color: $white;
        max-height: 60vh;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
        max-width: $max-content-width;
        margin: 0 auto;
        @include mq($screen-xl) {
            max-width: calc(100% * 0.8);
            max-height: 80vh;
            left: calc(100% * 0.195);
            margin: 0;
        }
        @include mq($screen-xxl) {
            max-width: calc(#{$screen-xxl} * 0.8);
            left: calc(((100% - #{$screen-xxl}) / 2) + (#{$screen-xxl} * 0.2));
        }
    }

    &__top {
        background-color: $gray-lightest;
        width: 100%;
        min-height: 50px;
        padding: $spacer--medium 50px $spacer--medium $spacer--medium;
        line-height: 1.5;
    }

    &__button-close {
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
        display: block;

        @include mq($screen-m) {
            display: block;
        }

        .icon {
            fill: $gray-light;
        }
    }

    &__scrollbar-wrap {
        max-height: calc(60vh - 50px);
        width: calc(100% + 20px) !important; // sass-lint:disable-line no-important
        overflow-x: hidden;
        box-sizing: border-box !important; // sass-lint:disable-line no-important

        @include mq($screen-xl) {
            max-height: calc(80vh - 50px);
        }

        &::-webkit-scrollbar {// sass-lint:disable-line no-vendor-prefixes
            width: 0;
            background: transparent;
        }
    }

    &__main {
        position: relative;
        min-height: 200px;

        @include mq($screen-xl) {
            display: flex;
            flex-flow: row wrap;
        }
    }

    &__search-item {
        font-weight: $font-weight-bold;
        color: $color-primary;
    }

    &__title {
        padding: $spacer--medium;
        margin-bottom: 0;

        &--main {
            border-bottom: $quicksearch__border;
            @include mq($screen-xl) {
                display: none;
            }
        }
    }

    &__products {
        width: 100%;
        @include mq($screen-xl) {
            width: 70%;
            padding-right: $spacer--large;
            border-right: $quicksearch__border;
        }
    }

    &__product-item {
        width: 100%;
        border-bottom: $quicksearch__border;
        padding-right: $spacer--medium;

        @include mq($screen-m) {
            padding-right: $spacer--extra-large;
        }
    }

    &__product-item-link {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        align-items: center;
        padding: $spacer--medium $spacer--medium 0;
        @include mq($screen-m) {
            flex-flow: row wrap;
            padding: $spacer--medium;
        }
    }

    &__product-main {
        display: flex;
        width: 100%;
        flex-flow: row nowrap;

        @include mq($screen-m) {
            width: 55%;
        }
    }

    &__image-wrapper {
        width: $quicksearch__image-width;
        height: $quicksearch__image-height;
        margin-right: $spacer--medium;
        margin-bottom: $spacer--medium;
        overflow: hidden;
    }

    &__image {
        width: $quicksearch__image-width;
        mix-blend-mode: multiply;
    }

    &__product-name {
        width: calc(100% - 92px);
        padding-right: $spacer--medium;
        line-height: 1.2;
        @include mq($screen-m) {
            margin-bottom: 0;
            padding-top: $spacer;
        }
    }

    &__info {
        width: 100%;
        margin: $spacer--medium 0;
        padding: $spacer 0;
        border: $quicksearch__border;
        border-width: 1px 0;

        @include mq($screen-m) {
            width: 30%;
            padding: 0 $spacer--medium;
            margin: 0;
            border-width: 0 1px;
        }
    }

    &__info-item {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: $spacer 0;
        font-size: 14px;

        .quicksearch__inner-value {
            text-align: right;
        }
    }

    &__inner-label {
        font-weight: $font-weight-thin;
        color: $gray;
        margin-right: $spacer;
    }

    &__inner-value {
        font-weight: $font-weight-bold;
        color: $font-color-base;
    }

    &__product-price {
        width: 100%;
        padding: $spacer 0;
        margin-bottom: $spacer--medium;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        @include mq($screen-m) {
            width: 15%;
            padding: 0 $spacer--medium;
            margin-bottom: 0;
            flex-flow: row wrap;
            align-items: center;

            .quicksearch__inner-label,
            .quicksearch__inner-value {
                width: 100%;
                &:first-of-type {
                    margin-bottom: $spacer;
                }
            }
            .quicksearch__inner-value {
                font-size: 19px;
            }
        }
    }

    &__additional {
        @include mq($screen-xl) {
            width: 30%;
            padding: $spacer--large;
        }
    }

    &__list {
        position: relative;
        padding: $spacer--medium;

        .list__item {
            font-weight: $font-weight-bold;
            line-height: 1.4;
        }
    }

    &__additional-link {
        color: $font-color-base;
        &:focus,
        &:hover {
            color: $color-primary;
        }
    }

    &__category-path-item {
        &:after {
            content: '>';
            display: inline-block;
        }
    }

    &__button {
        font-size: 15px;
    }

    &__actions {
        width: 100%;
        margin-bottom: $spacer--large;
        padding: 0 $spacer--medium;
        @include mq($screen-m) {
            width: 25%;
            margin-bottom: 0;
        }
    }

    &__add-to-btn {
        width: 100%;
        @include mq($screen-m) {
            max-width: 210px;
            margin-left: auto;
        }
    }

    &__empty-response {
        display: block;
        margin: $spacer--medium 0 0 $spacer--medium;
        font-size: $font-size-large;
    }

    // customise scrollbar for quicksearch
    .simplebar-scrollbar,
    .simplebar-track {
        width: 10px;
    }
    .simplebar-scrollbar {
        background-color: rgba($color-primary, 0.7);
        right: 10px;
    }
    .simplebar-track.horizontal {
        display: none;
    }
    .simplebar-content {
        padding-right: 10px;
        @include mq($screen-xl) {
            display: flex;
            flex-flow: row nowrap;
        }
    }

    .loading {
        min-height: 200px;
    }
}
