// custom styles for simplebar lib

.simplebar-track {
    z-index: 4;
    width: 7px;
    background-color: $gray-lightest;

    .simplebar-scrollbar.visible {
        opacity: 1;
    }
}

.simplebar-scrollbar {
    width: 7px;
    right: 0;
    background-color: $color-primary;
    border-radius: 0;
    opacity: 1;
}
