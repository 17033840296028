$password-form__margin: 0 0 30px;
$password-form__legend-color      : $gray;
$password-form__legend-line-height: 1.4;
$password-form__legend-margin     : 0 0 $spacer--large;
$password-form__button-margin-top : $spacer--medium;

.password-form {
    width: 100%;
    margin: $password-form__margin;

    &__legend {
        margin: $password-form__legend-margin;
        color: $password-form__legend-color;
        line-height: 1.4;
    }

    &__input {
        @extend .login-form__input;
    }

    &__hint-icon {
        position: absolute;
        top: $spacer--large;
        right: $spacer--large;

        .icon {
            fill: $gray-light;
            padding: $spacer;
        }

        .hint__content {
            min-width: 180px;
            max-width: 270px;
            white-space: normal;
            line-height: 1.3;
        }

        @include mq($max-screen: $screen-m) {
            &.hint--html:hover.hint--top:hover,
            &.hint--html:focus.hint--top:focus {
                .hint__content {
                    transform: translateX(-90%) translateY(-8px);
                }
            }
        }
    }

    &__button {
        margin-top: $password-form__button-margin-top;
        text-transform: uppercase;
    }

    div[data-role="password-strength-meter"] {
        margin-top: $spacer;
        color: $gray-dark;
    }
}
