.home {
    margin: 20px 0 40px;

    @include mq($max-content-width + 2 * $spacer--large) {
        margin: 32px 0 96px;
    }
}

.homepage {
    .v-messages {
        margin: 0;
    }
}
