.offer-view {
    .dashboard-tab__icon {
        fill: $white;
    }

    .notes-list {
        padding: $spacer--extra-large $spacer--extra-large $spacer--medium;
    }

    &__button-again {
        float: right;
    }

    &__button-back {
        display: inline-flex;
        margin: 0;

        .icon {
            width: 7px;
            height: 12px;
        }
    }

    &__terms {
        padding: $spacer--medium 0 40px;
    }

    &__terms-text {
        margin: 0;
    }

    &__terms-heading {
        margin: $spacer--medium 0 $spacer 0;
    }
}
