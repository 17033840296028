.banner {
    display: block;
    position: relative;
    width: 100%;

    &--with-button {
        .banner__button {
            position: absolute;
            bottom: 25%;
            left: $spacer--medium;
            padding: 0 28px;

            @include mq($screen-s) {
                bottom: 20%;
                left: 80px;
                padding: 0 48px;
            }

            @include mq($screen-m) {
                bottom: 25%;
            }

            @include mq($screen-l) {
                left: 120px;
            }

            @include mq($max-content-width) {
                bottom: 15%;
                left: 21.5%;
            }
        }
    }

    &--100vw {
        position: relative;
        width: 100vw;
        margin-left: 50%;
        left: -50vw;
    }

    &--gradient {
        background: linear-gradient(to right, $color-primary 0%, $color-primary 49%, $color-secondary 50%, $color-secondary 100%);
    }

    &--text-and-border {
        background-color: #008AD0;
        overflow: hidden;
        border-radius: 5px;

        &:before {
            position: absolute;
            z-index: 1;
            content: '';
            display: block;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        .banner__image {
            position: absolute;
            top: 0;
            right: 0;
            width: auto;
            max-width: none;
            height: 100%;
        }

        .banner__img {
            height: 100%;
            width: auto;
            max-width: none;
            height: 300px;

            @include mq($screen-xl) {
                height: 280px;
            }
        }

        .banner__button {
            display: block;
            width: 100%;

            &--link {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .banner__container {
            padding: 40px;
            margin: 0;
            min-height: 384px;

            @include mq($screen-s) {
                min-height: 320px;
            }

            &--single {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                @include mq($screen-m) {
                    align-items: flex-end;
                    flex-direction: row;
                    min-height: 264px;
                    padding: 40px 0;
                    margin: 0 32px;
                }

                @include mq($screen-l) {
                    flex-direction: column;
                    align-items: flex-start;
                    min-height: 350px;
                }

                .banner__title {
                    margin: 0 0 40px;
                    padding: 0;
                    font-size: 32px;
                    color: $white;
                    text-transform: none;
                }

                .banner__descrption {
                    padding: 0;
                    color: $white;
                    font-size: 20px;

                    @include mq($screen-l) {
                        margin: 0 0 32px;
                    }
                }

                .banner__title,
                .banner__descrption {
                    @include mq($screen-m) {
                        max-width: 230px;
                    }
                }

                .banner__button {
                    font-size: $font-size-medium;
                    background-color: $white;
                    color: $color-primary;
                    border-radius: 5px;

                    @include mq($screen-m) {
                        max-width: 330px;
                    }
                }
            }
        }
    }

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &:focus {
        outline: none;
    }

    &__image {
        width: 100%;
    }

    &__container {
        position: relative;
        display: block;
        padding: 90px 0;
        margin: 0 $spacer--large;
        z-index: 2;

        @include mq($screen-s) {
            margin: 0 $spacer--extra-large;
        }
    }

    &__title {
        display: block;
        font-weight: $font-weight-bold;
        font-size: $font-size-large;
        line-height: 1.33;
        text-transform: uppercase;
        color: $gray-darkest;
        margin-bottom: $spacer--medium;
        padding-right: 50%;
    }

    &__descrption {
        display: block;
        font-weight: $font-weight-normal;
        font-size: $font-size-small;
        line-height: 1.33;
        text-transform: none;
        color: $gray;
        padding-right: 50%;
    }
}
