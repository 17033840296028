$table__font-color      : $font-color-base;
$table__background      : $gray-lightest;
$table__font-size       : $font-size-base;

$table__td-color-before : $gray-dark;

$table--child__border   : 1px solid $border-color-base;

$table__button-fill     : $color-primary;

.table {
    width: 100%;
    border: 0;
    color: $table__font-color;
    font-size: $table__font-size;
    border-spacing: 0;
    border-collapse: collapse;
    text-align: left;
    vertical-align: middle;

    th,
    td {
        padding: 0 $spacer;
        &:first-child {
            @include mq($screen-m) {
                padding-left: $spacer--large;
            }
        }

        &:last-child {
            @include mq($screen-m) {
                padding-right: $spacer--large;
            }
        }
    }

    tr {
        display: flex;
        flex-direction: column;
        @include mq($screen-m) {
            display: table-row;
            padding: 0;
        }
    }

    thead {
        display: none;

        @include mq($screen-m) {
            display: table-header-group;
        }

        th {
            height: 50px;
            font-weight: $font-weight-thin;
            color: $gray-dark;
        }
    }

    tbody {
        font-weight: $font-weight-bold;

        tr {
            background-color: $table__background;
            border-bottom: $spacer--medium solid $white;

            &:last-child {
                border-bottom: none;
            }
        }

        td {
            display: flex;
            justify-content: space-between;
            padding: $spacer $spacer--medium;
            text-align: right;
            @include mq($screen-m) {
                display: table-cell;
                height: 80px;
                padding: 0 $spacer;
                text-align: left;
            }

            &:before {
                content: attr(data-th);
                min-width: 50%;
                font-weight: $font-weight-thin;
                color: $gray-dark;
                text-align: left;

                @include mq($screen-m) {
                    content: none;
                }
            }

            &.table__cell {
                &--child-content {
                    background: $white;
                    padding: 15px 0;
                    @include mq($screen-m) {
                        padding: 15px;
                    }
                    &:before {
                        content: none;
                    }
                }
            }

            &.table__empty-heading {
                &:before {
                    content: none !important; // sass-lint:disable-line no-important
                }
                @include mq($screen-m) {
                    padding: 0 $spacer--large;
                }
            }
        }
    }

    tfoot {
        font-weight: $font-weight-bold;

        td {
            display: block;
            padding: $spacer $spacer--medium;
            height: 50px;

            @include mq($screen-m) {
                display: table-cell;
                padding: $spacer--medium;
            }
        }
    }

    .right {
        @include mq($screen-m) {
            text-align: right;

            // generated span by magento
            &:before {
                text-align: right;
            }
            span {
                text-align: right;
            }
        }
    }

    .center {
        @include mq($screen-m) {
            text-align: center;
        }
    }

    // extra padding for proper column position
    .pr-50 {
        @include mq($screen-m) {
            padding-right: 50px;
        }
    }

    .pl-50 {
        @include mq($screen-m) {
            padding-left: 50px;
        }
    }
}
