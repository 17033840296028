.loyalty-campaigns {
    margin: 0 auto $spacer--extra-large;
    max-width: calc(100vw - #{$spacer--extra-large});

    &__dropdown {
        .dropdown-list__content {
            padding-top: $spacer--medium;
            background-color: $white;
        }
    }

    .dropdown-list__expand-button {
        background-color: $loyalty-orange;

        &:hover,
        &:focus {
            .icon {
                fill: $white;
            }
        }
    }

    &__table {
        tbody {
            td {
                height: 50px;
                max-height: 50px;
            }
        }
    }

    &__rank {
        margin-top: 37px;
    }

    &__turnover-progress {
        margin: $spacer--medium 0;
        background-color: $white;
        border: 1px solid $color-secondary;
    }

    &__turnover-progress-heading,
    &__turnover-progress-subheading,
    &__turnover-progress-points {
        padding: $spacer--medium $spacer--large;
        font-size: $font-size-large;
        background-color: $color-primary;
        color: $white;
    }

    &__turnover-progress-heading {
        border-bottom: 1px solid $color-secondary;
    }

    &__turnover-progress-subheading,
    &__turnover-progress-points {
        margin-bottom: 2px;
        font-size: $font-size-small;
        text-align: center;
    }

    &__turnover-progress-content {
        padding: $spacer--medium $spacer--large;
        text-align: center;
        font-size: $font-size-extra-large;
        font-weight: $font-weight-bold;
        background-color: $white;
        color: $green-dark;
    }
}
