.addto-popup {
    &.popup {
        width: 100%;
        top: auto;
        bottom: -50px;
    }
    &--list-view {
        &.popup {
            top: auto;
            bottom: -50px;
            @include mq($min-screen: $screen-m, $max-screen: $screen-xl) {
                width: calc(30% + 65px);
            }

            @include mq($screen-xl) {
                bottom: auto;
                top: calc((100% / 2) + 22px);
            }
        }
    }

    &__button {
        justify-content: flex-start;
        color: $gray;
        background-color: $white;
        font-weight: $font-weight-base;
        text-transform: none;

        &:hover,
        &:focus {
            background-color: $gray-lightest;
            color: $gray-dark;
        }
    }
}
