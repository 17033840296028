$file-upload__margin        : $spacer--medium !default;
$file-upload__field-height  : 48px !default;
$file-upload__font-size     : 14px !default;
$file-upload__button-padding: 0 $spacer--large !default;

.file-upload {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $file-upload__margin;
    max-width: 100%;

    &__wrapper {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        width: 100%;
    }

    &__input {
        @include visually-hidden();
    }

    &__name {
        position: relative;
        margin: 0;
        padding: 15px 10px;
        width: calc(50% - 25px);
        height: 50px;
        background-color: $gray-lightest;
        color: $gray-dark;
        font-size: $file-upload__font-size;
        text-transform: initial;
        overflow: hidden;

        @include mq($screen-s) {
            width: calc(60% - 25px);
        }
        @include mq($screen-m) {
            width: calc(70% - 25px);
        }
    }

    &__button {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        padding: $file-upload__button-padding;
        width: calc(50% - 25px);
        font-weight: $font-weight-base;
        @include mq($screen-s) {
            width: calc(40% - 25px);
        }
        @include mq($screen-m) {
            width: calc(40% - 25px);
        }
    }

    &__button-remove {
        background-color: $white;
        width: 50px;

        .icon {
            fill: $color-secondary;
        }
    }

    &--secondary {
        background-color: $gray-lightest;
        flex-flow: row nowrap;
        border: none;
        margin: 0;
        transition: $transition-base;
        height: 100%;
        align-items: center;
        justify-content: center;
        border: 2px dashed $blue;
        border-radius: 3%;

        @include mq($screen-m) {
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            border: 2px dashed $color-primary;
            border-radius: 5%;
            align-items: center;
            justify-content: center;
        }
        &[disabled=true],
        &[disabled=disabled] {
            pointer-events: none;
            cursor: not-allowed;
        }
        &[dragover=true] {
            background-color: darken($gray-lightest, 10%);
            .file-upload__name {
                color: darken($color-primary, 10%);
            }
            .file-upload__button {
                background-color: darken($color-primary, 10%);
                color: darken($white, 10%);
            }
            * {
                pointer-events: none;
            }
        }
        .file-upload__wrapper {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: $spacer--large 0;
            height: auto;
            width: auto;
            background: transparent;
        }
        .file-upload__name {
            color: $color-primary;
            font-weight: $font-weight-thin;
            text-align: center;
            width: auto;
            height: auto;
            background: transparent;
            @include mq($screen-m) {
                max-width: 200px;
            }
        }
        .file-upload__button {
            width: auto;
            font-size: $font-size-extra-small;
            height: 50px;
        }
    }
}
