$header__search-border-color: #ededed;

.header {
    &__search-trigger {
        @include mq($screen-m) {
            display: none;
        }
    }

    &__search {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;

        @include mq($screen-m) {
            display: flex;
            justify-content: flex-end;
            flex: 1;
            position: static;
            width: auto;
        }

        &--open {
            display: block;
            z-index: 10;
            @include mq($screen-m) {
                display: flex;
            }

            @include mq($max-screen: $screen-m) {
                background-color: white;
                z-index: 3;
            }
        }
    }

    &__search-wrapper {
        display: flex;
        width: 100%;

        @include mq($screen-xl) {
            width: calc(100% - 56px);
        }
    }

    &__search-dropdown {
        min-width: auto;
    }

    &__search-input-wrapper {
        display: flex;
        flex-basis: calc(100% - 20px);
        max-width: 590px;
        align-items: center;
        margin: 0;
        height: 55px;
        border-bottom: 1px solid $header__search-border-color;

        @include mq($screen-m) {
            order: 2;
        }

        @include mq($screen-xl) {
            min-width: 280px;
            border: none;
        }

        @include mq($screen-xxl) {
            width: 590px;
        }
    }

    &__search-input-field {
        flex: 1;
        width: auto;
        height: 40px;
        margin: 0;
        padding: 0 $spacer--medium;
        border: none;
        color: $gray-darker-secondary;
        font-weight: $font-weight-base;
        box-shadow: none;

        @include mq($screen-m) {
            height: 100%;
        }

        &::placeholder {
            font-size: 14px;
            color: $gray-darker-secondary;
            font-weight: $font-weight-thin;
        }
        &.input__field {
            padding-top: 0;
        }
    }

    &__search-select {
        margin-bottom: 0;
        @include mq($screen-m) {
            order: 1;
            flex-basis: 40%;
            width: 180px;
            max-width: 180px;
            font-size: 14px;
        }
        @include mq($screen-xl) {
            margin: 0;
        }
    }

    // customize vue-multiselect styles
    .multiselect {
        z-index: 59;

        @include mq($screen-m) {
            height: 40px;
        }
        @include mq($screen-xl) {
            height: 44px;
        }
        @include mq($screen-xxl) {
            height: 55px;
        }
        &__tags {
            min-height: 50px;
            background-color: $color-primary;
            color: $white;
            border: none;
            padding-right: 34px;
            transition: $transition-base;
            border-bottom: 1px solid transparent;
            font-size: $font-size-base;

            @include mq($screen-m) {
                height: 55px;
                width: 180px;
                max-width: 180px;
                min-height: 55px;
                line-height: 55px;
                padding-top: 0;
                background-color: $gray-lightest;
            }
        }
        &__single {
            background-color: $color-primary;
            border-radius: 0;
            font-size: 14px;
            @include mq($screen-m) {
                top: 50%;
                transform: translateY(-50%);
                background-color: $gray-lightest;
                color: $gray-darker-secondary;
            }
        }
        &__option {
            &--highlight {
                background-color: $gray-lightest !important; // sass-lint:disable-line no-important
            }
        }

        &__select {
            padding: 0;
            z-index: 1;
            @include mq($screen-m) {
                top: 0;
                right: 0;
                width: 34px;
                height: 55px;
                background-color: $color-primary;
            }

            &:before {
                content: '';
                display: block;
                width: 12px;
                height: 12px;
                margin: 0;
                background: transparent url('../images/icons/arrow-down-white.svg') no-repeat center center;
                background-size: 12px;
                border: 0;
                transition: $transition-base;
                top: 50%;
                left: 50%;
                transform: rotate(0) translate(-50%, -50%);
                transform-origin: top left;
            }
        }
        &--active {
            .multiselect {
                &__select {
                    transform: none;
                    &:before {
                        transform: rotate(180deg) translate(-50%, -50%);
                    }
                }
                &__tags {
                    @include mq($screen-m) {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.07);
                    }

                }
            }
        }
    }
}
