$login-form__button-margin-top  : $spacer--medium;
$login-form__input-margin-bottom: $spacer--medium;

.login-form {
    width: 100%;

    &__button {
        margin-top: $login-form__button-margin-top;
        text-transform: uppercase;
    }

    &__input {
        margin-bottom: $login-form__input-margin-bottom;
    }
}
