.notification-history-table-heading {
    display: flex;
    align-items: center;
    list-style: none;
    background-color: transparent;
    padding: 0 $spacer--extra-large;
    margin-bottom: $spacer--large;

    &__checkbox {
        flex: 0 0 auto;
    }
    &__data {
        display: none;
        flex-direction: column;
        flex: 1 1;
        @include mq($screen-xl) {
            display: flex;
            flex-direction: row;
        }
    }
    &__title-1 {
        font-size: $font-size-base;
        font-weight: $font-weight-thin;
        flex: 1;
        margin-bottom: $spacer--large;
        color: $gray-dark;
        @include mq($screen-xl) {
            margin-bottom: 0;
        }
    }
    &__title-2 {
        font-size: $font-size-base;
        font-weight: $font-weight-thin;
    }
}
