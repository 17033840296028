.notes-list {
    background-color: $gray-lightest;
    &--active {
        display: block;
    }

    &__title {
        font-weight: $font-weight-bold;
        font-size: $font-size-base;
    }

    &__content {
        display: none;
        flex-flow: row wrap;
        justify-content: flex-end;
        margin-bottom: $spacer--large;

        &--active {
            display: flex;
        }
    }

    &__list {
        width: 100%;
        background-color: $white;
        padding: $spacer--large;
    }

    &__item {
        padding-bottom: $spacer--medium;
        @include mq($screen-xl) {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
        }
    }
    &__author {
        flex-basis: 120px;
        font-weight: $font-weight-bold;
    }
    &__message {
        flex: 1 1;
        font-size: $font-size-small;
        font-weight: $font-weight-thin;
        color: $gray-dark;
        margin: 0;
        @include mq($screen-xl) {
            padding-right: $spacer--extra-large;
        }
        &--author-highlight {
            .notes-list__author {
                color: $color-primary;
            }
        }
    }

    &__date {
        font-size: 14px;
        font-weight: $font-weight-thin;
        color: $gray-dark;
        white-space: nowrap;
    }

    &__time {
        padding-left: $spacer--medium;
    }
}
