.banners {
    &__list {
        margin: 0;
        padding-left: 0;
        list-style-type: none;
    }

    &__item {
        padding-bottom: 10px;
    }
}
