.product-search {
    position: relative;

    &__quicksearch.quicksearch {
        top: 87px;
        width: calc(100% - #{$spacer--extra-large});
        left: $spacer--medium;
        @include mq($screen-m) {
            width: calc(100% - 40px);
            left: $spacer--extra-large;
        }
    }

    &__search {
        @include mq($screen-m) {
            padding: $spacer--medium $spacer--extra-large;
        }
    }

    .search-bar__text {
        margin: $spacer--medium 0;
    }
}
