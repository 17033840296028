$headings-margin                   : 0 0 0.5em 0;
$headings-font-family              : $font-family-base;
$headings-font-weight              : 700;
$headings-font-weight--second-level: 500;
$headings-line-height              : $font-line-height;
$headings-color                    : $font-color-base;
$headings-text-transform           : none;
$heading-font-size--first-level    : 23px;
$heading-font-size--second-level   : 20px;
$heading-font-size--third-level    : 16px;
$heading-font-size--fourth-level   : 15px;
$heading-font-size--fifth-level    : 14px;
$heading-font-size--sixth-level    : 13px;

.heading {
    margin: $headings-margin;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
    text-transform: $headings-text-transform;

    &--first-level {
        font-size: $heading-font-size--first-level;
    }

    &--second-level {
        font-size: $heading-font-size--second-level;
    }

    &--third-level {
        font-size: $heading-font-size--third-level;
    }

    &--fourth-level {
        font-size: $heading-font-size--fourth-level;
    }

    &--fifth-level {
        font-size: $heading-font-size--fifth-level;
    }

    &--sixth-level {
        font-size: $heading-font-size--sixth-level;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @extend .heading;
}

h1 {
    @extend .heading--first-level;
}

h2 {
    @extend .heading--second-level;
}

h3 {
    @extend .heading--third-level;
}

h4 {
    @extend .heading--fourth-level;
}

h5 {
    @extend .heading--fifth-level;
}

h6 {
    @extend .heading--sixth-level;
}
