$breadcrumbs__display\@mobile        : none !default;
$breadcrumbs__display-mq             : $screen-l !default;
$breadcrumbs__margin                 : $spacer !default;
$breadcrumbs__padding                : $spacer 0 !default;
$breadcrumbs__min-height             : 40px !default;

$breadcrumbs__color                  : $gray;
$breadcrumbs__color--active          : $gray;

$breadcrumbs__font-family            : $font-family-base !default;
$breadcrumbs__font-family--active    : $font-family-base !default;
$breadcrumbs__font-size              : $font-size-small;
$breadcrumbs__line-height            : 24px !default;
$breadcrumbs__font-weight            : $font-weight-thin;


$breadcrumbs__list-padding           : 0 !default;
$breadcrumbs__list-margin            : 0 !default;
$breadcrumbs__list-flow              : row wrap;

$breadcrumbs__item-padding           : 0 !default;

$breadcrumbs__link-padding           : 0 !default;

$breadcrumbs__text-decoration        : none !default;
$breadcrumbs__text-decoration--active: none !default;
