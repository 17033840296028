.catalog-requisition-lists {
    position: absolute;
    top: 0;
    width: 100%;
    border: 1px solid $border-color-base;
    background-color: $white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    z-index: 3;

    &__button {
        justify-content: flex-start;
        width: 100%;
        text-transform: none;
        font-weight: $font-weight-bold;
        color: $gray;
    }

    &__form {
        padding: 0 $spacer--large $spacer--medium;
    }

    &__label {
        text-transform: none;
        color: $gray;
        font-size: 13px;
    }

    &__input {
        height: 40px;
        margin-bottom: $spacer--medium;
    }

    &__form-button {
        width: 100%;
        height: 40px;
    }

    &__form-msg {
        padding: 0 $spacer--large;
        color: $color-success;
    }
    &__error {
        font-size: 12px;
        color: $color-danger;
    }

    .list__item {
        padding: 0;
        border-bottom: 1px solid $border-color-base;
    }
}
