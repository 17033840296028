$button__size      : 50px;
$button__icon-size : 17px;

.button {
    display: flex;
    align-items: center;
    justify-content: center;

    height: $button__size;
    min-width: $button__size;
    padding: 0 $spacer--large;

    background-color: $color-primary;
    border: none;
    border-radius: 0;
    outline: none;

    font-size: $font-size-extra-small;
    font-weight: $font-weight-bold;
    line-height: 1;
    color: $white;

    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    transition: $transition-base;

    &:hover,
    &:focus {
        color: $color-primary;
        background-color: $gray-lightest;
    }

    &:disabled {
        cursor: not-allowed;
    }

    // Visual styles
    &--secondary {
        color: $color-primary;
        background-color: $gray-lightest;

        &:hover,
        &:focus {
            color: $gray-lightest;
            background-color: $color-primary;
        }
        &-no-hover {
            &:hover,
            &:focus {
                color: $color-primary;
                background-color: $gray-lightest;
            }
        }
    }

    &--dark {
        background-color: $color-secondary;
        color: $white;

        &:hover,
        &:focus {
            color: $white;
            background-color: $color-secondary--active;
        }
    }

    &--darker {
        background-color: $color-secondary--active;
        color: $white;

        &:hover,
        &:focus {
            color: $white;
            background-color: $color-secondary;
        }
    }

    &--link {
        background-color: transparent;
        color: $color-primary;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: none;
            color: $color-secondary;
        }
    }

    &--link-inline {
        padding: 0;
        height: auto;
        min-height: $spacer--large;
        font-size: $font-size-base;
        background-color: transparent;
        color: $color-primary;
        text-decoration: none;
        text-transform: none;

        &:hover,
        &:focus {
            text-decoration: none;
            color: $color-secondary;
            background-color: transparent;
        }
    }

    // Layout types
    &--fluid {
        width: 100%;
    }

    &--fixed {
        display: inline-flex;
    }

    &--icon {
        &:hover,
        &:focus {
            .icon {
                fill: $color-primary;
            }
        }

        .icon {
            width: $button__icon-size;
            height: $button__icon-size;
            fill: $white;
            transition: $transition-base;
            pointer-events: none;
        }

        .button__text {
            pointer-events: none;
            &:nth-child(1) {
                margin-right: $spacer--medium;
            }
            &:nth-child(2) {
                margin-left: $spacer--medium;
            }
        }
    }

    &--skalar {
        .icon {
            width: 100%;
            height: 24px;
            margin-right: $spacer--medium;
        }
    }

    &--vitalle {
        background-color: $custom-cms-color-azure;
        .icon {
            width: 100%;
            height: 24px;
            fill: $white;
            stroke: $white;
            margin-right: $spacer--medium;
        }
        &:hover,
        &:focus {
            background-color: $custom-cms-color-gray-light;
            .icon {
                fill: $custom-cms-color-gray-dark;
                stroke: $custom-cms-color-azure;
            }
        }
    }

    &--loyalty {
        background-color: $loyalty-orange;

        .icon {
            width: 100%;
            height: 24px;
            margin-right: $spacer--medium;
        }

        &:hover,
        &:focus {
            background-color: $loyalty-purple;
        }
    }

    &--checkout-submit {
        &[disabled="true"],
        &[disabled="disabled"] {
            color: darken($color-primary, 15%);
            background-color: darken($gray-lightest, 15%);

            &:hover,
            &:focus {
                color: darken($color-primary, 15%);
                background-color: darken($gray-lightest, 15%);
            }
        }
    }

    &--text-center {
        .button__text {
            &:nth-child(1) {
                margin-right: auto;
                margin-left: auto;
            }
        }
    }

    &--outlined {
        padding: 0 30px;
        border: 2px solid $gray-light;
        border-radius: $spacer;
        background-color: transparent;
        color: $gray-dark;
        font-weight: $font-weight-base;

        &:hover,
        &:focus {
            color: $gray-dark;
            font-weight: $font-weight-bold;
            border-color: $gray;
        }
    }

    &--point {
        background-color: transparent;
        width: 32px;
        height: 32px;
        min-width: 0;
        min-height: 0;
        padding: 0;
        border: 1px solid $white;
        border-radius: 20px;

        &:hover,
        &:focus {
            background-color: rgba($white, 0.5);
        }

        @include mq($screen-m) {
            width: 40px;
            height: 40px;
        }

        .button__icon {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 12px;
            height: 12px;
            fill: $red;
            margin: -6px 0 0 -6px;

            @include mq($screen-m) {
                width: 16px;
                height: 16px;
                margin: -8px 0 0 -8px;
            }
        }
    }

    // Combined
    &.button--icon {
        &.button--secondary {
            &:hover,
            &:focus {
                .icon {
                    fill: $white;
                }
            }

            .icon {
                fill: $color-primary;
            }
        }
        &.button--dark {
            &:hover,
            &:focus {
                .icon {
                    fill: $white;
                }
            }

            .icon {
                fill: $white;
            }
        }
    }

    &.button--link.button--icon {
        &:hover,
        &:focus {
            .icon {
                fill: $color-secondary;
            }
        }

        .icon {
            fill: $color-primary;
        }

        &.button--dark {
            color: $color-secondary;

            &:hover,
            &:focus {
                color: $color-primary;

                .icon {
                    fill: $color-primary;
                }
            }

            .icon {
                fill: $color-secondary;
            }
        }
    }
}
