.catalog-item--slim {
    min-width: 100px;
    padding: 0;

    .catalog--grid &,
    .slider--products & {
        .catalog-item__top-wrapper {
            .catalog-item__image {
                max-height: 85px;
                width: auto;
                margin: 0 auto;
                @include mq($screen-m) {
                    max-height: none;
                    width: 100%;
                    margin: 0;
                }
            }
            .catalog-item__name-wrapper {
                padding: 0 0 $spacer;
                width: 100%;

                @include mq($screen-xl) {
                    padding: $spacer 48px $spacer 0;
                }
            }
            .catalog-item__name {
                margin: 0;

                @include mq($screen-s) {
                    height: 42px;
                }
            }
        }
        .catalog-item__info {
            padding: 0;
            margin: 0;

            @include mq($screen-xl) {
                padding: $spacer 0;
            }

            &-action {
                .list__item--action {
                    display: none;
                    @include mq($screen-m) {
                        display: flex;
                    }
                }

            }
            .list__item {
                padding: 0;
            }
        }
        .catalog-item__stock {
            padding: $spacer--medium 0;
            border-top: none;

            @include mq($screen-xl) {
                padding: $spacer 0;
            }
            .catalog-item-stock-list {
                margin: 0;
            }
            .catalog-item-stock-list__item {
                padding: 0;
                line-height: 1.3;

                &--secondary {
                    flex-direction: column;

                    @include mq($screen-m) {
                        flex-direction: row;
                    }
                }
            }
            .catalog-item-stock-list__item-key {
                color: $gray-darker;
            }
            .catalog-item-stock-list__item-key,
            .catalog-item-stock-list__item-value {
                font-size: 12px;
            }
            .catalog-item-stock-list__item-value {
                display: inline-flex;
                color: #038c3e;
                font-weight: $font-weight-bold;
            }
            .catalog-item-stock-list__discount {
                color: #e51b2b;
                font-weight: $font-weight-bold;
            }
            .catalog-item-stock-list__item-value-link {
                display: flex;
                align-items: center;
                padding: 0 $spacer;
                font-size: $font-size-extra-small;
                &:hover,
                &:focus {
                    color: $color-primary;
                }

                @include mq($screen-m) {
                    padding: 0;
                    font-size: 12px;
                }
            }
            .catalog-item-stock-list__item-value-link-icon {
                fill: $color-primary;
                width: 10px;
                height: 10px;
                margin-left: $spacer;
                margin-top: 2px;
            }
        }
        .catalog-item__bottom-wrapper {
            display: flex;
            flex-direction: column;
            padding: $spacer 0;

            @include mq($screen-m) {
                flex-direction: row;
                gap: $spacer--medium; // sass-lint:disable-line no-misspelled-properties
            }

            &--logged-in {
                justify-content: space-between;
                align-items: flex-start;

                @include mq($screen-m) {
                    align-items: center;
                }

                .catalog-item__price-wrapper {
                    align-items: flex-start;
                }
            }
        }
        .catalog-item__price-wrapper {
            padding-right: $spacer;

            @include mq($screen-m) {
                gap: 0; // sass-lint:disable-line no-misspelled-properties
            }
        }
        .catalog-item__omnibus {
            padding: 0;
            flex-flow: row nowrap;
            align-items: baseline;
            gap: $spacer; // sass-lint:disable-line no-misspelled-properties
            justify-content: flex-end;

            &-label {
                font-size: 10px;
                color: $gray;
            }

            &-value {
                font-size: $font-size-small;
                color: $color-primary--faded;
                position: relative;
            }

            &-hint {
                position: absolute;
                top: -2px;
                bottom: 0;
                right: -12px;
                cursor: pointer;

                .hint__content {
                    min-width: 200px;
                    text-transform: none;
                }

                .hint__icon {
                    width: 10px;
                    height: 10px;
                }
            }
        }
        .catalog-item__price {
            justify-content: flex-end;
            padding: 0;
            margin-bottom: 0;
            color: $gray-darker;

            @include mq($screen-m) {
                flex-direction: row;
                align-items: center;
            }

            .catalog-item__price-label {
                font-size: 12px;
                color: $gray-darker;

                @include mq($screen-m) {
                    margin-bottom: 0;
                }
            }

            .catalog-item__price-value {
                display: flex;
                justify-content: flex-end;
                font-size: $font-size-medium;
                color: $color-accent;
                margin-left: $spacer;

                @include mq($screen-m) {
                    display: inline-flex;
                }
            }
        }
        .catalog-item__actions {
            flex-flow: row nowrap;
            padding: $spacer 0;
            margin: 0;

            @include mq($screen-xl) {
                padding: $spacer 0;
            }

            .catalog-item__qty-input {
                width: auto;
                margin: 0 $spacer 0 0;
                .label {
                    display: none;
                }
                .input__field {
                    width: 48px;
                    height: 35px;
                    @include mq($screen-m) {
                        height: 44px;
                    }
                }
            }
            .catalog-item__addto {
                .catalog-item__primary-action {
                    height: 35px;
                    border-radius: $border-radius-secondary;
                    @include mq($screen-m) {
                        height: 44px;
                    }
                }
                .catalog-item__option-action {
                    display: none;
                    height: 35px;
                    @include mq($screen-m) {
                        display: flex;
                        height: 44px;
                    }
                }
            }
        }

        .catalog-item__option-action {
            @include mq($screen-m) {
                width: auto;
            }

            .icon {
                @include mq($screen-m) {
                    position: relative;
                    right: auto;
                }
            }
        }
        .catalog-item__option-action-text {
            @include mq($screen-m) {
                display: none;
            }
        }
    }

    .catalog--grid & {
        border: 1px solid #ededed;
        border-width: 0 1px 1px 0;

        &:nth-child(2n) {
            border-width: 0 0 1px 0;
        }

        @include mq($screen-m) {
            &:nth-child(2n) {
                border-width: 0 1px 1px 0;
            }
            &:nth-child(3n) {
                border-width: 0 0 1px;
            }
        }

        @include mq($screen-l) {
            &:nth-child(3n) {
                border-width: 0 1px 1px 0;
            }
            &:nth-child(4n) {
                border-width: 0 0 1px;
            }
        }
    }

    .catalog-wrap & {
        &:nth-child(2n) {
            border-width: 0 1px 1px 0;
        }

        &:nth-child(2n + 1) {
            border-width: 0 0 1px 0;
        }

        @include mq($screen-m) {
            &:nth-child(2n + 1) {
                border-width: 0 1px 1px 0;
            }
            &:nth-child(3n + 1) {
                border-width: 0 0 1px;
            }
        }

        @include mq($screen-l) {
            &:nth-child(3n + 1),
            &:nth-child(4n) {
                border-width: 0 1px 1px 0;
            }
            &:nth-child(4n + 1) {
                border-width: 0 0 1px;
            }
        }
    }
}

.catalog-item-stock-list {
    width: 100%;
    padding: 0;
    line-height: $font-line-height;

    &__item {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        list-style: none;
    }

    &__item-value {
        &--availability,
        &--in-stock {
            color: #038c3e;
            font-weight: $font-weight-bold;
        }

        &--out-of-stock {
            color: #e51b2b;
            font-weight: $font-weight-bold;
        }
    }
}
