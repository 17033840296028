// sass-lint:disable no-important
.left {
    text-align: left;
}

//prevent document from scrolling under mobile navigation overlay
.no-scroll {
    overflow: hidden;
    height: 100%;
}

.hidden,
[hidden=true],
[hidden=hidden] {
    @include visually-hidden();
}

// page builder classes
.max-w-screen-xs {
    max-width: $screen-xs;
}
.max-w-screen-s {
    max-width: $screen-s;
}
.max-w-screen-m {
    max-width: $screen-m;
}
.max-w-screen-l {
    max-width: $screen-l;
}
.max-w-screen-xl {
    max-width: $screen-xl;
}
.max-w-screen-xxl {
    max-width: $screen-xxl;
}
.max-w-full {
    max-width: 100%;
}

[class*="max-w-screen-"] {
    margin: auto;
}

.margin-desktop {
    @include mq($max-screen: $screen-l) {
        margin: 0 !important;
    }
}

.margin-tablet {
    @include mq($max-screen: $screen-s) {
        margin: 0 !important;
    }
}

.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}
// FLEX
.flex {
    display: flex;
}
// align items
.items-start {
    align-items: flex-start;
}
.items-end {
    align-items: flex-end;
}
.items-center {
    align-items: center;
}
.items-baseline {
    align-items: baseline;
}
.items-stretch {
    align-items: stretch;
}
// justify-content
.justify-start {
    justify-content: flex-start;
}
.justify-end {
    justify-content: flex-end;
}
.justify-center {
    justify-content: center;
}
.justify-between {
    justify-content: space-between;
}
.justify-around {
    justify-content: space-around;
}
.justify-evenly {
    justify-content: space-evenly;
}
// flex-direction
.flex-col {
    flex-direction: column;
}
.flex-row {
    flex-direction: row;
}
// padding-left
.pl-base {
    padding-left: $spacer !important;
}

.pl-medium {
    padding-left: $spacer--medium !important;
}

.pl-semi-large {
    padding-left: $spacer--medium !important;
}

.pl-large {
    padding-left: $spacer--large !important;
}

.pl-extra-large {
    padding-left: $spacer--large !important;
}

.py-extra-large {
    padding: $spacer--large 0 !important;
}

.p-0 {
    padding: 0 !important;
}

// margin-top
.mt-base {
    margin-top: $spacer !important;
}

.mt-medium {
    margin-top: $spacer--medium !important;
}

.mt-semi-large {
    margin-top: $spacer--semi-large !important;
}

.mt-large {
    margin-top: $spacer--large !important;
}

.mt-extra-large {
    margin-top: $spacer--extra-large !important;
}

// margin-bottom
.mb-0 {
    margin-bottom: 0 !important;
}

.mb-base {
    margin-bottom: $spacer !important;
}

.mb-medium {
    margin-bottom: $spacer--medium !important;
}

.mb-semi-large {
    margin-bottom: $spacer--semi-large !important;
}

.mb-large {
    margin-bottom: $spacer--large !important;
}

.mb-extra-large {
    margin-bottom: $spacer--extra-large !important;
}

.mb-110 {
    margin-bottom: 110px !important;
}

// full width
.full-width {
    width: 100vw !important;
    position: relative !important;
    left: 50% !important;
    right: 50% !important;
    margin-left: -50vw !important;
    margin-right: -50vw !important;
}

// POSITION
.static {
    position: static !important;
}
.fixed	 {
    position: fixed !important;
}
.absolute {
    position: absolute !important;
}
.relative {
    position: relative !important;
}
.sticky {
    position: sticky !important;
}

// z-index
.z-0 {
    z-index: 0 !important;
}
.z-10 {
    z-index: 10 !important;
}
.z-20 {
    z-index: 20 !important;
}
.z-30 {
    z-index: 30 !important;
}
.z-40 {
    z-index: 40 !important;
}
.z-50 {
    z-index: 50 !important;
}
.z-auto	{
    z-index: auto !important;
}

.border-none {
    border: none !important;
}
