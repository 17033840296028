.returns-view-details {
    width: 100%;
    @include mq($screen-m) {
        width: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    &__title {
        display: block;
        margin: $spacer 0;
        overflow: hidden;
        line-height: 24px;
        color: $gray-darker;
        text-align: left;
        font-size: $font-size-base;
        @include mq($screen-m) {
            margin: 0 0 $spacer;
            font-size: $font-size-extra-small;
        }
        @include mq($screen-l) {
            margin: 0 0 $spacer;
            font-size: $font-size-base;
        }
    }
    &__list {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding: 0;
        list-style: none;
        margin: 0;
    }
    &__item {
        display: flex;
        flex-basis: 100%;
        justify-content: space-between;
        padding: 5px 0;
        @include mq($screen-m) {
            justify-content: flex-start;
        }
    }
    &__label {
        font-weight: $font-weight-thin;
        white-space: nowrap;
    }
    &__value {
        padding-left: $spacer;
        font-weight: $font-weight-bold;
        text-align: right;
    }
    &__label,
    &__value {
        font-size: $font-size-base;
        @include mq($screen-m) {
            margin: 0 0 $spacer;
            font-size: $font-size-extra-small;
        }
        @include mq($screen-l) {
            margin: 0 0 $spacer;
            font-size: $font-size-base;
        }
    }
}
