$header__size             : 55px;
$header__border-color     : #ededed;

.header {
    position: relative;
    border-bottom: 1px solid $header__border-color;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);

    &__menu-wrapper {
        display: none;
        border-top: 1px solid rgba(0, 0, 0, 0.07);

        @include mq($screen-xl) {
            display: block;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: nowrap;
        margin: 0;

        @include mq($screen-xl) {
            flex-direction: row;
            height: $header__size;
        }

        @include mq($container__max-width + 2 * $container__margin) {
            margin: 0 auto;
        }

        &--mega-menu {
            position: relative;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__logo {
        margin-right: auto;

        @include mq($screen-m) {
            margin-right: 54px;
            padding: $spacer $spacer--medium;
        }

        @include mq($screen-xxl) {
            margin-right: 110px;
        }
    }

    &__panels {
        display: flex;
        flex: 0 1 auto;
        flex-flow: row nowrap;
        justify-content: space-between;
        min-height: 50px;
        max-height: 55px;

        @include mq($screen-xl) {
            flex: none;
        }

        &--top {
            padding: 0 0 0 $spacer--large;
            border-bottom: 1px solid $header__border-color;

            @include mq($screen-xl) {
                width: 55%;
                border: none;
            }
        }
    }

    &__panel {
        display: flex;

        @include mq($screen-m) {
            flex: 1;
        }

        @include mq($screen-xl) {
            flex: none;
        }

        &--search {
            @include mq($screen-xl) {
                width: 100%;
                order: 1;
            }
        }
        &--switcher {
            order: 2;
        }
        &--login {
            order: 2;
        }
        &--cart {
            @include mq($screen-xl) {
                order: 3;
                flex: 0 0 auto;
            }
        }
        &--account {
            flex: none;

            @include mq($screen-xl) {
                order: 4;
            }
        }
    }

    &__account-trigger {
        @include mq($screen-xl) {
            display: none;
        }
    }

    &__account {
        display: none;

        @include mq($screen-xl) {
            display: flex;
            align-items: center;

            .link {
                padding: 0 $spacer--extra-large;
                color: $gray-darker;
                text-decoration: none;

                &:hover,
                &:focus {
                    text-decoration: none;
                    color: $gray-darker;
                }
            }
        }
    }

    &__account-popup {
        position: relative;
        top: 1px;
        margin: 0;

        @include mq($screen-m) {
            margin: 0 auto;
        }

        .sidebar__item--popup-menu {
            display: block;
            height: auto;
        }
    }

    &__account-footer {
        @include mq($screen-s) {
            display: none;
        }
    }

    &__switcher-wrap {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        .dropdown-switcher {
            &__icon {
                @include mq($screen-m) {
                    position: relative;
                }
            }
        }
    }

    &__search-trigger {
        &.header-button {
            background-color: $color-primary;

            .icon {
                fill: $white;
            }
        }
    }

    &--sticky {
        @include mq($max-screen: $screen-m) {
            position: fixed;
            width: 100%;
            max-width: 100vw;
            background-color: white;
            top: 0;
            z-index: 5;
        }
    }
}
