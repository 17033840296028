.notification-history {
    &__actions {
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
        border-bottom: 1px solid $border-color-base;
        padding-bottom: $spacer--extra-large;
        margin-bottom: $spacer--extra-large;
        @include mq($screen-xl) {
            flex-flow: row nowrap;
        }
    }
    &__buttons {
        display: flex;
        align-items: center;
        @include mq($screen-xl) {
            order: 1;
        }
    }
    &__action-btn {
        margin-right: $spacer--large;
    }
    &__search {
        padding: 0;
        flex: 1 1 100%;
        margin-bottom: $spacer--large;
        @include mq($screen-xl) {
            flex: 0 1 630px;
            order: 2;
            margin-bottom: 0;
        }
    }
}
