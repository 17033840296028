.shopping-lists-view__navigation {
    display: flex;
    flex-flow: column nowrap;
    @include mq($screen-m) {
        flex-flow: row;
        margin: 0 $spacer--medium;
    }
    .icon {
        fill: $color-primary;
    }
}
