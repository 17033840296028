$slider-slick__nav-button-size: 48px;
$slider-slick__nav-icon-size: 48px;

.slider-slick {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    @include clearfix();

    &--100vw {
        position: relative;
        width: 100vw;
        margin-left: 50%;
        left: -50vw;
    }

    &__navigation {
        display: none;
        position: absolute;
        width: 100%;
        top: 50%;
        margin-top: -$slider-slick__nav-button-size * 0.5;

        @include mq($screen-m) {
            display: block;
        }
    }

    &__arrows {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }

    &__nav-button {
        width: $slider-slick__nav-button-size;
        height: $slider-slick__nav-button-size;
        border-radius: 0;
        padding: 0;
        position: relative;

        &:not(.button--secondary) {
            background: none;

            &:hover,
            &:focus,
            &:active {
                background: none;
            }
        }
    }

    &__nav-icon {
        width: $slider-slick__nav-icon-size;
        height: $slider-slick__nav-icon-size;
        fill: $gray-dark;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -$slider-slick__nav-icon-size * 0.5;
        margin-left: -$slider-slick__nav-icon-size * 0.5;
    }

    &__next {
        float: right;
        margin-right: $spacer--medium;

        @include mq($screen-l) {
            margin-right: $spacer--large;
        }
    }

    &__prev {
        margin-left: $spacer--medium;

        @include mq($screen-l) {
            margin-left: $spacer--large;
        }
    }

    &__dots {
        display: block;
        position: absolute;
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: center;
        width: 100%;
        left: 0;
        bottom: $spacer--extra-large;

        li {
            display: inline-block;
            margin: 0;
            padding: $spacer--medium;

            &.slick-active {
                button {
                    background-color: $gray-lightest;
                }
            }
        }

        button {
            display: block;
            width: 8px;
            height: 8px;
            padding: 0;
            background: none;
            border: 2px solid $gray-lightest;
            border-radius: 50%;
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    &__slides {
        height: auto;

        &--loaded {
            .slider-slick__item {
                &:nth-child(n+2) {
                    display: block;
                }
            }
        }
    }

    &__item {
        height: auto;

        &:nth-child(n+2) {
            display: none;
        }
    }

    &--products {
        .slider-slick__nav-button {
            border: 1px solid $gray-light;

            &:hover,
            &:focus,
            &:active {
                border-color: $color-primary;
            }
        }
    }
}
