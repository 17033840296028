$iframe__container--padding-top : 56.25%; // 16:9 Aspect Ratio (9/16 = 0.5625)
$iframe__max-width              : 550px;

.iframe {
    max-width: $iframe__max-width;
    margin: auto;

    &__container {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: $iframe__container--padding-top;
    }

    &__video {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}
