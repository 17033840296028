$s-tooltip__icon-size: 14px;

.s-tooltip {
    display: inline-flex;

    &__label {
        margin-right: $spacer--medium;
    }
}

.v-popper--theme-tooltip {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    .v-popper__inner {
        max-width: 60vw;
        padding: $spacer 8px;
        font-size: 12px;
        background: $color-primary;
        border-radius: 3px;
        line-height: normal;

        @include mq($screen-m) {
            max-width: 456px;
        }
    }

    .v-popper__arrow-outer {
        border-color: $color-primary;
    }

    .icon {
        width: $s-tooltip__icon-size;
        height: $s-tooltip__icon-size;
    }
}
