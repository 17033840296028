$filter__label-height           : 50px;
$filter__border-color           : $gray-lightest;
$filter-radio__icon-size        : 30px;
$filter-radio__icon-before-size : 16px;

.filter {
    &__content {
        display: none;
    }

    &__heading {
        position: relative;
        height: 52px;
        border: 1px solid $filter__border-color;
    }
    &__label {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        height: $filter__label-height;
        width: 100%;
        background: none;
        border: 0;
        cursor: pointer;
    }
    &__search {
        @extend .input;
        height: 100%;
        margin-bottom: 0;
    }
    &__search-field {
        @extend .input__field;
        height: $filter__label-height;
        border: 0;
        color: transparent;
    }
    &__search-button {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 11;
        height: $filter__label-height;
        background: $white;

        .icon {
            fill: $blue;
        }
    }

    &__search-label {
        position: absolute;
        top: 50%;
        left: $spacer--medium;
        display: flex;
        flex-direction: row;
        align-items: center;
        transform: translateY(-50%) scale(1);
        transform-origin: 0 0;
        transition: transform 150ms;
        font-weight: 300;
        font-size: 15px;
        text-transform: inherit;
    }

    &__arrow {
        width: 10px;
        height: 10px;
        margin-left: $spacer--medium;
        transition: transform 300ms;
    }

    &--checkbox {
        display: flex;
        align-items: center;
        height: $filter__label-height;
        margin: 0;

        &:focus,
        &:hover {
            text-decoration: none;
        }

        .checkbox__label {
            margin: 0;
            padding-left: 60px;

            &:before {
                top: 50%;
                left: 20px;
                transform: translateY(-50%);
            }
        }

        .checkbox__icon {
            top: 50%;
            left: 25px;
            transform: translateY(-50%);
        }
    }

    &--range,
    &--checkbox,
    &--radio {
        border: 1px solid $filter__border-color;
        border-top-width: 0;
    }

    &--radio {
        .radio {
            &__label {
                height: $filter-radio__icon-size;
                line-height: $filter-radio__icon-size;
                color: $gray-dark;
                font-size: $font-size-base;
                font-weight: $font-weight-thin;
                text-transform: none;
                margin-bottom: 0;
            }

            &__fieldset {
                padding: $spacer--medium 0;
            }

            &__field {
                &:checked {
                    + .radio__label {
                        color: $gray-dark;
                    }
                }
            }

            &__handler {
                padding: 0 $spacer--extra-large;

                &:not(:last-of-type) {
                    padding-bottom: $spacer--medium;
                    margin-bottom: $spacer--medium;
                    border-bottom: 1px solid $filter__border-color;
                }
            }

            &__icon {
                border: 1px solid $border-color-base;
                border-radius: $filter-radio__icon-size;
                width: $filter-radio__icon-size;
                height: $filter-radio__icon-size;

                &::before {
                    width: $filter-radio__icon-before-size;
                    height: $filter-radio__icon-before-size;
                    border-radius: $filter-radio__icon-before-size;
                }
            }
        }
    }

    &--active {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 35px;
        margin: $spacer--medium 0;
        padding: $spacer--medium;
        background: $filter__border-color;
        font-size: $font-size-small;

        .icon {
            width: 10px;
        }

        button {
            border: none;
            background: transparent;
            cursor: pointer;
        }
    }

    &--is-Open {
        & > .filter__content {
            display: block;
        }

        & > .filter__heading {
            .filter__arrow {
                transform: rotate(-180deg);
            }
        }
    }

    &--is-Searchable {
        .filter__label {
            display: none;
        }

        .filter__search {
            .filter__search-field {
                padding-top: $spacer--medium;
                color: $font-color-base;
            }

            .filter__search-label {
                font-weight: $font-weight-bold;
                color: $font-color-base;
                text-transform: uppercase;
                transform: scale(0.66) translateY(-25px);
            }

            .filter__arrow {
                display: none;
            }
        }
    }

    &--rolled {
        & > .filter__heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: $spacer--medium;
            background: $filter__border-color;
            font-weight: $font-weight-bold;
            margin-bottom: $spacer--medium;

            .button {
                margin: 0;
            }

            .filter__label {
                position: static;
                width: $filter__label-height;
                height: $filter__label-height;
                background: $blue;
                .filter__arrow {
                    fill: $white;
                    margin-left: 0;
                }
            }
        }
    }

    // Overwriting vue-scrollbar lib default styles
    &__scrollbar-wrap {
        max-height: 336px;
        overflow: auto !important; //sass-lint:disable-line no-important
    }
}
