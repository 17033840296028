.dashboard-table {
    @extend .table;

    margin: $spacer--medium 0;

    &__points {
        color: $loyalty-orange;
    }

    thead {
        th {
            &.dashboard-table__button {
                text-align: center;
            }
        }
    }

    tbody {
        td {
            &.dashboard-table__cell--isLink {
                cursor: pointer;
            }
            &.dashboard-table__button {
                padding: $spacer--large $spacer--medium;

                &:before {
                    content: none;
                }
                @include mq($screen-m) {
                    padding: 0;
                }
                .button {
                    width: 100%;

                    @include mq($screen-m) {
                        display: flex;
                        align-items: center;
                        width: auto;
                        margin: auto;
                        padding: 0;
                        background: none;
                        .button__text {
                            display: none;
                        }
                        .icon {
                            margin: 0;
                            fill: $table__button-fill;
                        }
                        &:focus,
                        &:hover {
                            .icon {
                                fill: $white;
                                @include mq($screen-m) {
                                    fill: $table__button-fill;
                                }
                            }
                        }
                    }
                }
            }
            &.dashboard-table__button-expand {
                order: 2;
                padding: 0;
                &:before {
                    content: none;
                }
                @include mq($screen-m) {
                    width: 44px;
                    padding: 0;
                    position: relative;
                }
                .button {
                    width: 100%;
                    @include mq($screen-m) {
                        width: 44px;
                        height: 100%;
                        position: relative;
                        top: 0;
                        bottom: 0;
                        min-width: auto;
                    }
                    &.open {
                        .icon {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
            &.dashboard-table__badges-wrapper {
                order: 1;
                &:before {
                    content: none;
                }
                .dashboard-table__badges {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start;
                    margin: $spacer--medium 0;
                    @include mq($screen-m) {
                        order: 0;
                        flex-flow: column nowrap;
                        justify-content: center;
                        align-items: flex-start;
                        margin: 0;
                    }
                    .badge {
                        margin: 0 $spacer;
                        &:first-child {
                            margin: 0 $spacer 0 0;
                        }
                        @include mq($screen-m) {
                            margin: $spacer 0;
                        }
                    }
                }
            }
            &.dashboard-table__cell--goto {
                display: none;
                @include mq($screen-m) {
                    display: table-cell;
                    width: 44px;
                    padding-right: 0;

                    .button {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            &.dashboard-table__creditmemo-label {
                height: 45px;
                align-items: center;
                justify-content: inherit;
                background: #f9f9f9;
                font-size: 18px;

                &:before {
                    content: none;
                }

                @include mq($screen-m) {
                    height: 60px;
                    font-size: 15px;
                }
            }
        }
    }
}
