.notification-product {
    display: flex;
    flex-direction: row !important; // sass-lint:disable-line no-important
    align-items: stretch;
    max-width: 236px;
    &__body {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        padding: $spacer--medium;
        background-color: $gray-lightest;
    }
    &__name {
        flex-grow: 1;
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
        padding: $spacer--medium 0;
    }
    &__description {
        flex-grow: 1;
        font-size: $font-size-base;
        color: $gray-dark;
        line-height: 1.5;
        max-height: 200px;
        overflow-y: auto;
    }
    &__points,
    &__qty {
        display: flex;
        justify-content: space-between;
        padding-top: $spacer--medium;
        margin-top: $spacer--medium;
        border-top: $border-base;
    }
    &__qty {
        align-items: center;
        border-top: none;
        padding-top: 0;
    }
    &__qty-input {
        padding: 0 $spacer;
        input {
            padding: $spacer--medium $spacer;
            width: 54px;
            height: 44px;
            text-align: center;
        }
    }
    &__points-value {
        font-weight: $font-weight-bold;
        color: $loyalty-orange;
    }
    &__button {
        width: 100%;
        margin-top: $spacer--medium;
        background-color: $loyalty-orange;
        color: $white;
        border-radius: $spacer;

        &[disabled] {
            font-size: 10px;
            background-color: $white;
            color: $loyalty-orange;
        }

        &:hover,
        &:focus {
            background-color: $white;
            color: $loyalty-orange;
        }
    }
}

.notification-product-modal {
    padding: 30px 20px 15px;
    &__heading {
        text-align: center;
        margin-bottom: 15px;
    }
    &__checkbox {
        padding: 15px 0;
        .base-checkbox__label {
            margin-bottom: 0;
        }
        .base-checkbox__icon {
            left: 7px;
        }
    }
    &__paragraph {
        color: $gray-dark;
    }
    &__button {
        width: 100%;
        background-color: $loyalty-orange;

        &:hover,
        &:focus {
            color: $loyalty-orange;
        }
    }
}
