.shopping-lists-list {
    &__top {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        @include mq($screen-m) {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            border-bottom: solid 1px rgba(0, 0, 0, 0.09);
            padding: 0 0 $spacer--large 0;
        }
    }
    &__heading {
        color: $font-color-base;
        background-color: $white;
        margin: $spacer--large 0 0 0;
        font-size: 20px;
        @include mq($screen-m) {
            width: 40%;
            order: 1;
            font-size: 23px;
        }
    }
    &__create-button {
        width: 100%;
        margin: 0 0 $spacer--large 0;
        @include mq($screen-m) {
            flex-basis: 20%;
            width: auto;
            min-width: 200px;
            margin: 0 $spacer--large 0 0;
            order: 2;
        }
    }

    &__table {
        position: relative;
    }

    .search-bar {
        width: 100%;
        padding: $spacer--large 0 $spacer--large;
        margin: 0;
        @include mq($screen-m) {
            max-width: 300px;
            float: right;
        }
    }
    .document-filters {
        padding: $spacer--large 0;
        margin-bottom: $spacer--large;
        border-bottom: 1px solid $border-color-base;
    }
}
