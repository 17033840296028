$cookie-message__width                  : 100% !default;
$cookie-message__background-color       : $gray-lightest !default;
$cookie-message__font-size              : $font-size-small !default;
$cookie-message__font-family            : $font-family-base !default;
$cookie-message__text-color             : $gray !default;
$cookie-message__text-padding           : $spacer--medium $spacer--medium 0 $spacer--medium !default;
$cookie-message__text-padding--small    : $spacer--medium $spacer $spacer--medium $spacer--medium !default;
$cookie-message__link-color             : $blue !default;
$cookie-message__close-color            : $gray !default;
$cookie-message__close-size             : 48px !default;
$cookie-message__close-font-weight      : $font-weight-bold !default;
$cookie-message__close-padding          : 0 $spacer--medium 0 0 !default;
$cookie-message__close-padding--small   : 0 $spacer--medium 0 $spacer default;
$cookie-message__close-icon-fill        : $gray !default;
$cookie-message__close-icon-size        : 14px !default;
$cookie-message__close-icon-margin-left : $spacer;

.cookie-message {
    display: none;
    align-items: stretch;
    width: $cookie-message__width;
    background-color: $cookie-message__background-color;
    box-sizing: border-box;
    font-size: $cookie-message__font-size;
    font-family: $cookie-message__font-family;

    &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        min-width: 100%;
        text-align: center;
        @include mq($screen-s) {
            flex-wrap: nowrap;
            justify-content: space-between;
        }
    }

    &__text {
        flex-basis: 100%;
        color: $cookie-message__text-color;
        text-align: justify;
        padding: $cookie-message__text-padding;
        font-size: inherit;
        font-family: inherit;

        @include mq($screen-s) {
            text-align: left;
            flex-basis: auto;
            padding: $cookie-message__text-padding--small;
        }
    }

    &__link {
        color: $cookie-message__link-color;
    }

    &__close {
        display: flex;
        align-items: center;
        flex-basis: auto;
        flex-shrink: 0;
        min-width: $cookie_message__close-size;
        height: $cookie-message__close-size;
        padding: $cookie-message__close-padding;
        background: none;
        border: none;
        color: $cookie-message__close-color;
        cursor: pointer;
        font-weight: $cookie-message__close-font-weight;
        font-family: inherit;
        font-size: inherit;

        @include mq($screen-s) {
            padding: $cookie-message__close-padding--small;
        }
    }

    &__close-icon {
        fill: $cookie-message__close-icon-fill;
        width: $cookie-message__close-icon-size;
        height: $cookie-message__close-icon-size;
        margin-left: $cookie-message__close-icon-margin-left;
    }

    &--open {
        display: flex;
    }
}

.cookie-message__text > a {
    @extend .cookie-message__link;
}
