.returns-add {
    &__search-label {
        display: block;
        color: $gray;
        margin-bottom: $spacer;
        font-weight: $font-weight-thin;
    }
    &__separator {
        margin-top: $spacer--extra-large;
        border-top: 1px solid $gray-lightest;
    }
    &__list-label {
        padding: $spacer--medium $spacer--large;
        margin: 0;
        @include mq($screen-m) {
            padding: $spacer--medium;
        }
    }
    &__product-list {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    &__bottom {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        margin-top: 30px;
    }
    &__button-back {
        order: 2;
        @include mq($screen-m) {
            order: initial;
        }
    }
    &__button-submit {
        order: 1;
        width: 100%;
        @include mq($screen-m) {
            order: initial;
            width: 295px;
        }
    }
    .alert-user-message__heading {
        text-align: center;
    }
    .modal--narrow .modal__content {
        max-width: 410px;
    }
}
