.contact {
    max-width: $max-content-width;
    margin: $spacer--large auto;

    @include mq($screen-m) {
        display: flex;
        flex-flow: row wrap;
        padding: 0 0 0 $spacer--medium;
        justify-content: space-between;
    }

    &__main {
        padding: 0 $spacer--medium;
        @include mq($screen-m) {
            width: 50%;
            padding: 0;
        }
        @include mq($screen-l) {
            width: auto;
        }
    }

    &__address-wrap {
        margin-bottom: 30px;
        color: $gray-dark;
        font-weight: $font-weight-thin;
        font-style: normal;
    }

    &__perso-list {
        margin: 15px 0 30px;
    }

    &__perso {
        margin-bottom: 10px;
    }

    &__perso-item {
        display: block;
        font-weight: bold;
        margin-bottom: 10px;

        &:last-child {
            font-weight: $font-weight-thin;
        }
    }

    &__select {
        max-width: 400px;
        display: none;

        .label {
            text-transform: none;
        }
    }

    &__map {
        width: 100%;
        @include mq($screen-m) {
            width: 50%;
        }
        @include mq($screen-l) {
            width: auto;
            min-width: 500px;
        }
        @include mq($screen-xl) {
            width: auto;
            min-width: 680px;
        }
    }

    &__details {
        width: 100%;
        margin: $spacer--large 0;
    }

    &__table {
        margin-top: $spacer--medium;

        tbody {
            td.contact__table-td-button {
                &:before {
                    content: none;
                }
            }
        }
    }

    &__table-button {
        @include mq($screen-xl) {
            height: 40px;
            line-height: 40px;
        }
    }
}
