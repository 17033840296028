.product-view {
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 0 -8px 30px -8px;

        @include mq($screen-l) {
            flex-wrap: nowrap;
            margin: $spacer--medium 0 50px 0;
        }
    }

    &__column {
        width: 100%;
        @include mq($screen-l) {
            width: 33.33%;
            margin: 0 $spacer--extra-large;
        }
        &:first-child {
            @include mq($screen-l) {
                margin: 0 $spacer--medium 0 0;
            }
        }
        &:last-child {
            @include mq($screen-l) {
                margin: 0 0 0 $spacer--medium;
            }
        }

        &--pricing {
            position: relative;
            background-color: $gray-lightest;
            margin: $spacer--medium 0 $spacer--extra-large;
            padding: 36px 0 $spacer--extra-large;
        }
        &--gallery {
            overflow: hidden;
        }
    }

    &__catalog-numbers-list {
        width: 100%;
        border-bottom: 1px solid $gray-lighter;
        padding: $spacer--large $spacer--medium;
        @include mq($screen-l) {
            padding: $spacer--large 0;
        }
        .list__label {
            padding-top: $spacer--medium;
        }
        .list__value {
            padding-bottom: $spacer--medium;
            font-weight: $font-weight-base;
            color: $gray-dark;
        }
        .list__value,
        .list__label {
            width: 100%;
            @include mq($screen-m) {
                width: auto;
                line-height: 30px;
                padding: 0;
            }
        }
    }

    &__status {
        position: relative;
        min-height: 68px;
        margin: 0 0 $spacer--large;
    }

    &__status-list {
        padding: $spacer--large $spacer--medium;
        @include mq($screen-l) {
            padding: $spacer--large 0;
            border-bottom: 1px solid $gray-lighter;
        }
        .list__value,
        .list__label {
            line-height: 30px;
            flex: none;

            &--loader {
                position: relative;
                margin-right: $spacer--extra-large;
            }
        }
        .list__value {
            font-weight: $font-weight-thin;
            color: $gray;
        }
    }

    &__dropdown-list {
        @extend .dropdown-list;

        @include mq($screen-l) {
            margin-top: $spacer--large;
        }

        .dropdown-list__label {
            padding: $spacer--large 13px;
            background-color: $color-secondary;
            font-size: $font-size-base;
            font-weight: normal;
            color: $gray-lightest;
            letter-spacing: 0.75px;
            border-bottom: solid 1px rgba(0, 0, 0, 0.2);

            .dropdown-list__icon {
                fill: $white;
                width: 11px;
            }
        }
        .dropdown-list__content {
            padding: 0;
            border-top: none;
            background-color: $white;
        }

        .dropdown-list__wrapper {
            padding: $spacer--medium;
        }

        .dropdown-list__item--open {
            .dropdown-list__content {
                border: 1px solid $gray-lightest;
            }
        }
    }

    &__description {
        color: $gray;
        font-weight: $font-weight-thin;
    }

    &__description-list {
        .list__label,
        .list__value {
            line-height: 30px;
        }
        .list__value {
            font-weight: $font-weight-thin;
            color: $gray;
        }
    }

    &__tech-data-list {
        display: inline-block;

        @include mq($screen-xl) {
            width: auto;
            max-width: 512px;
            margin: 0 $spacer--extra-large 0 0;
        }

        .list--horizontal\@medium {
            padding: $spacer--medium;
            flex-flow: row wrap;
            @include mq($screen-m) {
                padding: 0;
            }
        }

        .list--description-columns {
            .list__item {
                padding: 0 $spacer--medium;
            }
            .list__item--pim_long-description {
                display: flex;
                flex-flow: row nowrap;
                align-items: flex-start;
                justify-content: space-between;

                .list__label {
                    line-height: 24px;
                    padding-top: $spacer;
                }
                .list__value {
                    flex: 1 1 auto;
                    line-height: 24px;
                    padding: $spacer 0 $spacer $spacer--large;
                }
            }
            .list__label,
            .list__value {
                line-height: 35px;
            }
            .list__value {
                flex: 1 0 50%;
                font-weight: $font-weight-thin;
            }
        }
    }

    &__bulk-packs-list {
        width: 100%;

        .list__label,
        .list__value {
            line-height: 35px;
        }

        .list__value {
            font-weight: $font-weight-thin;
            color: $gray;
        }
    }

    &__pricing {
        padding: 0 0 $spacer--extra-large;
        border-bottom: 1px solid $gray-lighter;

        .list__item {
            padding: 0 $spacer--medium;
            @include mq($screen-m) {
                padding: 0 $spacer--extra-large;
            }
        }

        .list__label,
        .list__value {
            line-height: 30px;
        }

        .list__label {
            display: flex;
            align-items: center;
            font-size: $font-size-base;
            font-weight: $font-weight-base;
            color: $gray-dark;
        }

        .list__value {
            font-weight: $font-weight-bold;
            color: $font-color-base;
        }
    }

    &__quantity-list-item.list__item {
        padding: 18px $spacer--medium 0;
        margin: $spacer--medium 0 $spacer;
        flex-wrap: wrap;
        @include mq($screen-m) {
            flex-wrap: nowrap;
            padding: 18px $spacer--extra-large 0;
        }
        .list__label {
            flex: 0 1 auto;
            white-space: nowrap;
        }
        .list__value {
            flex: 0 1 auto;
        }
        .quantity-update {
            flex: 1 1 100%;
            @include mq($screen-l) {
                flex: 1 1 auto;
            }
            &__label {
                color: $gray-dark;
                font-weight: $font-weight-base;
            }

            &__input {
                color: $font-color-base;
            }
        }
    }

    &__price {
        font-size: 27px;

        &--highlight {
            .price {
                font-size: 24px;
            }
        }

        &--underline {
            text-decoration: underline;
        }

        &--gray {
            .list__label,
            .list__value {
                color: #33333391; // sass-lint:disable-line no-invalid-hex
            }
        }
    }

    &__add-to-cart-button {
        position: relative;
        padding: 25px $spacer--medium 0 $spacer--medium;
        margin: 0 0 $spacer--medium;
        @include mq($screen-m) {
            padding: 25px $spacer--extra-large 0 $spacer--extra-large;
        }
    }

    &__action {
        position: relative;
        padding: 0 $spacer--extra-large;
    }

    &__action-button {
        position: relative;
        width: 100%;
        background-color: $white;
        color: $color-primary;

        &:hover,
        &:focus {
            background-color: $white;
        }
    }

    &__action-icon {
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 42px;
        height: 44px;

        .icon {
            width: 12px;
            height: 12px;
            fill: $color-primary;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: -1px;
            height: 80%;
            width: 1px;
            margin: auto;
            background-color: $color-primary;
        }
    }

    &__details {
        position: relative;
        flex-direction: column;
        margin-bottom: 25px;
        scroll-margin-top: 120px; // sass-lint:disable-line no-misspelled-properties

        @include mq($screen-l) {
            scroll-margin-top: 20px; // sass-lint:disable-line no-misspelled-properties
        }

        @include mq($screen-xl) {
            flex-direction: row;
        }

        .tab__wrapper {
            display: flex;
            flex-flow: row wrap;
            width: 100%;
            padding: 0 0 24px;
            gap: 8px; // sass-lint:disable-line no-misspelled-properties
            border-bottom: 1px solid #ededed;
        }

        .tab__title {
            flex: none;
            color: $gray-darker-secondary;
            background-color: $white;
            border-radius: 5px;
            border-color: #ededed;
            padding: $spacer--semi-large $spacer--large;

            &:hover,
            &:focus {
                color: $color-primary;
                background-color: $gray-lightest;
            }

            &:not(:first-of-type) {
                @include mq($screen-xl) {
                    margin: 0 $spacer;
                }
            }
        }

        .tab__content {
            display: flex;
            flex-direction: column;
            padding: $spacer--large 0;
            scroll-margin-top: 100px; // sass-lint:disable-line no-misspelled-properties
            border: none;

            @include mq($screen-l) {
                scroll-margin-top: 0; // sass-lint:disable-line no-misspelled-properties
            }
        }
        .catalog--grid {
            margin: 0;
            padding: 0;
        }
        .list--even-odd {
            .list__item {
                min-height: 56px;

                @include mq($screen-xl) {
                    min-width: 512px;
                }
            }

            & > .list__item {
                padding: 0 $spacer--large;
            }
        }
        .list--description-columns {
            flex-flow: column wrap;
            width: 100%;

            @include mq($screen-m) {
                column-gap: $spacer--extra-large;
            }

            @include mq($screen-xl) {
                width: auto;
            }
        }

        .products-recommendations {
            &__title {
                text-align: left;
                font-size: $font-size-extra-large;
            }
        }
    }

    &__express-wholesale-notice {
        font-size: $font-size-base;
        padding: $spacer--medium 0;
        color: $green;
        font-weight: $font-weight-bold;
        line-height: 2;
    }

    &__express-wholesale-list {
        .list__item {
            max-height: 56px;
        }

        @include mq($screen-xl) {
            max-height: 675px;
        }
    }

    &__icons {
        margin-top: 14px;
    }

    &__collective-packaging {
        display: inline-block;

        @include mq($screen-xl) {
            width: auto;
        }
    }

    &__benefits-list {
        padding: 0 $spacer--medium;
        margin: 0;
        color: $loyalty-gray-dark;

        @include mq($screen-l) {
            padding: 0;
        }
    }

    &__benefits-list-item {
        display: flex;
        align-items: center;
        padding: 0 0 $spacer--semi-large;
        list-style-type: none;
    }

    &__benefits-icon {
        display: flex;
        min-width: 25px;
        width: 25px;
        height: 25px;
        margin: 0 $spacer--semi-large 0 0;
    }

    &__benefits-text {
        b,
        strong {
            color: #038c3e;
        }
    }

    &__banner {
        display: flex;
        align-items: center;
        padding: $spacer--medium;
        background-color: $color-accent;
        border-radius: 5px;
        width: calc(100% - 20px);
        max-width: 438px;
        margin: $spacer--semi-large auto;

        @include mq($screen-l) {
            width: 100%;
            max-width: none;
        }
    }

    &__banner-icon {
        width: 84px;
    }

    &__banner-title {
        margin: 0;
        color: $white;
        font-size: $font-size-base;

        @include mq($screen-xl) {
            font-size: $font-size-extra-large;
        }
    }

    &__banner-text {
        margin: 0;
        color: $white;
    }

    &__banner-link {
        color: $white;
        font-size: $spacer--extra-large;

        &:hover,
        &:focus {
            color: $white;
            text-decoration: underline;
        }
    }

    &__cta {
        display: flex;
        flex-direction: column;
        padding: 0 $spacer--medium;

        @include mq($screen-l) {
            padding: 0;
        }
    }

    &__cta-wrapper {
        display: flex;
        justify-content: center;
        padding: 0 $spacer--extra-large;

        @include mq($screen-l) {
            justify-content: flex-start;
            padding: 0;
        }

        @include mq($screen-l) {
            padding: 0 $spacer--extra-large;
        }
    }

    &__cta-text-wrapper {
        width: 65%;
        max-width: 270px;

        @include mq($screen-l) {
            max-width: none;
        }
    }

    &__cta-icon {
        width: 48px;
        height: 56px;
        margin: $spacer 22px 0 0;
    }

    &__cta-heading {
        margin: 0;
        color: $color-primary;
        line-height: normal;

        @include mq($screen-l) {
            margin: 0 0 $spacer;
            font-size: 22px;
        }
    }

    &__cta-paragraph {
        padding: 0 0 $spacer--medium;
        color: $gray-darker-secondary;
    }

    &__cta-link {
        padding: $spacer--extra-large 0;
        font-weight: $font-weight-base;
        color: $gray-darker-secondary;

        &:before,
        &:after {
            width: calc(50% - 70px);
        }
    }

    &__scroll {
        position: fixed;
        z-index: 6;
        bottom: 48px;
        right: 28px;
        padding: 0;
        background: none;
        border: none;
        cursor: pointer;

        @include mq($screen-xl) {
            bottom: 48px;
            right: 84px;
        }
    }

    &__scroll-icon {
        width: 120px;
        height: 120px;
        border-radius: 120px;
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
    }

    .catalog-requisition-lists {
        width: calc(100% - (2 * #{$spacer--medium}));
        @include mq($screen-m) {
            width: calc(100% - (2 * #{$spacer--extra-large}));
        }
    }

    .article {
        figure {
            margin: 0 0 $spacer--extra-large;
        }
    }

    hr {
        margin: 0;
        border-width: 0 0 1px !important; // sass-lint:disable-line no-important
        border-color: rgba(0, 0, 0, 0.09) !important; // sass-lint:disable-line no-important
    }
}
