.alert-user-message {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: $spacer--extra-large;

    &--center {
        .alert-user-message__heading {
            text-align: center;
        }
        .alert-user-message__button-wrapper {
            justify-content: center;
        }
    }

    &--offer {
        flex-flow: row wrap;
        justify-content: center;
        .alert-user-message__heading {
            flex-basis: 100%;
            border-bottom: 1px solid $border-color-base;
            padding-bottom: 5px;
            text-align: left;
        }
        .alert-user-message__paragraph {
            flex-basis: 100%;
            padding-top: 10px;
            padding-bottom: 38px;
            text-align: left;
        }
        .alert-user-message__button-wrapper {
            flex-basis: 50%;
        }
        .button {
            flex-basis: 100%;
        }
    }

    &__icon {
        width: 70px;
        height: 70px;
        margin: 0 auto $spacer--extra-large;
    }

    &__heading {
        margin-bottom: inherit;
    }

    &__text {
        text-align: center;
        margin-bottom: $spacer--extra-large;
    }

    &__button-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include mq($screen-s) {
            flex-direction: row;
        }
    }

    &__button {
        &:first-child {
            margin: 0 0 $spacer 0;
        }

        @include mq($screen-s) {
            flex-basis: 50%;

            &:first-child {
                margin: 0 $spacer 0 0;
            }
        }
    }
}
