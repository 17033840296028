.loyalty-content {
    &--about {
        .loyalty-content__cms {
            border-bottom: $border-base;
            padding-bottom: 30px;
        }

        .loyalty-content__link {
            justify-content: flex-start;
            background: none;
            color: $blue;
            font-size: 12px;

            .icon {
                width: 13px;
                fill: $black;
            }
        }
    }

    &__cms {
        ul,
        p {
            color: $gray-dark;
        }

        a {
            font-weight: $font-weight-normal;
        }

        a.button {
            margin: $spacer--medium auto;
            width: 300px;
            font-weight: $font-weight-bold;
        }

        ul {
            @extend .list;
        }

        li {
            @extend .list__item;
        }

        img {
            display: inline-block;
        }

        .columns {
            column-count: 2;
            column-gap: $spacer--extra-large;
        }
    }

    @include mq($max-screen: $screen-m - 1) {
        .grid__column--1-2 {
            flex-basis: 100%;
            max-width: 100%;
        }
    }

    &__action {
        width: 300px;
        margin: $spacer--medium auto 66px;
    }

    &__card {
        padding: 40px 40px 25px;
        margin: $spacer--extra-large 0;
        border-radius: $spacer;
        background: #fff;
        box-shadow: 0 4px 36px 0 rgba(0, 0, 0, 0.1);
    }
}
