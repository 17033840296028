.cart-items-list {
    margin-bottom: $spacer;

    &__hint {
        margin: 0 0 0 $spacer;

        .hint__content {
            min-width: 210px;
            white-space: initial;
            font-size: $font-size-extra-small;
            line-height: 15px;
        }
    }

    tbody {
        tr {
            border-top: 10px solid $white;
            border-bottom: 0;

            &:first-child {
                border-top: none;
            }

            &.table__row--child-content {
                border-top: 0;
            }

            &.table__cell--child-content {
                display: none;
                border-top: none;

                td {
                    &:first-child {
                        &:before {
                            @include mq($screen-m) {
                                display: inline-block;
                                min-width: auto;
                                margin-right: $spacer;
                                padding-left: $spacer;
                            }
                        }
                    }

                    &:before {
                        display: block;
                        content: attr(data-th);
                        @include mq($screen-m) {
                            padding-bottom: $spacer;
                            font-size: 13px;
                        }
                    }
                    .badges {
                        display: flex;
                        flex-flow: column nowrap;
                        width: auto;
                        align-items: flex-start;
                    }
                    .badge {
                        margin-bottom: 4px;
                        @include mq($screen-m) {
                            &:first-child {
                                margin-top: $spacer;
                            }
                            &:last-child {
                                margin-bottom: 10px;
                            }
                        }
                    }
                }

                &.open {
                    display: table-row;
                }

                @include mq($screen-m) {
                    border-top: 1px solid $gray-light;
                    padding: $spacer;
                }
            }
        }
        td {
            &.cart-items-list__qty-input,
            &.dashboard-table__cell--qty-input {

                .input__container {
                    display: flex;
                }

                .input__text {
                    align-self: center;
                }

                .input-qty-wrap {
                    display: flex;

                    &__unit {
                        text-transform: lowercase;
                        align-self: center;
                    }
                }

                .input {
                    @extend .catalog-item__qty-input;
                    display: flex;
                    justify-content: flex-end;

                    @include mq($screen-m) {
                        justify-content: flex-start;
                        width: auto;
                        margin-bottom: 0;
                    }

                    .input__field {
                        font-weight: $font-weight-bold;
                        margin-right: $spacer--medium;
                    }
                }
            }
            &.table__cell--child-content {
                padding: $spacer--large;
                background-color: $gray-lightest;
                @include mq($screen-m) {
                    border-top: 1px solid $border-color-base;
                }

                .list {
                    width: 100%;
                    @include mq($screen-m) {
                        justify-content: flex-start;
                        align-items: flex-start;
                    }
                    &__item {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: space-between;
                        @include mq($screen-m) {
                            line-height: 1.5;
                            flex-flow: column nowrap;
                            width: 15%;
                            padding: $spacer--medium $spacer;
                            &:first-child {
                                flex-flow: row wrap;
                            }
                            &:last-child {
                                flex-grow: 1;
                            }
                        }
                        @include mq($screen-l) {
                            width: 11%;
                        }
                    }
                    .cart-items-list__inner-label {
                        font-weight: $font-weight-thin;
                        padding-right: $spacer--medium;
                        @include mq($screen-m) {
                            font-size: $font-size-extra-small;
                        }
                    }
                    .cart-items-list__item {
                        &--sku,
                        &--matnr {
                            @include mq($screen-m) {
                                width: 30%;
                            }
                        }
                        &--badges {
                            justify-content: flex-end;
                            @include mq($screen-m) {
                                width: 15%;
                                flex-flow: row wrap;
                                justify-content: flex-end;
                                margin-right: 2%;
                            }
                            @include mq($screen-l) {
                                margin-right: 13%;
                            }
                        }
                    }
                }
            }
        }
    }
}
