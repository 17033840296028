$dashboard-tab__content-padding: $spacer--large $spacer--medium;
$breakpoint: $screen-m;
.dashboard-tab {
    justify-content: flex-end;
    &__title.tab__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 0 1 100%;
        order: 0;
        padding: $spacer--large $spacer--extra-large;
        color: $white;
        background-color: $color-primary;
        border: none;
        border-bottom: solid 1px rgba(0, 0, 0, 0.2);
        font-size: 12px;
        font-weight: $font-weight-bold;
        text-align: left;
        text-transform: uppercase;

        @include mq($breakpoint) {
            display: flex;
            flex: 0 1 auto;
            align-items: center;
            background-color: $white;
            border: solid 1px transparent;
            color: $color-primary;
        }

        &--active {
            @include mq($breakpoint) {
                color: $color-primary;
                border: 1px solid $gray-lightest;
                border-bottom: 1px solid $white;
            }

            .tab__icon {
                transform: rotateX(180deg);
            }
        }
    }

    &__icon.tab__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15px;
        svg {
            fill: $white;

            @include mq($breakpoint) {
                max-width: 7px;
                max-height: 7px;
                fill: $white;
            }
        }

        @include mq($breakpoint) {
            position: relative;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 12px;
            height: 12px;
            padding: 0;
            background-color: $color-primary;
            margin-left: 7px;
        }
    }

    &__content.tab__content {
        position: relative;
        order: 0;
        padding: $dashboard-tab__content-padding;
        top: 0;

        @include mq($breakpoint) {
            top: -2px;
            order: 1;
            padding: $dashboard-tab__content-padding;
        }

        &--active {
            border-top: solid 1px $gray-lightest;
            border-bottom: solid 1px $gray-lightest;
        }
    }
}
