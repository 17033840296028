
.view-switcher {
    display: flex;
    flex-flow: row nowrap;

    &__wrap {
        display: flex;
        flex-flow: row nowrap;
    }

    &__button {
        margin: 0;

        .icon {
            width: 16px;
            height: 16px;
        }

        &:focus,
        &:hover,
        &--active {
            background-color: $color-primary--active;
            border-color: $color-primary--active;
        }

        &--active {
            &:not(.view-switcher__button--nophoto) {
                display: none;
                @include mq($screen-m) {
                    display: block;
                }
            }
        }
    }
}
