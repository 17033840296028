@use "sass:math";

$progress-bar__size             : 150px;
$progress-bar__step             : 10;
$progress-bar__border-width     : 5px;
$progress-bar__border-color     : $blue;
$progress-bar__outline          : $gray-lightest;
$progress-bar__animation-time   : 0.5s;

.progress-bar {
    position: relative;
    width: $progress-bar__size;
    height: $progress-bar__size;
    line-height: $progress-bar__size;
    margin: 0 auto;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: $progress-bar__border-width solid $progress-bar__outline;
    }
    &__line {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: none;
        border-width: $progress-bar__border-width;
        border-style: solid;
        border-color: $progress-bar__border-color;
    }
    &__line-container {
        position: absolute;
        top: 0;
        width: 50%;
        height: 100%;
        overflow: hidden;
        z-index: 1;

        &--left {
            left: 0;
            .progress-bar__line {
                left: 100%;
                border-top-right-radius: ($progress-bar__size * 0.5);
                border-bottom-right-radius: ($progress-bar__size * 0.5);
                border-left: 0;
                transform-origin: center left;
            }
        }
        &--right {
            right: 0;
            .progress-bar__line {
                left: -100%;
                border-top-left-radius: ($progress-bar__size * 0.5);
                border-bottom-left-radius: ($progress-bar__size * 0.5);
                border-right: 0;
                transform-origin: center right;
            }
        }
    }

    &__value {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 50%;
        font-size: 36px;
        line-height: 20px;
        height: 100%;
        &--budget {
            font-size: 22px;
        }
        &--limit {
            font-size: $font-size-base;
            font-weight: $font-weight-thin;
            color: $gray-dark;
        }
    }
}

@for $i from 1 through $progress-bar__step {
    $stepName: ($i * math.div(100, $progress-bar__step));

    @if $i <= ($progress-bar__step * 0.5) {
        .progress-bar[data-percentage="#{$stepName}"] {
            .progress-bar__line-container--right .progress-bar__line {
                animation: loading-#{$i} $progress-bar__animation-time linear forwards;
            }
            .progress-bar__line-container--left .progress-bar__line {
                animation: 0;
            }
        }
    }

    @if $i > ($progress-bar__step * 0.5)  {
        .progress-bar[data-percentage="#{$stepName}"] {
            .progress-bar__line-container--right .progress-bar__line {
                animation: loading-#{($progress-bar__step* 0.5)} $progress-bar__animation-time linear forwards;
            }
            .progress-bar__line-container--left .progress-bar__line {
                animation: loading-#{$i - ($progress-bar__step * 0.5)} $progress-bar__animation-time linear forwards $progress-bar__animation-time;
            }
        }
    }
}

@for $i from 1 through ($progress-bar__step * 0.5) {
    $degrees: math.div(180, ($progress-bar__step * 0.5));
    $degrees: ($degrees * $i);
    @keyframes loading-#{$i} {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(#{$degrees}deg);
        }
    }
}
