.table {
    th,
    td {
        display: table-cell;

        &:first-child {
            padding-left: $spacer--large;
        }

        &:last-child {
            padding-right: $spacer--large;
        }
    }

    tr {
        display: table-row;
        -webkit-print-color-adjust: exact; // sass-lint:disable-line no-vendor-prefixes
        print-color-adjust: exact; // sass-lint:disable-line no-misspelled-properties
        padding: 0;
    }

    thead {
        display: table-header-group;
    }

    tbody {
        td {
            display: table-cell;
            height: 68px;
            padding: 0 $spacer;
            text-align: left;

            &:before {
                display: none;
            }
        }
    }

    tfoot {
        display: table-footer-group;

        td {
            display: table-cell;
            padding: $spacer--medium;
        }
    }
}
