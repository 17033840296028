@use "sass:math";

@mixin item-order($elements: 15, $elements-per-row: 2) {
    $rows: ceil(math.div($elements, $elements-per-row));
    $middle-row: floor($rows * 0.5);

    order: calc((#{$middle-row} * #{$elements-per-row}) + 1);
};

@mixin item-position($elements: 15) {
    @include item-order($elements, 2);

    @include mq($screen-m) {
        @include item-order($elements, 3);
    }

    @include mq($screen-l) {
        @include item-order($elements, 4);
  }
};
