$password-strength__font-size               : $font-size-small !default;
$password-strength__color                   : $gray !default;

$password-strength__indicator-margin        : 0 0 0 $spacer !default;
$password-strength__indicator-padding       : 2px $spacer--extra-large !default;
$password-strength__indicator-border        : 1px solid $gray !default;
$password-strength__indicator-border-radius : 32px !default;

$password-strength__indicator-background             : $gray-lighter !default;
$password-strength__indicator-background--none       : $gray-lighter !default;
$password-strength__indicator-background--weak       : $red !default;
$password-strength__indicator-background--medium     : $yellow !default;
$password-strength__indicator-background--strong     : rgba($green, 0.75) !default;
$password-strength__indicator-background--very-strong: $green !default;

.password-strength {
    color: $password-strength__color;
    font-size: $password-strength__font-size;
    &__indicator {
        position: relative;
        display: inline-block;
        margin: $password-strength__indicator-margin;
        padding: $password-strength__indicator-padding;
        border: $password-strength__indicator-border;
        border-radius: $password-strength__indicator-border-radius;
        background-color: transparent;
        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            z-index: -1;
            height: 100%;
            border-top-left-radius: $password-strength__indicator-border-radius;
            border-bottom-left-radius: $password-strength__indicator-border-radius;

            .password-none & {
                width: 100%;
                border-top-right-radius: $password-strength__indicator-border-radius;
                border-bottom-right-radius: $password-strength__indicator-border-radius;
                background-color: $password-strength__indicator-background--none;
            }

            .password-weak & {
                width: 25%;
                background-color: $password-strength__indicator-background--weak;
            }

            .password-medium & {
                width: 50%;
                background-color: $password-strength__indicator-background--medium;
            }

            .password-strong & {
                width: 75%;
                background-color: $password-strength__indicator-background--strong;
            }

            .password-very-strong & {
                width: 100%;
                border-top-right-radius: $password-strength__indicator-border-radius;
                border-bottom-right-radius: $password-strength__indicator-border-radius;
                background-color: $password-strength__indicator-background--very-strong;
            }
        }
    }
}
