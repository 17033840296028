$dropdown-list__width                    : 100%;
$dropdown-list__bg-color                 : $gray-lightest;
$dropdown-list__border-radius            : 0;
$dropdown-list__outline                  : none;
$dropdown-list__font-size                : 16px;
$dropdown-list__transition-height        : height 0.3s;
$dropdown-list__transition               : $transition-base;
$dropdown-list__content-padding          : $spacer--medium;

// List item
$dropdown-list__item-padding             : $spacer--medium;
$dropdown-list__item-color               : $font-color-base;
$dropdown-list__item-color-hover         : $font-color-base;
$dropdown-list__item-color--open         : $font-color-base;
$dropdown-list__item-bg-color            : $gray-lightest;
$dropdown-list__item-bg-color--open      : $gray-lightest;
$dropdown-list__item-bg-color-hover      : $gray-lightest;
$dropdown-list__item-bg-color--level2    : $gray-dark;
$dropdown-list__label-padding            : $spacer--large;

// List icon
$dropdown-list__icon-width               : 16px;
$dropdown-list__icon-height              : 16px;
$dropdown-list__icon-fill                : $gray-darker;
$dropdown-list__icon-fill-hover          : $gray-darker;
$dropdown-list__icon-fill--open          : $gray-darker;

// Secondary list variant
$dropdown-list__fill--secondary           : $gray !default;
$dropdown-list__font-weight--secondary    : $font-weight-bold !default;
$dropdown-list__bg-color--secondary       : $dropdown-list__bg-color !default;
$dropdown-list__item-color--secondary     : $dropdown-list__item-color !default;
$dropdown-list__icon-size---secondary     : 24px !default;
$dropdown-list__icon-padding--secondary   : 0 5px !default;
$dropdown-list__icon-margin--secondary    : 0 0 0 5px !default;
$dropdown-list__icon-fill---secondary-open: $color-primary !default;
$dropdown-list__content-margin--secondary : 0 !default;

// Dark list variant
$dropdown-list__bg-color--dark           : $gray-darker;
$dropdown-list__list-padding--dark       : $spacer--medium;
$dropdown-list__item-border-color--dark  : $gray-darker;
$dropdown-list__icon-fill--dark          : $white;
$dropdown-list__item-color--dark         : $white;
$dropdown-list__item-color-hover--dark   : $white;
$dropdown-list__item-bg-color-hover--dark: $gray-darker;

// Nested list variables
$dropdown-list__inner-item-bg-color      : $gray-dark;
$dropdown-list__inner-item-color         : $white;
$dropdown-list__icon-fill--inner         : $white;
$dropdown-list__inner-item-border        : 1px solid $white;
$dropdown-list__inner-item-border--level2: 1px solid $gray-dark;

.dropdown-list {
    width: $dropdown-list__width;
    position: relative;
    background-color: $dropdown-list__bg-color;

    &--secondary {

        .dropdown-list__item,
        .dropdown-list__item--open {

            .dropdown-list__label {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: row;
                text-transform: uppercase;
                font-size: $dropdown-list__font-size;
                font-weight: $dropdown-list__font-weight--secondary;
                background-color: $dropdown-list__bg-color--secondary;
                color: $dropdown-list__item-color--secondary;

                .dropdown-list__icon {
                    position: relative;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: $dropdown-list__icon-size---secondary;
                    height: $dropdown-list__icon-size---secondary;
                    padding: $dropdown-list__icon-padding--secondary;
                    margin: $dropdown-list__icon-margin--secondary;
                }
            }
        }

        .dropdown-list__item {
            fill: $dropdown-list__fill--secondary;
            &:hover,
            &:focus {
                fill: $dropdown-list__fill--secondary;
                .dropdown-list__icon {
                    fill: $dropdown-list__fill--secondary;
                }
            }

        }

        .dropdown-list__item--open {
            fill: $dropdown-list__icon-fill---secondary-open;

            &:hover,
            &:focus {
                .dropdown-list__icon {
                    fill: $dropdown-list__icon-fill---secondary-open;
                }
            }
        }

        .dropdown-list__content {
            margin: $dropdown-list__content-margin--secondary;
        }
    }

    &--dark {
        background-color: $dropdown-list__bg-color--dark;
        padding: 0 $dropdown-list__list-padding--dark;

        .dropdown-list__item {
            border-bottom: 2px solid $dropdown-list__item-border-color--dark;
        }

        .dropdown-list__item,
        .dropdown-list__item--open {
            .dropdown-list__label {
                background-color: $dropdown-list__bg-color--dark;
                color: $dropdown-list__item-color--dark;
                font-weight: $font-weight-bold;

                &:hover,
                &:focus {
                    color: $dropdown-list__item-color-hover--dark;
                    background-color: $dropdown-list__item-bg-color-hover--dark;

                    & .dropdown-list__icon {
                        fill: $dropdown-list__icon-fill--dark;
                    }
                }

                & .dropdown-list__icon {
                    fill: $dropdown-list__icon-fill--dark;
                }
            }
        }

        .dropdown-list__content {
            background-color: $dropdown-list__bg-color--dark;
        }
    }

    &--with-nested {
        .dropdown-list__item--homepage {
            display: flex;
            flex-flow: row nowrap;

            .icon {
                margin: 10px 0 10px 15px;
            }
        }

        .dropdown-list__item--manufacturer {
            .dropdown-list__inner-item--all {
                display: none;
            }
        }

        .dropdown-list__label {
            padding: 17px 15px;
            &.current {
                background-color: $gray-lightest;
            }
        }
    }

    &__list {
        display: block;
        width: $dropdown-list__width;
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    &__icon {
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        width: $dropdown-list__icon-width;
        height: $dropdown-list__icon-height;
        margin: auto;
        fill: $dropdown-list__icon-fill;
        transition: $dropdown-list__transition;

        &--inner {
            width: $dropdown-list__icon-width;
            height: $dropdown-list__icon-width;
            top: 0;
            bottom: 0;
            fill: $dropdown-list__icon-fill--inner;
            margin: auto 0;
        }
    }

    &__item {
        position: relative;
        display: block;
        width: 100%;
        padding: 0;
        font-size: $dropdown-list__font-size;
        cursor: pointer;

        &--open,
        &.open {
            & .dropdown-list__label {
                color: $dropdown-list__item-color--open;
                background-color: $dropdown-list__item-bg-color--open;

                & .dropdown-list__icon {
                    fill: $dropdown-list__icon-fill--open;
                    transform: rotate(180deg);
                }
            }

            .dropdown-list__content {
                height: auto;
            }
        }

        &--collapse {
            .dropdown-list__label {
                padding-right: 30px;
            }
        }
    }

    &__label {
        display: block;
        position: relative;
        width: 100%;
        color: $dropdown-list__item-color;
        background-color: $dropdown-list__item-bg-color;
        padding: $dropdown-list__label-padding;
        margin: 0;
        border: 0;
        border-radius: $dropdown-list__border-radius;
        text-decoration: none;
        text-align: left;
        cursor: pointer;
        transition: $dropdown-list__transition;

        &:hover,
        &:focus {
            color: $dropdown-list__item-color-hover;
            background-color: $dropdown-list__item-bg-color-hover;
            text-decoration: none;
            outline: $dropdown-list__outline;

            .dropdown-list__icon {
                fill: $dropdown-list__icon-fill-hover;
            }
        }
    }

    &__content {
        height: 0;
        overflow: hidden;
        padding: 0 $dropdown-list__content-padding;
        transition: $dropdown-list__transition-height;

        @include mq($max-screen: $screen-m) {
            padding: 0;
        }
    }

    &__content-wrapper {
        cursor: auto;
    }

    // Nested lists styles
    &__inner-list {
        list-style-type: none;
        display: block;
        height: 0;
        padding: 0;
        margin: 0;
        overflow: hidden;
        transition: $dropdown-list__transition;
        font-size: $dropdown-list__font-size;
    }

    &__inner-item {
        position: relative;
        display: block;
        padding: 0;
        background-color: $dropdown-list__inner-item-bg-color;
        border-bottom: $dropdown-list__inner-item-border;

        &.open {
            .dropdown-list__icon--inner {
                transform: rotate(180deg);
            }
        }

        &--level2 {
            background-color: $dropdown-list__item-bg-color--level2;
            border-bottom: $dropdown-list__inner-item-border--level2;
            .dropdown-list__inner-label {
                color: $dropdown-list__inner-item-color;
                padding-left: $dropdown-list__item-padding * 2;
            }
        }
    }

    &__inner-label {
        display: block;
        position: relative;
        text-decoration: none;
        padding: $dropdown-list__item-padding;
        color: $dropdown-list__inner-item-color;

        &:hover,
        &:focus {
            color: $dropdown-list__inner-item-color;
            text-decoration: none;
        }
    }

    @include mq($screen-m) {
        // from $screen-m drpdown list displays inline and with opened list
        &\@screen-m {
            .dropdown-list__list {
                display: flex;
                flex-flow: row nowrap;
            }

            .dropdown-list__item {
                width: 33.33%;
                cursor: default;
            }

            .dropdown-list__label {
                cursor: default;
                &:hover,
                &:focus {
                    color: $dropdown-list__item-color;
                    background-color: $dropdown-list__item-bg-color;
                }
            }
            .dropdown-list__icon {
                display: none;
            }
            .dropdown-list__content {
                height: auto;
            }

            &.dropdown-list--dark {
                .dropdown-list__label {
                    &:hover,
                    &:focus {
                        color: $dropdown-list__item-color-hover--dark;
                        background-color: $dropdown-list__item-bg-color-hover--dark;
                    }
                }
            }
        }
    }

    &--primary {
        background-color: white;

        .dropdown-list {
            &__item {
                margin-bottom: $spacer--medium;

                &--open {
                    .dropdown-list__expand-button {
                        .icon {
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            &__label {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                height: 50px;
                padding-left: $spacer--large;
                color: $gray-darker;
                background-color: $gray-lightest;
                font-weight: $font-weight-bold;
            }

            &__expand-button {
                position: absolute;
                top: 0;
                right: 0;
            }

            &__content-wrapper {
                padding-top: 25px;
            }
        }
    }
}
