$select__margin             : $spacer;
$select__padding            : 0 $spacer--large 0 $spacer--medium;
$select__border             : 1px solid $form-elements-border-color;
$select__height             : 50px;
$select__border-radius      : $form-elements-radius;
$select__field-background   : $white;
$select__input-border-color : $form-elements-border-color;

.select {
    margin-bottom: $select__margin;

    &--label-inside {
        .select__field-item {
            padding-right: 30px;
            overflow: hidden;
        }
    }

    &--required {
        .select__label {
            &:after {
                content: '*';
            }
        }
    }

    &__field {
        position: relative;
        background: $select__field-background;

        &--native {
            width: 100%;
            padding: $select__padding;
            border: $select__border;
            appearance: none;
        }
    }

    &__field-item {
        position: relative;
        height: $select__height;
        line-height: $select__height;
        padding: 0 30px 0 $spacer--large;
        cursor: pointer;
        font-size: $font-size-base;
        border: $select__border;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        .choices__list--dropdown & {
            border: none;
            border-bottom: $select__border;

            &:last-child {
                border-bottom: none;
            }
        }
    }

    &__field-input {
        width: 100%;
        height: $select__height;
        padding: 0 30px 0 $spacer--large;
        border: none;
        border-bottom: 1px solid $select__input-border-color;
        line-height: $select__height;
        cursor: pointer;
        font-size: $font-size-base;
        white-space: nowrap;
        &:focus {
            outline: none;
        }
    }

    &__label-inside {
        &:after {
            content: ':';
        }
    }
}
// Choices.js lib styles overwrites
.choices {
    &__item {
        border: $select__border;
    }

    &__list--dropdown {
        z-index: 100;

        .is-open & {
            border-radius: 0 0 $select__border-radius $select__border-radius;
            border-color: $select__input-border-color;
        }

        .choices__item--selectable {
            padding-right: $spacer--large;

            @media (min-width: 640px) {
                padding-right: 10px;
            }
        }
    }
    &[data-type*="select-one"] {
        &:after {
            width: 12px;
            height: 12px;
            top: 0;
            bottom: 0;
            margin: auto;
            border: 0;
            background-image: url('../images/icon-sprite.svg#arrow-view');
            background-position: center center;
            background-size: 12px 8px;
            background-repeat: no-repeat;
            transition: $transition-base;
        }
        &.is-open {
            &:after {
                margin-top: auto;
                border: 0;
                transform: rotate(180deg);
            }
        }
    }
    &__placeholder {
        opacity: 1;
        color: $gray;
    }
}

select {
    @extend .select__field-item;

    appearance: none;
    border: $select__border;
    border-radius: $form-elements-radius;
    width: 100%;
}

// Overwriting vue-multiselect lib default styles
.multiselect {
    color: $gray-dark;
    height: 50px;

    &__tags {
        border-radius: 0;
        min-height: $select__height;
        padding: 15px 40px 0 8px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        cursor: pointer;
    }

    &__select {
        height: $select__height;
        &::placeholder {
            color: $gray-dark;
        }
    }

    &__option {
        padding: 17px 12px;
        min-height: 50px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);

        &--highlight {
            background: $gray-lightest !important; // sass-lint:disable-line no-important
            color: $gray-dark !important; // sass-lint:disable-line no-important

            &:after {
                background: $gray-lightest;
                color: $gray-dark;
                line-height: 50px;
            }
        }

        &--selected {
            color: $gray-dark;
        }
    }

    &--error {
        .multiselect__tags {
            border: 1px solid $color-danger;
        }
    }
}
