.cms-page-view {
    &__heading {
        font-size: 32px;
        margin-bottom: $spacer--large;
        padding: 0 $spacer--medium;
        font-weight: $font-weight-bold;
        line-height: normal;

        &--center {
            text-align: center;
        }

        &--secondary {
            @include mq($screen-m) {
                font-size: 18px;
                line-height: 24px;
            }
        }
    }

    &__preheading {
        display: block;
        font-size: $font-size-small;
        line-height: 16px;
        color: $gray;
        margin-bottom: $spacer--medium;
    }

    &__list {
        color: $gray-darkest;
        font-family: $font-family-base;
        font-weight: $font-weight-normal;
        line-height: 24px;
        font-size: $font-size-base;
        margin: 0;

        li {
            margin: 0 0 $spacer--medium 0;
        }

        &--margin-large {
            margin-bottom: $spacer--large;

            li {
                margin: 0;
            }
        }

        &--no-margin {
            li {
                margin: 0;
            }
        }
    }

    &__width-50 {
        margin-bottom: $spacer--large;

        @include mq($screen-l) {
            width: 50%;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    &__columns {
        width: 100%;
        position: relative;

        @include mq($screen-l) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        &--2cols {
            .cms-page-view__column {
                flex-grow: 0;
                flex-shrink: 0;

                @include mq($screen-l) {
                    width: calc(50% - 8px);
                }

                @include mq($screen-xl) {
                    width: calc(50% - 16px);
                }

                &--1-4 {
                    @include mq($screen-l) {
                        width: calc(32% - 8px);
                    }

                    @include mq($screen-xl) {
                        width: calc(29% - 16px);
                    }

                    @include mq($max-content-width) {
                        width: calc(24% - 16px);
                    }
                }

                &--3-4 {
                    @include mq($screen-l) {
                        width: calc(68% - 8px);
                    }

                    @include mq($screen-xl) {
                        width: calc(71% - 16px);
                    }

                    @include mq($max-content-width) {
                        width: calc(76% - 16px);
                    }
                }

                &--tablet-1-3 {
                    @include mq($screen-m) {
                        width: calc(40% - 8px);
                    }

                    @include mq($screen-xl) {
                        width: calc(35% - 16px);
                    }
                }

                &--tablet-1-2 {
                    @include mq($screen-m) {
                        width: calc(50% - 8px);
                    }
                }
            }

            &.cms-page-view__columns--mobile {
                display: flex;
                justify-content: space-between;

                .cms-page-view__column {
                    width: calc(50% - 8px);
                }
            }
        }

        &--2cols-tablet {
            @include mq($screen-m) {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
        }

        &--3cols {
            @include mq($screen-m) {
                display: flex;
                flex-wrap: wrap;
            }

            .cms-page-view__column {
                @include mq($screen-m) {
                    flex: 0 0 50%;
                }

                @include mq($screen-l) {
                    flex-basis: 33.333%;
                }

                &--25 {
                    @include mq($screen-l) {
                        flex-basis: 25%;
                    }
                }

                &--35 {
                    @include mq($screen-l) {
                        flex-basis: 35%;
                    }
                }

                &--40 {
                    @include mq($screen-l) {
                        flex-basis: 40%;
                    }
                }
            }
        }

        &--3cols-desktop {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: $spacer--extra-large;

            @include mq($screen-m) {
                margin-bottom: 32px;
            }

            .cms-page-view__column {
                flex: 0 0 calc(50% - 8px);

                @include mq($screen-l) {
                    flex-basis: calc(33.333% - 10px);
                }
            }

            .cms-page-view__cell {
                margin-bottom: $spacer--medium;
            }
        }

        &--right {
            @include mq($screen-m) {
                justify-content: flex-end;
            }
        }
    }

    &__column {
        &--absolute {
            @include mq($screen-l) {
                position: absolute;
            }
        }

        &--to-right {
            @include mq($screen-l) {
                right: 0;
            }
        }

        &--to-left {
            @include mq($screen-l) {
                left: 0;
            }
        }
    }

    &__banner-wrapper {
        @include mq($screen-l) {
            flex-wrap: nowrap;
            gap: 40px; // sass-lint:disable-line no-misspelled-properties
        }
    }

    &__cell {
        margin-bottom: $spacer--large;

        &--alignright {
            text-align: right;
        }
    }

    &__cell-banner {
        margin: 0 $spacer--medium;

        @include mq($screen-l) {
            margin: 0;
        }
    }

    &__column-banner {
        @include mq($screen-l) {
            min-width: 330px;
        }

        @include mq($screen-xl) {
            min-width: 412px;
        }
    }

    &__list-banner {
        margin: 40px 0;

        .banner__container--single {
            .banner__descrption {
                @include mq($screen-m) {
                    margin: 0;
                }
            }

            .banner__title,
            .banner__descrption {
                @include mq($screen-xl) {
                    max-width: none;
                    margin: 0;
                }
            }

            @include mq($screen-l) {
                flex-direction: row;
                align-items: flex-end;
                min-height: auto;
            }

            @include mq($screen-xl) {
                align-items: center;
                margin: 0 48px;
            }
        }
    }

    &__column-description {
        .cms-page-view__cell {
            @include mq($screen-l) {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
            }
        }
    }

    &__sanpol-icon {
        width: 255px;
        margin: 0 auto;
        display: block;

        @include mq($screen-m) {
            margin-top: 40px;
        }

        &--big {
            margin-top: 40px;
            margin-bottom: 40px;
            width: 75%;
            max-width: 432px;
            height: auto;
            min-height: 37px;
            max-height: 67px;

            @include mq($screen-m) {
                margin-top: 80px;
                margin-bottom: 80px;
            }
        }
    }

    &__section {
        margin-bottom: 32px;
        padding: 0 $spacer--large;
        width: 100%;

        &--center {
            display: flex;
            justify-content: center;
            text-align: center;

            .button {
                font-size: $font-size-medium;
                color: $gray-darker-secondary;
                background-color: $gray-lightest;
                border-radius: 5px;

                @include mq($screen-m) {
                    width: 400px;
                }
            }
        }

        &--one-column {
            @include mq($screen-l) {
                width: 70%;
                margin: 0 auto;
            }
        }

        p {
            color: $gray-darker-secondary;
            font-size: $font-size-medium;
        }

        h2 {
            margin: $spacer--large 0 $spacer--extra-large;
        }
    }

    &__products {
        .catalog-grid,
        .catalog-grid-item,
        .catalog-grid-item__name {
            border: none;
        }

        .catalog-grid-item__manufacturer,
        .catalog-grid-item__price {
            font-size: 24px;
            color: $color-accent;
        }

        .catalog-grid-item__name {
            font-size: $font-size-medium;
            color: $gray-darker-secondary;
        }

        .catalog-grid-item {
            &__availability-info {
                font-size: $font-size-medium;
            }

            &__manufacturer {
                display: flex;
                margin: 0 0 $spacer--medium;
            }

            &__main {
                padding: $spacer;
            }

            &:hover:before,
            &:active:before {
                display: none;
            }

            .product-view__add-to-cart-btn {
                font-size: $font-size-medium;
                color: $white;
                background-color: #008ad0;
                border-radius: 5px;
            }
        }

        .availability-label {
            display: inline-flex;
            margin: 0 0 $spacer;

            &--green,
            &--blue {
                display: inline-flex;
                margin: 0 0 $spacer;
            }
        }
    }

    &__wrapper {
        padding: $spacer--medium;

        @include mq($screen-m) {
            padding: $spacer--extra-large $spacer--medium;
        }

        @include mq($screen-l) {
            padding: 0 0 32px;
        }

        .cms-page-view__heading {
            padding: 0;
        }

        p {
            color: $loyalty-gray-dark;
            font-size: $font-size-medium;
        }

        & > h2 {
            font-size: 32px;
            line-height: normal;
        }
    }

    &__gallery {
        &--show-all {
            .cms-page-view__gallery-item {
                &:nth-child(n+10) {
                    display: block;
                }
            }
        }
    }

    &__gallery-item {
        &:nth-child(n+10) {
            display: none;
        }
    }

    &__gallery-button {
        margin: $spacer--medium 0 0;
    }
}
