$submenu__list-max-width\@medium          : 752px;
$submenu__item-min-width                  : 152px;
$submenu__item-min-width\@medium          : 172px;
$submenu__item-min-width\@xl              : 176px;
$submenu__item-height                     : 56px;
$submenu__item-separator-height           : 24px;
$submenu__item-separator-border           : 1px solid #D8D8D8;
$submenu__item-separator-margin-offset    : -3px;
$submenu__item-active-border-width        : 52%;
$submenu__item-active-border-height       : 3px;
$submenu__item-active-position-bottom     : -7px;
$submenu__item-active-position-bottom\@xxl: -10px;
$submenu__item-active-position-left       : 24%;
$submenu__item-active-border              : $submenu__item-active-border-height solid $color-primary;
$submenu__item-min-width                  : 152px;
$submenu__link-font-size                  : 18px;
$submenu__link-active-text-shadow         : 0 0 0.65px $gray-dark, 0 0 0.65px $gray-dark;

@mixin border-mixin() {
    content: "";
    position: absolute;
    width: 100%;
    z-index: -1;
    height: $submenu__item-separator-height;
}

.submenu {
    &__list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: auto;
        row-gap: $spacer--extra-large; //sass-lint:disable-line no-misspelled-properties
        padding: 0;
        list-style: none;
        column-gap: 2px; //sass-lint:disable-line no-misspelled-properties

        @include mq($screen-m) {
            max-width: $submenu__list-max-width\@medium;
        }
    }

    &__item {
        position: relative;
        display: inline-flex;
        height: $submenu__item-height;
        justify-content: center;
        align-items: center;
        min-width: $submenu__item-min-width;

        @include mq($screen-m) {
            min-width: $submenu__item-min-width\@medium;
        }

        @include mq($screen-m) {
            min-width: $submenu__item-min-width\@xl;
        }

        &:first-child {
            .submenu__link,
            .pagebuilder-button-link {
                &:before {
                    display: none;
                }
            }
        }
    }

    &__link,
    .pagebuilder-button-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0 $custom-cms-spacer--extra-large 0 $custom-cms-spacer--extra-large;
        text-transform: uppercase;
        font-size: $submenu__link-font-size;
        color: $gray-dark;
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-base;
        white-space: nowrap;

        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: $submenu__item-active-border-height;
            bottom: $submenu__item-active-position-bottom;
            left: $submenu__item-active-position-left;
            border-bottom: $submenu__item-active-border-height solid transparent;
            transition: $transition-base;

            @include mq($screen-xxl) {
                bottom: $submenu__item-active-position-bottom\@xxl;
            }
        }

        &:hover,
        &:focus {
            color: $gray-darker;
            // Text shadow instead of font-weight:bold to prevent layout shifts.
            text-shadow: $submenu__link-active-text-shadow;

            &:after {
                width: $submenu__item-active-border-width;
                height: $submenu__item-active-border-height;
                border-bottom: $submenu__item-active-border;

                @include mq($screen-xxl) {
                    bottom: $submenu__item-active-position-bottom\@xxl;
                }
            }
        }

        &:before {
            @include border-mixin();
            border-left: $submenu__item-separator-border;
            margin-left: $submenu__item-separator-margin-offset; // offset to prevent double border
        }
    }
}
