.dashboard {
    &__table {
        @extend .table;
        font-size: $font-size-large;
        margin-bottom: $spacer--extra-large;

        thead {
            display: block;
            tr {
                display: flex;
                flex-direction: column;
                background: $gray-lightest;
                border: $spacer--medium solid $gray-lightest;
            }
            th {
                display: flex;
                align-items: center;
                font-weight: $font-weight-bold;
                font-size: $font-size-extra-large;
                &:last-child,
                &:first-child {
                    padding: 0;
                }
                .button {
                    height: 40px;
                }
            }
            @include mq($screen-m) {
                display: table-header-group;
                tr {
                    display: table-row;
                }
                th {
                    display: table-cell;
                    width: 40%;
                    &:first-child {
                        width: 60%;
                    }
                }
            }
        }
        tbody {
            td {
                display: flex;
                flex-direction: column;
                font-weight: $font-weight-thin;
                padding: 0;
                &:before {
                    display: block;
                    content: attr(data-th);
                    padding-bottom: $spacer;
                }
                &.dashboard-table__status {
                    font-size: $font-size-base;
                    .dashboard-status {
                        padding-bottom: $spacer;
                        font-weight: $font-weight-bold;
                        font-size: $font-size-large;
                    }
                }
                &.dashboard-table__order {
                    padding-bottom: $spacer--large;
                    color: $gray-darker;
                    font-size: $font-size-base;
                    &:before {
                        color: $gray-darker;
                        font-weight: $font-weight-bold;
                        font-size: $font-size-large;
                    }
                    @include mq($screen-m) {
                        padding-top: $spacer--extra-large;
                    }
                }
            }
            tr {
                display: flex;
                flex-direction: column;
                border: $spacer--medium solid $gray-lightest;
                border-top: 0;
                background: $white;
                padding: $spacer--large;
                &:last-child {
                    border-bottom: $spacer--medium solid $gray-lightest;
                }
            }
            @include mq($screen-m) {
                display: table-row-group;
                tr {
                    display: table-row;
                }
                td {
                    display: table-cell;
                }
            }
        }
    }
}
