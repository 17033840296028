$footer__background-color        : $gray-lightest;
$footer__padding                 : $spacer--large 0 $spacer * 6;

$footer__label-font-weight       : $font-weight-bold;


$footer__links-width\@large      : 100%;
$footer__link-font-weight        : $font-weight-normal;
$footer__link-font-size          : $font-size-small;
$footer__link-color              : $gray;
$footer__link-color-hover        : $color-primary;
$footer__link-inner-padding      : $spacer;

$footer__address-color           : $gray;
$footer__address-padding         : 0 $spacer--large;

$footer__copyright-font-size     : 13px;
$footer__copyright-padding       : $spacer * 4 0 0 $spacer--large;
$footer__copyright-padding\@large: $spacer * 5 0 0 $spacer--large;

.footer {
    background-color: $footer__background-color;
    padding: $footer__padding;
    margin-top: $spacer--medium;

    &--print {
        display: none;
    }

    &__handler {
        margin: 0 auto;

        @include mq($screen-l) {
            display: flex;
            flex-flow: row wrap;
        }
    }

    &__links {
        width: $footer__links-width\@large;

        .dropdown-list__content {
            @include mq($screen-m) {
                padding: 0;
            }
        }

        .dropdown-list__label {
            font-weight: $footer__label-font-weight;
        }

        .list__item {
            padding-left: $footer__link-inner-padding;
        }

        .dropdown-list__item {
            width: auto;
        }
    }

    &__link,
    .list__item a {
        display: block;
        font-size: $footer__link-font-size;
        font-weight: $footer__link-font-weight;
        text-decoration: none;
        color: $footer__link-color;

        &:focus,
        &:hover {
            color: $footer__link-color-hover;
        }
    }

    &__address {
        font-style: normal;
        font-size: 14px;
        line-height: 30px;
        padding: $footer__address-padding;
        color: $footer__address-color;

        .footer__icon {
            width: 18px;
            height: 18px;
            margin-right: $spacer--medium;
        }

        a {
            color: $font-color-base;
        }

        ul {
            padding-left: 30px;
        }
    }

    &__copyright {
        display: block;
        font-size: $footer__copyright-font-size;
        text-align: left;
        padding: $footer__copyright-padding;
        font-size: $font-size-extra-small;
        color: $gray;

        @include mq($screen-l) {
            padding: $footer__copyright-padding\@large;
        }
    }

    &__color-primary {
        color: $color-primary;
    }

    &__social {
        width: 100%;
        display: flex;
        padding: $spacer--extra-large 0;
        font-size: $footer__link-font-size;
        color: $footer__address-color;

        @include mq($screen-m) {
            justify-content: flex-end;
            padding: 0 0 $spacer--extra-large;
        }
    }

    &__social-items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @include mq($screen-m) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__label,
    &__social-items-title {
        @extend .dropdown-list__label;

        font-size: $font-size-medium;
    }

    &__social-items-list {
        display: flex;
        padding: 0;
        margin: 0;

        @include mq($screen-m) {
            padding: 0 $spacer--large;
        }

        li:not(:last-child) {
            margin-right: $spacer--medium;
        }

        img,
        svg {
            width: 24px;
        }
    }

    &__nav-button {
        width: fit-content;
        margin-bottom: 34px;
        font-size: $font-size-medium;
        text-transform: none;
    }

    &__links-list {
        padding: 0 $spacer--large;

        .list__item {
            padding: 0 0 $spacer--large;
        }
    }

    &__dropdown-list {
        &.dropdown-list__list {
            padding: 0;

            @include mq($screen-m) {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 16px;
                grid-row-gap: 24px;

                .footer__links {
                    &--categories {
                        grid-area: 1 / 1 / 3 / 2;
                    }
                    &--brands {
                        grid-area: 1 / 2 / 3 / 3;
                    }
                    &--buyers {
                        grid-area: 1 / 3 / 3 / 3;
                    }
                    &--about {
                        grid-area: 1 / 4 / 3 / 4;
                    }
                    &--contact {
                        grid-area: 1 / 5 / 2 / 5;
                    }
                    &--social {
                        grid-area: 2 / 5 / 3 / 5;
                    }
                }
            }
        }

        & > ul {
            padding: 0;
        }
    }

    &__awards {
        display: flex;
        justify-content: space-between;

        @include mq($screen-m) {
            flex-direction: column;
        }

        ul {
            display: flex;
            padding: 0;

            @include mq($screen-m) {
                padding: 0 $spacer--large;
            }
        }

        li:not(:last-child) {
            margin-right: $spacer--medium;
        }

        img {
            width: 54px;
        }
    }

    &--auth {
        padding: 0;
        margin: 0;

        .button {
            margin-left: auto;
            margin-right: auto;
        }
        .footer {
            &__handler {
                display: flex;
                flex-direction: column;
                max-width: 598px;
                padding: 20px 0;

                @include mq($screen-m) {
                    padding: 34px 0;
                }
            }

            &__copyright {
                margin: auto;
                max-width: 65%;
                font-size: $font-size-small;
                padding: 0;

                @include mq($screen-m) {
                    max-width: 85%;
                }
            }
        }
    }

    img {
        width: auto;
    }

    ul {
        list-style: none;
    }
}
