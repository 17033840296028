.loader {
    position: relative;
    cursor: not-allowed;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $white;
        opacity: 0.8;
        z-index: 900;
    }

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 46px;
        height: 50px;
        margin: -23px 0 0 -25px;
        background-image: url('../images/icon-sprite.svg#loader-view');
        background-size: 46px 50px;
        transition: $transition-base;
        animation: rotating 400ms linear infinite;
        z-index: 901;
    }

    &--fixed {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999;
    }

    &--transparent {
        background: transparent;
    }
    &--small {
        min-height: 0;

        &:after {
            width: 30px;
            height: 30px;
            margin: -15px 0 0 -15px;
            background-size: 30px 30px;
        }
    }
}
