.cart-table-details {
    padding: $spacer--large 0 $spacer--medium;
    text-align: left;

    &__title {
        padding: 0;
    }
    &__description {
        display: flex;
        flex-flow: row wrap;
        font-size: $font-size-small;
        justify-content: space-between;

        .list {
            display: inline-block;
            justify-content: none;
            padding-bottom: 0;
        }

        .list__label {
            font-weight: $font-weight-base;
        }
    }
}
