.returns-view-comment {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    padding: $spacer--large;
    &__label,
    &__date,
    &__paragraph {
        color: $gray;
        font-size: $font-size-base;
        @include mq($screen-m) {
            font-size: 12px;
        }
        @include mq($screen-l) {
            font-size: $font-size-base;
        }
    }
    &__label {
        flex: 0 0 auto;
        font-weight: $font-weight-bold;
    }
    &__date {
        flex: 0 0 auto;
    }
    &__paragraph {
        flex: 1 1 100%;
        padding: $spacer--medium 0 0;
        margin: 0;
    }
}
