.account-edit {
    position: relative;

    .account-details__content {
        .tab {
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}
