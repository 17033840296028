.returns-view-description-list {
    @extend .list;
    @extend .list--description-columns;

    .list__item {
        display: flex;
        flex: 1 1 100%;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: flex-start;
        padding: $spacer 0;
        @include mq($screen-m) {
            flex: 1 1 auto;
            justify-content: flex-start;
            padding: 0 $spacer--large $spacer--large;
        }
        &:last-child {
            padding-bottom: $spacer--large;
        }
    }
    .list__label {
        font-weight: $font-weight-thin;
    }
    .list__value {
        font-weight: $font-weight-bold;
    }
    .list__label,
    .list__value {
        flex: 0 0 auto;
        font-size: $font-size-base;
        line-height: $font-line-height;
        color: $font-color-base;
        @include mq($screen-m) {
            font-size: $font-size-extra-small;
        }
        @include mq($screen-l) {
            font-size: $font-size-base;
        }
    }
    .returns-view-description-list__item--vertical {
        flex-direction: column;
        align-items: stretch;
    }
}
