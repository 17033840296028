.hint {
    &--white {
        @include hint-color(hsl(0, 0%, 100%));
        .hint__content {
            color: $gray-dark;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
            border: solid 0.5px $gray-lightest;
        }
    }
    &.hint--top::before {
        margin-bottom: -8px;
    }
    &.hint--bottom::before {
        margin-top: -7px;
    }
    &.hint--left::before {
        margin-right: -7px;
    }
    &.hint--right::before {
        margin-left: -7px;
    }
    &__content {
        font-family: $font-family-base;
        font-size: $font-size-base;
        font-weight: $font-weight-thin;
        letter-spacing: 0.5px;
        white-space: nowrap;
    }
    &__icon {
        width: 14px;
        height: 14px;
        fill: $gray-light;
    }
}
