.vb > .vb-dragger {
    z-index: 5;
    width: 12px;
    right: 0;
}

.vb > .vb-dragger > .vb-dragger-styler {
    backface-visibility: hidden;
    transform: rotate3d(0, 0, 0, 0);
    backface-visibility: hidden;
    background-color: $color-primary;
    margin: 0 0 0 3px;
    height: 100%;
    display: block;
}

.vb-visible {
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: calc(100% - 1px);
        width: 9px;
        background-color: $gray-lightest;
    }
}

.vb-content {
    width: 100% !important; // sass-lint:disable-line no-important
}

.vb-content,
.filter-menu {
    // IE and Edge
    -ms-overflow-style: none;
    // Firefox
    scrollbar-width: none; // sass-lint:disable-line no-misspelled-properties

    // Hide scrollbar for Chrome, Safari and Opera
    &::-webkit-scrollbar {
        display: none;
    }
}
