.cart-table-details {
    width: 100%;
    &__title {
        display: block;
        height: 17px;
        margin: $spacer 0;
        overflow: hidden;
        line-height: 17px;
        color: $gray-darker;
        text-align: left;
    }

    &__description {
        .list {
            display: flex;
            justify-content: space-between;
            padding: $spacer 0;

            &:last-child {
                padding: $spacer 0 0;
            }

            &__label {
                color: $gray-dark;
                font-weight: $font-weight-thin;
            }

            &__value {
                color: $gray-darker;
                font-weight: $font-weight-bold;
            }
        }
    }

    @include mq($screen-m) {
        width: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        &__title {
            margin: 0 0 $spacer;
        }

        &__description {
            margin: 0;
            .list {
                display: block;
                justify-content: initial;
                padding: 0 !important; // sass-lint:disable-line no-important
            }
        }
    }

    &__checkbox {
        @include mq($screen-l) {
            left: 20%;
        }
    }

    @include mq($screen-l) {
        &__description {
            display: flex;
            justify-content: space-between;
        }
    }

    &--child-cell {
        @include mq($screen-m) {
            .cart-table-details__description {
                .list__label {
                    margin: 0 $spacer $spacer 0;
                }
            }
        }
    }
}
