.product-view-manufacturer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 14px 8px;

    &__label {
        font-size: $font-size-base;
        color: $gray-light;
    }
    &__name {
        font-size: $font-size-base;
        color: $gray-darker;
        font-weight: $font-weight-bold;
        margin: 0 0 0 $spacer;
    }
    &__anchor {
        max-width: 80px;
        max-height: 40px;
    }
}
