.cart {
    &__heading {
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacer--extra-large;
        padding-right: 0;
        @include mq($screen-m) {
            margin-bottom: $spacer;
        }

        .heading {
            align-self: center;
            margin: 0;
        }

        .button--checkout-submit {
            display: none;
        }
    }

    &__buttons {
        display: flex;
        fill: $blue;

        .icon {
            align-self: center;
            margin-right: $spacer--large;
        }
    }
}
