// Horizontal\@medium
$list__padding--horizontal\@medium: 10px;

// Divided
$list__border--divider    : 1px solid $gray-dark;

.list {
    &--horizontal\@medium {
        display: flex;
        flex-flow: row nowrap;

        .list__item {
            padding: $list__padding--horizontal\@medium;

            &:first-child {
                padding-top: $list__padding--horizontal\@medium;
            }
            &:last-child {
                padding-bottom: $list__padding--horizontal\@medium;
            }
        }
    }

    &--divided {
        &.list--horizontal\@medium {
            .list__item {
                border-bottom: none;
                border-right: $list__border--divider;

                &:last-child {
                    border-right: none;
                }
            }
        }
    }
}
