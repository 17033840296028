$rank__cell-width        : 80px;
$rank__cell-width--large : 150px;

.rank {
    font-size: $font-size-base;

    &__heading {
        font-size: 23px;
        font-weight: bold;
        margin-bottom: 27px;
    }

    &__list-headings {
        display: flex;
        justify-content: space-between;
        margin-bottom: 11px;
    }

    &__list-heading {
        min-width: $rank__cell-width;
        text-align: center;

        @include mq($screen-m) {
            min-width: $rank__cell-width--large;
        }
    }

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-flow: column nowrap;
        height: auto;

        @include mq($screen-m) {
            height: 600px;
            flex-flow: column wrap;
        }
    }

    &__list-item {
        margin-bottom: $spacer--medium;
        display: flex;
        align-items: center;
        height: 50px;
        background-color: $gray-lightest;
        justify-content: space-between;

        &--active {
            background-color: $color-primary;
            color: $white;
        }
    }

    &__position,
    &__label,
    &__points {
        font-weight: $font-weight-bold;
        min-width: $rank__cell-width;
        text-align: center;

        @include mq($screen-m) {
            min-width: $rank__cell-width--large;
        }
    }

    &--beprofi {
        .rank {
            &__heading {
                text-align: center;
                margin: 40px 0;
            }

            &__list-item {
                justify-content: start;

                @include mq($screen-m) {
                    margin-right: $spacer--medium;
                    width: calc(50% - 10px);
                }
            }

            &__label {
                order: 1;
                margin-left: $spacer;
                min-width: auto;
            }

            &__points {
                margin-left: 30px;
                min-width: auto;
                font-weight: $font-weight-base;
            }

            &__position {
                margin-left: 30px;
                min-width: auto;
            }

            // beprofi-rank leftovers
            &__client-position {
                height: 50px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-top: 20px;
                flex-flow: row wrap;
                @include mq($screen-m) {
                    flex-flow: row nowrap;
                }
            }
            &__client-position-heading {
                flex-basis: auto;
                width: 100%;
                margin: 0 0 $spacer--medium 0;
                @include mq($screen-m) {
                    width: auto;
                    margin: 0 $spacer--medium 0 0;
                }
            }
            &__client-position-item {
                flex: 0 1 auto;
                width: 100%;
                @include mq($screen-m) {
                    width: calc(50% - 10px);
                    margin-right: 10px;
                }

                .rank {
                    &__list-item {
                        width: 100%;
                    }
                }
            }
        }
    }

    &--condensed {
        .rank {
            &__list {
                @include mq($screen-m) {
                    max-height: 600px;
                    height: fit-content;
                }
            }

            &__position {
                min-width: 20px;
                text-align: right;
            }
        }
    }
}
