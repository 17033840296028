$article-margin-bottom                 : 56px;
$article-margin-bottom\@medium         : 64px;
$article-max-width                     : 1328px;

$article_link-gap                      : 7px;
$article_link-line-height              : 24px;

.article {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    margin-bottom: $article-margin-bottom;
    max-width: $article-max-width;
    width: 100% !important; //sass-lint:disable-line  no-important

    @include mq($screen-m) {
        margin-bottom: $article-margin-bottom\@medium;
    }

    @include mq($screen-xl) {
        margin-bottom: 0;
    }

    &__description {
        margin-bottom: $custom-cms-spacer--extra-large;
        color: $gray-dark;
    }

    &__link {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: $article_link-gap; //sass-lint:disable-line  no-misspelled-properties
        font-size: $font-size-small;
        line-height: $article_link-line-height;

        &:after {
            content: '';
            width: $spacer--medium;
            height: $spacer--medium;
            background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 12"><path fill-rule="evenodd" fill="#{$color-primary}" d="M0 1.4L1.4 0l6 6-6 6L0 10.6 4.6 6"/></svg>') no-repeat;
            transition: $transition-base;
        }

        &:hover,
        &:focus {
            color: $color-secondary--active;

            &:after {
                background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 12"><path fill-rule="evenodd" fill="#{$color-secondary--active}" d="M0 1.4L1.4 0l6 6-6 6L0 10.6 4.6 6"/></svg>') no-repeat;
            }
        }
    }

    &__header {
        min-height: 58px;
        margin: $custom-cms-spacer--medium 0 0;
        font-size: $custom-cms-font-size-large;
    }

    .lazyload-wrapper {
        flex: 1;
    }

    &--home {
        figure {
            margin: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
