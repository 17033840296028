$fieldset__border       : none;
$fieldset__margin       : 0;
$fieldset__padding      : 0;
$fieldset__legend-margin: 0 0 $spacer--large;

$fieldset__outline--secondary: 1px solid $form-elements-border-color;

.fieldset {
    border: $fieldset__border;
    padding: $fieldset__padding;
    margin: $fieldset__margin;
    width: 100%;
    // this rule keep a fieldset inside the form width
    min-width: 0;

    &--secondary {
        outline: $fieldset__outline--secondary;

        & > .fieldset__legend {
            position: relative;
            clip: auto;
            height: auto;
            width: 100%;
            padding: $spacer--large;
            font-size: 17px;
            background-color: $gray-lightest;
        }
    }

    &__wrapper {
        padding: 0 $spacer--large;
    }

    &__legend {
        font-weight: $font-weight-bold;
        padding: $spacer--large $spacer--medium;

        &--hidden {
            @include visually-hidden();
        }
    }
}
