$slider-item-min-width    : 240px;
$slider-item-max-width    : 300px;
$slider-horizontal-margin : 30px;
$slider-screen-l          : 1024px;
$slider-border            : 1px solid #ededed;
.slider {
    &--products {
        max-width: 100vw;
        margin: 0 $slider-horizontal-margin;

        .slider {
            &__navigation {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                justify-content: space-between;

                @include mq($screen-xl) {
                    display: flex;
                }

                .slider__slides--loaded & {
                    display: flex;
                }
            }

            &__navigation-icon {
                border: 1px solid $gray-light;

                &:hover,
                &:focus,
                &:active {
                    background-color: $color-primary;
                    border-color: $color-primary;
                }
            }

            &__prev {
                transform: translateX(-$slider-horizontal-margin);
            }

            &__next {
                transform: translateX($slider-horizontal-margin);
            }

            &__item {
                padding: 0 $spacer--medium;
                flex: 0 1 auto;
                width: 100%;
                min-width: $slider-item-min-width;
                max-width: $slider-item-max-width;

                @include mq($screen-m) {
                    min-width: calc(100% / 3);
                }

                @include mq($slider-screen-l ) {
                    min-width: calc(100% / 4);
                }

                @include mq($screen-xl) {
                    min-width: calc(100% / 5);
                }

                @include mq($screen-3xl) {
                    min-width: calc(100% / 6);
                }

                .catalog-item {
                    width: 100%;
                    height: 100%;
                    padding: $spacer--medium;
                    flex-direction: column;

                    &__option-action {
                        position: relative;
                        background-color: $gray-lightest;
                        width: 100%;
                        color: $gray-darker;
                        font-weight: 400;
                        text-transform: none;

                        @include mq($screen-m) {
                            width: auto;
                        }

                        &:hover,
                        &:focus {
                            color: $white;
                            background-color: $color-primary;

                            .icon {
                                fill: $white;
                            }
                        }

                        .icon {
                            position: absolute;
                            right: 10px;

                            @include mq($screen-m) {
                                right: auto;
                            }
                        }
                    }
                }

                .catalog-item__image-wrapper .catalog-item__image {
                    max-height: none;
                }

                .catalog-requisition-lists {
                    width: 100%;
                }
            }
        }

        .slick-list {
            .slick-track {
                column-gap: 0;
            }
        }
    }

    &--bordered {
        .slick-slide {
            &.slick-active {
                border-right: $slider-border;

                &:last-child {
                    border: none;
                }
            }
        }

        .slider__item {
            border-right: $slider-border;

            &:last-child {
                border: none;
            }
        }
    }

    &--cms {
        max-width: $max-content-width;
        margin: 0 $spacer--large;

        @include mq($max-content-width + 2 * $spacer--large) {
            margin: 0 auto;
        }

        .slick-track {
            column-gap: 0;
        }

        .slick-slide {
            margin: 0 $spacer--large;
        }
    }

    &--nav {
        max-width: $max-content-width;
        margin-top: $spacer--medium;
        display: flex;
        align-items: center;
        position: relative;

        .slick-list {
            max-width: 100%;
            flex: 1;
        }

        .slick-slide {
            cursor: pointer;
        }

        .slick-current {
            // sass-lint:disable no-important
            outline: 2px solid $blue !important;
            outline-offset: 3px;
        }

        .slick-track {
            column-gap: $spacer--large;
        }
    }

    &--cms,
    &--nav {
        .slider {
            &__navigation {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                justify-content: space-between;

                @include mq($screen-xl) {
                    display: flex;
                }

                .slider__slides--loaded & {
                    display: flex;
                }
            }

            &__navigation-icon {
                border: 1px solid $gray-light;

                &:hover,
                &:focus,
                &:active {
                    background-color: $color-primary;
                    border-color: $color-primary;
                }
            }

            &__prev,
            &__next {
                position: absolute;
                z-index: 2;
                top: 50%;
                height: 44px;
                width: 44px;
                min-width: 44px;
                border: 1px solid $custom-cms-color-gray-lighter;

                .icon {
                    height: 10px;
                }
            }

            &__prev {
                left: 0;
                transform: translate(-$spacer--large, -50%);
            }

            &__next {
                right: 0;
                transform: translate($spacer--large, -50%);
            }


            &__item {
                max-width: 100%;
            }
        }

        .slick-slide {
            > div {
                display: flex;
            }
        }

        .slick-track {
            display: flex;
        }
    }
}
