.inquiry-list {
    .search-bar {
        width: 100%;
        padding: $spacer--large 0 $spacer--large;
        margin: 0;
        @include mq($screen-m) {
            max-width: 300px;
            float: right;
        }
    }
    .document-filters {
        padding: $spacer--large 0;
        margin-bottom: $spacer--large;
        border-bottom: 1px solid $border-color-base;
    }
}
