.home-ads {
    @include home-container();

    &__banners {
        display: flex;
        gap: $spacer--large; //sass-lint:disable-line no-misspelled-properties
        flex-direction: column;

        @include mq($screen-xl) {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }

    &__banner {
        &:hover,
        &:focus {
            opacity: 0.8;
        }
    }

    &__image {
        object-fit: cover;
        object-position: center;
    }
}
