.invoices-view {
    &__document-details,
    &__tab,
    &__summary {
        margin: 0 -8px;
    }
    &__button-back {
        display: inline-flex;
        margin: 0;
        .icon {
            width: 7px;
            height: 12px;
        }
    }
    &__table {
        tbody {
            td.dashboard-table__button {
                .invoices-view__remove {
                    width: auto;
                    display: inline-flex;
                }
            }
            tr.table__cell--child-content {
                .table__empty-heading {
                    @include mq($screen-m) {
                        padding: 0;
                    }
                    &:before {
                        min-width: 0;
                    }
                }
                .child-table tr {
                    background-color: $gray-lightest;
                }
            }
        }
    }
    &__related-orders-table {
        .button--icon {
            display: inline-flex;
        }
        .icon {
            width: 22px;
            height: 22px;
        }
    }
}

