.home-submenu {
    @include home-container();

    display: flex;
    flex-direction: column;
    gap: $spacer--medium; // sass-lint:disable-line no-misspelled-properties
    padding: 0 0 $spacer--extra-large;
    margin-bottom: $spacer--semi-large;

    @include mq($screen-m) {
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: $spacer--extra-large;
    }

    @include mq($screen-xl) {
        column-gap: $spacer--large;
    }

    @include mq($max-content-width + 2 * $spacer--large) {
        margin-bottom: $spacer--semi-large;
    }

    &__button {
        height: 45px;
        padding: 0 36px;

        @include mq($screen-m) {
            flex-grow: 1;
            max-width: 350px;
        }

        @include mq($screen-xl) {
            flex-grow: 0;
        }

        &:hover,
        &:focus {
            &.button--icon  {
                .icon {
                    &--angleright {
                        fill: $white;
                        stroke: none;
                    }
                }
            }

            &.button--loyalty {
                .icon {
                    fill: $white;
                }
            }
        }

        .icon {
            &--angleright {
                margin-right: 0;
                height: $spacer--medium;
                stroke: none;
            }
        }

        &.button {
            &--brand {
                .icon {
                    margin: 0 $spacer--large 0 0;

                    &--angleright {
                        margin: 0;
                        width: $spacer--medium;
                    }

                    &:first-child {
                        width: 125px;
                    }
                }
            }

            .button__text {
                font-size: $font-size-small;
                margin: 0 $spacer--large 0 0;
            }
        }
    }
}
