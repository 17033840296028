.print-watermark {
    position: fixed;
    top: 0;
    font-size: 20vw;
    text-transform: uppercase;
    transform: rotate(-45deg);
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.1;
}
