.inquiry-view {
    position: relative;
    .sanpol-inquiry__button-back {
        display: inline-flex;
        margin: 0;
        .icon {
            width: 7px;
            height: 12px;
        }
    }
    .dashboard-tab__icon {
        fill: $white;
    }
    .dashboard-table__cell--details {
        &:before {
            content: none;
        }
    }
}
