$tab__border-width           : 1px;
$tab__border-width--large    : 8px;

// title
$tab__title-padding          : $spacer--large 30px;
$tab__title-border           : $tab__border-width solid transparent;
$tab__title-color            : $gray-darker;
$tab__title-color--active    : $gray-darker;
$tab__title-font-weight      : $font-weight-bold;
$tab__title-background       : $gray-lightest;

// content
$tab__content-padding        : $spacer--medium $spacer--large;
$tab__content-padding--large : $spacer--large $spacer--extra-large;

// icon
$tab__icon-color             : $gray;
$tab__icon-color--active     : $gray-darker;
$tab__icon-padding           : 16px;
$tab__icon-size              : 48px;

.tab {
    display: flex;
    flex-flow: row wrap;
    flex-basis: 100%;

    &__title {
        flex: 1 1 auto;
        order: -1;
        padding: $tab__title-padding;

        text-align: center;
        font-weight: $tab__title-font-weight;

        color: $tab__title-color;
        background-color: $tab__title-background;

        border: $tab__title-border;

        cursor: pointer;

        @include mq($screen-l) {
            flex: 0 1 auto;
        }

        &--active {
            background: none;
            color: $tab__title-color--active;
            z-index: 2;
            border: 1px solid $gray-lighter;
            border-bottom-color: $white;
        }

        &--icon {
            padding: 17px;

            .icon {
                width: 13px;
                height: 13px;
            }

        }
    }

    &__content {
        position: relative;
        top: -1px;
        display: none;
        width: 100%;
        padding: $tab__content-padding;
        border-top: 1px solid $gray-lighter;

        @include mq($screen-l) {
            padding: $tab__content-padding--large;
            flex-basis: 100%;
        }

        &--without-spacing {
            padding: 0;
        }

        &--active {
            display: block;
        }

        // Nested tabs
        .tab__content {
            border: 1px solid $gray-lighter;
        }

        &--empty {
            text-align: center;
            padding: $spacer--large 0 0;
        }

        &--condensed {
            .loading {
                @extend .loading--small;
            }

            .empty-content {
                @extend .empty-content--condensed;
            }
        }
    }

    &[disabled] {
        .tab__title,
        .tab__content {
            border: 0 !important; // sass-lint:disable-line no-important
            background: rgba(240, 241, 243, 0.5) !important; // sass-lint:disable-line no-important
        }

        .tab__title,
        .tab__content,
        .list__label,
        .list__value {
            color: $gray-light;
        }
    }
}

.catalog-product-view {
    .tab__title--icon {
        .icon {
            display: inline;
            vertical-align: middle;
            margin-left: $spacer--semi-large;
            transition: ease 0.25s;
            transform-origin: center;

            @include mq($screen-xl) {
                display: none;
            }
        }

        &.tab__title--active {
            .icon {
                transform: rotate(0.5turn);
            }
        }
    }

    .tab__content--active {
        order: -1;

        @include mq($screen-xl) {
            order: 0;
        }
    }
}
