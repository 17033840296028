$sidebar__border-color       : $border-color-base;
$sidebar__border-bottom-color: #ededed;

$sidebar__label-color        : $gray-darker-secondary;
$sidebar__label-padding      : 0 $spacer--extra-large;
$sidebar__label-line-height  : 22px;

$sidebar__link-color         : $gray-darker-secondary;
$sidebar__link-padding       : 0 $spacer--extra-large;

.sidebar {
    &__list {
        padding: 0 0 $spacer;

        &:first-child {
            padding: 0 0 $spacer--large;
        }

        &:last-child {
            padding: 0 0 $spacer--large;

            .sidebar__item:last-child {
                border-bottom: none;
            }
        }
    }

    &__item {
        display: flex;
        align-items: center;
        height: 28px;

        &--label {
            padding: $sidebar__label-padding;
            height: 32px;
            font-weight: $font-weight-bold;
            font-size: $font-size-base;
            color: $sidebar__label-color;
        }

        &--popup-menu {
            display: none;
        }

        &--hidden {
            display: none;
        }

        &--darker-background {
            background-color: $gray-lightest;
        }
    }
    &__label {
        padding: $sidebar__label-padding;
        font-weight: $font-weight-bold;
        font-size: $font-size-base;
        color: $sidebar__label-color;
    }

    &__link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $sidebar__link-padding;
        text-decoration: none;
        font-size: $font-size-small;
        font-weight: $font-weight-normal;
        color: $sidebar__link-color;

        &--multiline {
            flex-wrap: wrap;
            padding: $spacer--extra-large;
            border-bottom: solid 1px $sidebar__border-bottom-color;

            & > * {
                flex-basis: 100%;
                margin: $spacer 0;

                &:first-child {
                    margin: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__item-user-name {
        color: $gray-darker-secondary;
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
    }

    &__item-link {
        color: $color-primary;
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
    }
}
