.document-filter {
    max-width: 100%;
    &__input-wrapper {
        display: flex;
        flex-flow: row nowrap;

        .select {
            width: 100%;
        }
    }
    &__label {
        padding: 0 0 $spacer 0;
    }
    &--range {
        .document-filter__input {
            flex: 1;
            max-width: calc(50% - 7px);
            margin: 0;
        }
        .document-filter__input-separator {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 14px;
        }
        .document-filter__icon {
            top: 18px;
        }
    }
}
