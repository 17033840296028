$loader__size            : 100% !default;
$loader__icon-size       : 56px !default;
$loader__background      : rgba(255, 255, 255, 0.5) !default;
$loader__z-index         : 10000 !default;
$loader__border          : 8px solid $black !default;
$loader__border-top-color: transparent !default;
$loader__border-radius   : 50% !default;
$loader__animation       : spin 1s linear infinite !default;

.alpaca-loader {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: $loader__size;
    height: $loader__size;
    background-color: $loader__background;
    z-index: $loader__z-index;

    &--visible {
        display: block;
    }

    &__icon {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 46px;
        height: 50px;
        margin: -23px 0 0 -25px;
        background-image: url('../images/icon-sprite.svg#loader-view');
        background-size: 46px 50px;
        transition: $transition-base;
        animation: rotating 400ms linear infinite;
    }
}
