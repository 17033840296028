.notification-data-update {
    list-style: none;
    padding: 0;
    margin: 0;
    &__data {
        display: flex;
        justify-content: space-between;
    }
    &__key {
        flex: 0 1 50%;
        line-height: $font-line-height;
    }
    &__value {
        flex: 0 1 50%;
        font-weight: $font-weight-bold;
        line-height: $font-line-height;
    }
}
