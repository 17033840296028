$popup__padding                  : 0;
$popup__content-top-margin       : 0;
$popup__width                    : 320px;
$popup__width--screen-s          : 100%;
$popup__background               : $white;
$popup__z-index                  : 50;
$popup__border                   : 0;
$popup__box-shadow-color         : $black;
$popup__box-shadow               : 0 2px 4px 0 rgba($popup__box-shadow-color, 0.3);
$popup__close-button-bg          : $white;
$popup__close-button-border      : 0;
$popup__button-icon-fill         : $black;
$popup__button-icon-fill--actiive: $color-primary;
$popup__margin                   : 0;

.popup {
    display: none;
    position: absolute;
    right: 0;
    left: auto;
    width: $popup__width--screen-s;
    padding: $popup__padding;
    margin: $popup__margin;
    z-index: $popup__z-index;
    box-shadow: $popup__box-shadow;
    background-color: $popup__background;
    border: $popup__border;
    @include mq($screen-s) {
        width: $popup__width;
    }
    @include mq($screen-xl) {
        top: -45px;
    }
    @include mq($screen-xl) {
        right: 0;
    }
    @include mq($screen-xxl) {
        top: -55px;
    }

    &--active {
        display: block;
    }

    &__handler {
        position: relative;
        margin-top: $popup__content-top-margin;
    }

    &__close-button {
        position: absolute;
        top: -48px;
        right: -16px;
        background-color: $popup__close-button-bg;
        border: $popup__close-button-border;

        &:hover,
        &:focus {
            background-color: $popup__close-button-bg;
        }

        &-icon {
            fill: $popup__button-icon-fill;

            &:hover,
            &:focus {
                fill: $popup__button-icon-fill--actiive;
            }
        }
    }

    &--menu-scroll {
        // 106px - height of .header__container
        max-height: calc(100vh - 106px);
        overflow-y: auto;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
    }
}
