.base-checkbox {
    display: flex;
    align-items: center;
    position: relative;
    background: transparent;
    padding: $spacer;
    max-width: 100%;

    &--invalid {
        .base-checkbox__box {
            border: 1px solid red;
        }
    }

    &--disabled {
        opacity: 0.6;
        filter:  grayscale(1);
    }

    &__field {
        display: none;
    }
    &__field:checked {
        ~ .base-checkbox__box .base-checkbox__icon {
            fill: $color-primary;
        }
    }

    &__label {
        display: block;
        padding-left: $spacer--large;
        font-size: $font-size-base;
        line-height: $font-line-height;
        color: $black;
        text-transform: none;
        cursor: pointer;
    }
    &__box {
        flex: 0 0 auto;
        width: 30px;
        height: 30px;
        background: $white;
        border: 1px solid $form-elements-border-color;
        background: $white;
    }

    &__icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        width: 20px;
        height: 20px;
        fill: white;
        cursor: pointer;
        transition: $transition-base;
    }
    .base-checkbox__field:checked ~ .base-checkbox__icon,
    .base-checkbox__field[value=true] ~ .base-checkbox__icon {
        fill: $color-primary;
    }

    &--required {
        .base-checkbox__label:after {
            content: '*';
        }
    }
}
