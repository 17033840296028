.orders-view {
    .dashboard-document-details,
    .dashboard-tab,
    .summary {
        margin: 0 -8px;
    }
    &__button-back {
        display: inline-flex;
        margin: 0;
        .icon {
            width: 7px;
            height: 12px;
        }
    }
}
