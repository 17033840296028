$catalog__noresults-text-color: #444;

.catalog {
    &--grid {
        display: flex;
        flex-flow: row wrap;
        margin: 15px 0 30px;
        padding: 0 $spacer--medium;
        list-style: none;

        .catalog-item {
            flex: 1 1 auto;
            margin: 0 $spacer $spacer--medium $spacer;
            min-width: 216px;

            @include mq($screen-s) {
                width: calc(50% - #{$spacer--medium});
                flex: 0 1 auto;
            }
            @include mq($screen-m) {
                width: calc(33% - #{$spacer--medium});
            }
            @include mq($screen-l) {
                width: calc(25% - #{$spacer--medium});
            }
            @include mq($screen-xl) {
                width: calc(20% - #{$spacer--medium});
            }

            &--middle {
                margin: 0 $spacer $spacer--large;
                width: 100%;
            }
        }
        .catalog-item__top-wrapper {
            position: relative;
            display: flex;
            flex-flow: row wrap;
            flex: 0 0 auto;
        }
        .catalog-item__link {
            width: 100%;
        }
    }
    &.catalog--grid.catalog--slim {
        padding: 0;
        .catalog-item--slim {
            margin: 0;
            padding: $spacer--medium;
            width: 50%;
            flex: 0 1 auto;
            min-width: 100px;

            @include mq($screen-m) {
                width: 33%;
            }

            @include mq($screen-l) {
                width: 25%;
            }
        }
    }

    &--list {
        display: block;
        margin: $spacer--extra-large 0 0;
    }

    &__noresults {
        padding: $spacer--medium;

        @include mq($screen-xl) {
            padding: $spacer--medium 0;
        }

        h2,
        ul {
            font-size: $font-size-medium;
        }

        ul,
        p {
            color: $catalog__noresults-text-color;
        }

        ul {
            padding: 0 0 0 $spacer--extra-large;

            li {
                padding: 0 0 $spacer;
            }
        }
    }
}
