$contact-map__height        : 370px;
$contact-map__height\@medium: 450px;

.contact-map {
    display: block;
    min-width: 320px;
    width: 100%;
    height: 370px;
    z-index: 0;
    @include mq($screen-m) {
        height: $contact-map__height\@medium;
    }

    &__popup {
        font-family: $font-family-base;
        font-weight: $font-weight-thin;
        text-align: center;
        line-height: 1.6;

        &-heading {
            font-size: 15px;
            margin-bottom: 0;
        }

        // overwrite default leaflet styles
        a.leaflet-popup-close-button {
            right: $spacer;
            padding: 0;
            font-size: 28px;
            line-height: 1;
        }
        .leaflet-popup-content-wrapper {
            border-radius: 0;
        }
    }
}
