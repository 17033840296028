.dropdown-switcher {
    position: relative;
    width: 100%;
    background-color: $white;
    z-index: 2;

    &--open {
        .dropdown-switcher__content {
            display: block;
        }

        .dropdown-switcher__label {
            background-color: #e1e3e7;
            @include mq($screen-xl) {
                background-color: $gray-lightest;
            }
            .icon {
                transform: rotate(180deg);
            }
        }
    }

    &--cl {
        min-width: 200px;
        margin-right: $spacer--medium;

        .dropdown-switcher__label {
            &:after {
                display: none;
            }
            .button__text {
                margin-right: 5px;
            }
        }
    }

    &--header {
        .dropdown-switcher {
            &__button-text {
                display: none;

                @include mq($screen-m) {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    margin: 0 $spacer 0 0;
                }

                &--bold {
                    font-weight: $font-weight-bold;
                }
            }

            &__icon {
                &.icon {
                    right: 0;
                    width: 12px;
                    height: 12px;
                    fill: $gray-darker-secondary;
                    display: none;

                    @include mq($screen-m) {
                        display: inline-flex;
                    }
                }

                &--tag {
                    &.icon {
                        display: inline-flex;
                        right: auto;
                        width: $spacer--extra-large;
                        height: $spacer--extra-large;
                    }
                }
            }

            &__label .icon {
                transform: none;
            }

            &__content {
                position: absolute;
                right: 0;
                top: 100%;
                min-width: 240px;
                background-color: $white;

                @include mq($screen-m) {
                    left: 0;
                }
            }

            &__label,
            &__label:hover,
            &__label:focus {
                padding: 0;
                background-color: transparent;
                color: $gray-darker-secondary;
            }

            &__label {
                justify-content: center;

                @include mq($screen-xl) {
                    height: 55px;
                }

                &:after {
                    @include mq($screen-xl) {
                        background-color: transparent;
                    }
                }

                &:hover,
                &:focus {
                    .icon {
                        fill: $gray-darker-secondary;
                    }
                }
            }

            &__label-selected {
                display: flex;
                align-items: center;
            }
        }
    }

    &__label {
        width: 100%;
        height: 48px;
        padding-right: 40px;
        justify-content: flex-start;
        font-size: 13px;
        font-weight: $font-weight-base;
        color: $font-color-base;
        background-color: $gray-lightest;
        text-transform: none;
        @include mq($screen-xl) {
            height: 40px;
            background-color: $gray-lightest;
        }

        &:after {
            @include mq($screen-xl) {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                width: 35px;
                height: 40px;
                background-color: $color-primary;
            }
        }

        .icon {
            position: absolute;
            fill: $color-primary;
            right: 15px;
            width: 13px;
            height: 13px;
            z-index: 1;
            transition: $transition-base;
            @include mq($screen-xl) {
                right: 10px;
                fill: $white;
            }
        }

        &:focus,
        &:hover {
            background-color: #e1e3e7;
            color: $font-color-base;
            @include mq($screen-xl) {
                background-color: $gray-lightest;
                .icon {
                    fill: $white;
                }
            }
        }
    }

    &__content {
        display: none;
        padding: 0;
        @include mq($screen-xl) {
            position: absolute;
            width: 100%;
            background-color: $white;
            border: 1px solid $border-color-base;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
        }
    }

    &__option {
        padding: $spacer--large;
        border-bottom: 1px solid rgba(0, 0, 0, 0.07);
        cursor: pointer;

        @include mq($screen-xl) {
            padding: 10px 7px;
            &:last-child {
                border-bottom: 0;
            }
        }

        &--selected {
            background-color: #f5f5f5;
        }

        &:focus,
        &:hover {
            background-color: #f5f5f5;
        }

        &-title {
            display: block;
            font-size: 13px;
            font-weight: $font-weight-bold;
        }

        &-description {
            display: block;
            margin-top: 5px;
            font-weight: $font-weight-thin;
            font-size: 12px;
            color: $gray-dark;
        }
    }

    &__option-button {
        border: 0;
        background-color: transparent;
        padding: 0;
        margin: 0;
        text-align: left;
        cursor: pointer;
    }

    &__option-hidden-text {
        @include visually-hidden;
    }
}
