.own-item {
    padding: $spacer--medium 0;

    &__items {
        &--hidden {
            display: none;
        }
        .own-item__title {
            padding: 0 $spacer--extra-large;
        }
    }

    &__title {
        font-weight: $font-weight-bold;
    }

    &__list {
        margin-bottom: $spacer--extra-large;
        background-color: $gray-lightest;
    }

    &__list-item {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        padding: 0 $spacer--extra-large;
        border-bottom: 5px solid $white;
    }

    &__position {
        width: 100%;
        font-weight: $font-weight-bold;
        @include mq($screen-m) {
            width: auto;
            min-width: 60%;
        }
    }

    &__position-details {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        width: 100%;
        @include mq($screen-m) {
            width: auto;
            min-width: 40%;
            justify-content: flex-end;
        }
    }

    &__price {
        font-weight: $font-weight-bold;
    }

    &__label {
        font-weight: $font-weight-thin;
        margin-right: $spacer;
    }

    &__button-remove {
        margin: 0 0 0 auto;
        &:focus,
        &:hover {
            background-color: $gray-lightest;
        }
        @include mq($screen-m) {
            margin: 0 0 0 $spacer--medium;
        }
    }

    &__add {
        padding: $spacer--large $spacer--extra-large $spacer--medium;
        background-color: $gray-lightest;
    }

    &__add-bottom {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
    }

    &__input {
        width: 100%;
        margin-bottom: $spacer--medium;
    }

    &__input-price,
    &__select {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin: 0 $spacer--extra-large $spacer--medium 0;

        .label {
            text-transform: none;
            font-weight: $font-weight-thin;
            margin-right: $spacer--medium;
        }
    }

    &__input-field {
        height: 50px;
        width: 90px;
        padding: 0 $spacer--medium;
        line-height: 50px;
    }

    &__button-add {
        margin: 0 0 0 auto;
    }

    &__form {
        margin-bottom: 0;
        @include mq($screen-m) {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
        }
    }
}
