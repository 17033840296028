.loyalty-my-data-form {
    display: flex;
    flex-flow: row wrap;
    min-height: 100px;

    &__input {
        flex: 0 1 100%;
        margin-top: $spacer;
    }

    &__street {
        @include mq($screen-m) {
            flex: 0 1 calc(100% - 127px);
        }
    }

    &__street-number {
        @include mq($screen-m) {
            flex: 0 0 117px;
            margin-left: $spacer--medium;
        }
    }

    &__postcode {
        @include mq($screen-m) {
            flex: 0 0 117px;
            margin-right: $spacer--medium;
        }
    }

    &__city {
        @include mq($screen-m) {
            flex: 0 1 calc(100% - 127px);
        }
    }

    &__pesel {
        @include mq($screen-m) {
            flex: 0 0 calc(50%);
            padding-right: $spacer--medium;
        }
    }
    &__phone {
        @include mq($screen-m) {
            flex: 0 0 calc(50%);
        }
    }

    &__taxoffice {
        margin-top: $spacer--medium;
        &-label {
            text-transform: none;
        }
    }

    &__button {
        width: 100%;
        margin: 30px 0 $spacer--medium;
        @include mq($screen-m) {
            min-width: 294px;
            margin: $spacer--medium auto 0;
        }
    }
}
