.shopping-lists-view__document-details {
    @include mq($screen-m) {
        border-bottom: 1px solid $gray-lightest;
    }
    .dashboard-document-details__heading {
        display: flex;
        flex-flow: row nowrap;
        max-width: 100%;
        align-items: center;
    }
    .dashboard-document-details__heading-text {
        margin-right: $spacer;
    }
    .dashboard-document-details__columns {
        width: 100%;
    }
    .dashboard-document-details__list {
        display: flex;
        align-items: center;
        &--editable {
            max-width: 100%;
        }
    }
}
