.sorter-limiter {
    &__fieldset {
        padding: 0 0 $spacer--extra-large;
        margin: 0;
        border: 0;
        @include mq($screen-m) {
            flex-basis: 50%;
        }
    }

    &__legend {
        display: block;
        margin-bottom: 10px;
    }

    &__list {
        padding-left: 20px;
    }

    &__form {
        margin-bottom: 0;
        @include mq($screen-m) {
            display: flex;
            flex-flow: row nowrap;
        }
    }
}
