.loyalty-agreement {
    &__label,
    &__heading {
        text-align: center;
    }

    &__heading {
        margin: 0 0 30px;
        font-size: 23px;
    }

    &__label {
        margin: 0 0 $spacer 0;
        color: $gray;
    }

    &__btn {
        width: 100%;
        margin: 0 0 $spacer--extra-large;
        background-color: $loyalty-orange;

        @include mq($screen-m) {
            margin: 0 auto $spacer--extra-large;
            width: 300px;
        }
    }

    &__link {
        font-weight: $font-weight-normal;
    }
}
