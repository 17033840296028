.cart-items-list {
    thead {
        th {
            &:last-child {
                display: none;
            }
        }
    }

    tbody {
        tr {
            &.table__row--child-content {
                display: table-row !important; // sass-lint:disable-line no-important
            }

            &.table__cell--child-content {
                border-top: 1px solid $gray-light;
                padding: $spacer;

                td {
                    &:first-child {
                        &:before {
                            display: inline-block;
                            min-width: auto;
                            margin-right: $spacer;
                            padding-left: $spacer;
                        }
                    }

                    &:before {
                        padding-bottom: $spacer;
                        font-size: 13px;
                    }

                    .badge {
                        &:first-child {
                            margin-top: $spacer;
                        }
                        &:last-child {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }

        td {
            &.cart-items-list__qty-input,
            &.dashboard-table__cell--qty-input {
                .input {
                    justify-content: flex-start;
                    width: auto;
                    margin-bottom: 0;
                }
            }

            &.table__cell--child-content {
                padding: 0;
                border-top: 1px solid $border-color-base;
                display: table-cell !important; // sass-lint:disable-line no-important

                .list {
                    justify-content: flex-start;
                    align-items: flex-start;

                    &__item {
                        line-height: 1.5;
                        flex-flow: column nowrap;
                        width: 15%;
                        padding: $spacer--medium $spacer;

                        &:first-child {
                            flex-flow: row wrap;
                        }
                        &:last-child {
                            flex-grow: 1;
                        }
                    }

                    .cart-items-list__inner-label {
                        font-size: $font-size-extra-small;
                    }

                    .cart-items-list__item {
                        &--sku,
                        &--matnr {
                            width: 40%;
                            padding-left: 15px;
                        }
                        &--badges {
                            width: 15%;
                            flex-flow: row wrap;
                            justify-content: flex-end;
                            margin-right: 2%;
                        }
                    }
                }
            }

            &:last-child {
                display: none;
            }

            &:first-child {
                display: table-cell;
            }
        }
    }

    .data-dialog {
        top: 27px;
    }
}
