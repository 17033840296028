.cms-advice-item {
    padding: 25px 0;
    border-top: 1px solid $gray-lightest;

    &__image {
        margin-bottom: $spacer--medium;
    }

    &__heading,
    &__paragraph {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__paragraph {
        max-height: 192px;
    }

    &__heading {
        max-height: 64px;
    }

    @include mq($screen-m) {
        position: relative;
        min-height: 250px;
        overflow: hidden;
        padding-left: 250px;

        &__paragraph {
            max-height: inherit;
            height: 94px;
        }

        &__heading {
            max-height: inherit;
            height: 32px;
        }

        &__image {
            position: absolute;
            top: 25px;
            left: 0;
            max-width: 240px;
            max-height: 100%;
            margin: 0;
        }

        &__button {
            width: 200px;
        }
    }

    @include mq($screen-l) {
        min-height: 275px;
        padding-left: 330px;

        &__paragraph {
            height: 122px;
        }

        &__image {
            max-width: 320px;
        }
    }
}
