$summary__padding: $spacer--extra-large $spacer--medium;

.summary {
    display: flex;
    flex-flow: row nowrap;
    padding: $summary__padding;

    &__col {
        flex: 1 1 33.333%;
        flex-wrap: nowrap;

        &:nth-child(2) {
            margin: 0;
            padding: 0;
            border: none;
        }

        &--sum {
            flex: 0 0 400px;

            .summary__list-item {
                &:nth-child(1),
                &:nth-child(2) {
                    border-bottom: solid 1px $gray-lightest;
                }
            }
        }

        &--discount {
            flex: 0 0 210px;
        }
    }


    &__heading {
        margin: 25px $spacer--extra-large;
    }
}
