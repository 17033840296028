.impersonate-select {
    height: auto;
    &__caret {
        position: absolute;
        right: 17px;
        top: 50%;
        transform: translateY(-50%);
        fill: $color_primary;
        cursor: pointer;
    }
    &__option {
        position: relative;
        display: flex;
        flex-flow: row wrap;
    }
    &__option-name {
        flex-basis: 100%;
        font-weight: $font-weight-bold;
        font-size: $font-size-base;
        padding-bottom: $spacer;
    }
    &__option-company {
        flex-basis: 100%;
        font-size: 12px;
        color: $gray;
    }
    &__option-role {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        padding-bottom: $spacer;
        font-weight: $font-weight-bold;
        font-size: $font-size-extra-small;
        color: $color-primary;
        text-transform: uppercase;
        &--supervisor {
            color: $color-secondary;
        }
    }

    .vb-dragger-styler {
        margin: 0 0 0 2px !important;  // sass-lint:disable-line no-important
        width: 15px;
    }
    .vb-visible:after {
        right: 0;
        width: 15px;
    }
}
