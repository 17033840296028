$auth__border-radius: 5px;

.auth {
    padding: 0 0 $spacer--medium;
    width: 100%;

    @include mq($screen-m) {
        padding: 0;
    }

    &__header {
        padding: 30px 20px;
        margin-top: -$spacer--medium;
        background-color: $white;
    }

    &__header-logo {
        margin-right: auto;
        width: 100%;
        height: 100%;
        max-width: 276px;
        height: 41px;
        margin: 0 auto;
        @include mq($screen-m) {
            margin: 0;
        }
    }

    &__form {
        padding: 20px 10px 0;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px; // sass-lint:disable-line no-misspelled-properties
        @include mq($screen-m) {
            grid-template-columns: 1fr 1fr;
            padding: 20px 30px 0;
        }
        &-col {
            padding: 29px 27px;
            background-color: $white;
            border-radius: $auth__border-radius;
        }
        &-col-title {
            margin: 0 0 20px;
            font-size: $font-size-extra-large;
            font-weight: $font-weight-bold;
            color: $gray-darker-secondary;
        }
    }

    &__button {
        font-size: $font-size-medium;
        border-radius: $auth__border-radius;
        text-transform: none;
    }

    &__input {
        border-radius: $auth__border-radius;
    }

    &__forgot-password {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        margin-top: 20px;
    }

    &__link {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $gray-darker;
        font-weight: $font-weight-normal;
        text-transform: none;

        &--secondary {
            font-weight: $font-weight-bold;

            &:before,
            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                width: 30%;
                height: 2px;
                background-color: $gray-lightest;

                @include mq($screen-m) {
                    width: 160px;
                }
            }

            &:after {
                left: auto;
                right: 0;
            }
        }
    }

    &__registration-description {
        margin-bottom: 25px;
        color: $black;
        text-align: left;
        line-height: 1.25;
    }

    &--password-recovery {
        .auth__form {
            display: block;
            background-color: $white;
            border-radius: $spacer;
            padding: 35px 30px;
            max-width: 596px;
            margin: $spacer--extra-large auto 0;

            .mage-error {
                color: $red;
                margin-top: $spacer;
                font-size: $font-size-extra-small;
            }
        }
    }
}
