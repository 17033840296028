$dashboard-document-details__columns-margin: $spacer--medium;
$dashboard-document-details__columns-margin--desktop: 0 $spacer--medium $spacer--medium $spacer--medium;
$dashboard-document-details__action-margin: $spacer--medium;
$dashboard-document-details__heading-margin--desktop: 0 0 0 $spacer--medium;

$dasboard-document-details__breakpoint: $screen-l;

.dashboard-document-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    &__heading {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: $spacer--large $spacer--medium 0;
        color: $font-color-base;
        font-size: 20px;
        font-family: $font-family-base;

        @include mq($dasboard-document-details__breakpoint) {
            display: flex;
            flex: 0 0 auto;
            font-size: 23px;
            color: $gray-darker;
            padding: 0;
            margin: $dashboard-document-details__heading-margin--desktop;
        }
    }

    &__actions {
        display: flex;
        flex-flow: row wrap;
        flex: 1 1 100%;
        order: 2;
        @include mq($dasboard-document-details__breakpoint) {
            flex: 0 0 auto;
            order: 1;
        }
    }

    &__columns {
        display: flex;
        flex-wrap: wrap;
        order: 1;
        width: 100%;
        margin: $dashboard-document-details__columns-margin;
        @include mq($dasboard-document-details__breakpoint) {
            margin: $dashboard-document-details__columns-margin--desktop;
            order: 2;
        }
    }

    &__action {
        display: flex;
        flex: 1 1 40%;
        justify-content: flex-start;
        align-items: center;
        text-decoration: none;
        background-color: transparent;
        height: 48px;
        padding: 0 $spacer--medium;

        &:hover,
        &:focus {
            text-decoration: none;
            background: none;
        }
        &:nth-child(n+2):before {
            display: none;
            @include mq($dasboard-document-details__breakpoint) {
                display: block;
                content: '';
                position: relative;
                left: -20px;
                height: 11px;
                width: 0;
                border-left: 1px solid $gray-lighter;
            }
        }
        &:nth-child(1) {
            @include mq($dasboard-document-details__breakpoint) {
                margin-left: $spacer--medium;
            }
        }
        @include mq($dasboard-document-details__breakpoint) {
            flex: 1 1 auto;
            margin: 0 $spacer--extra-large;
            padding: 0;
            justify-content: center;
        }
    }

    &__action-icon {
        width: 15px;
        fill: $color-primary;
    }

    &__action-label {
        font-size: $font-size-extra-small;
        font-family: $font-family-base;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $color-primary;
        font-weight: $font-weight-bold;
        white-space: nowrap;
    }

    &__action-button {
        padding: 0;
        &:hover {
            background-color: transparent;
        }
    }

    &__input {
        width: auto;
        height: 48px;
        padding: 0 $spacer--medium;
        border: 1px solid $border-color-base;
        &:disabled {
            border: 0;
            padding: 0;
            background-color: $white;
            color: $font-color-base;
        }
        @include mq($screen-m) {
            font-weight: $font-weight-bold;
        }
        &:not(:disabled) {
            // to overwrite directive input-autowidth
            min-width: 100px !important; // sass-lint:disable-line no-important
            @include mq($screen-l) {
                // to overwrite directive input-autowidth
                min-width: 200px !important; // sass-lint:disable-line no-important
            }
        }
    }

    &__input-button {
        background-color: $white;

        &:hover,
        &:focus {
            background-color: $white;
        }

        & > .icon {
            fill: $gray-light;
        }
    }

    &__list {
        flex: 1 1 100%;
        @include mq($dasboard-document-details__breakpoint) {
            flex: 1 1 33.333%;
        }
        &:nth-child(5) {
            @include mq($dasboard-document-details__breakpoint) {
                flex: 1 1 100%;
            }
        }
        &--editable {
            align-items: center;
            .dashboard-document-details__list-value {
                display: flex;
                flex-flow: row nowrap;
                max-width: 70%;
            }
        }
    }

    &__list-label {
        font-size: $font-size-base;
        font-weight: $font-weight-bold;
        color: $gray-darker;
        padding: 0 $spacer 0 0;
        line-height: 1.75;
        &[empty] {
            visibility: hidden;
        }
    }

    &__list-value {
        font-size: $font-size-base;
        font-weight: $font-weight-normal;
        color: $gray-dark;
        padding: 0;
        line-height: 1.75;
    }
}
