$color-disabled : $gray-lighter;

.section-navigation {
    display: block;
    position: relative;
    text-align: center;

    &.cms-page-view__section {
        padding: 80px 0 0 0;
    }

    @include mq($screen-s) {
        padding-top: $spacer--large;
    }

    &--border-top {
        border-top: $border-secondary;
    }

    &--no-links {
        padding-top: $spacer--large;
    }

    &__link {
        position: absolute;
        display: block;
        top: $spacer--large;
        padding: 0;

        &--back {
            padding-left: 48px;
            left: 0;

            .section-navigation__icon {
                left: 0;
            }
        }

        &--next {
            padding-right: 48px;
            right: 0;

            .section-navigation__icon {
                right: 0;
            }
        }

        &--disabled {
            &:hover,
            &:focus {
                text-decoration: none;
            }

            .section-navigation__icon {
                fill: $color-disabled;
            }

            .section-navigation__link-label {
                color: $color-disabled;
            }
        }
    }

    &__link-label {
        font-size: $font-size-medium;
        font-weight: $font-weight-normal;
        color: $gray-darker-secondary;
        text-transform: lowercase;
        line-height: 48px;
        display: block;
    }

    &__icon {
        display: block;
        position: absolute;
        top: 0;
        width: 48px;
        height: 48px;
        fill: $color-secondary;
        padding: 15px 19px;
    }

    &__button {
        margin: 0 $spacer--medium;
        text-align: center;
        font-size: $font-size-medium;
        color: $gray-darker-secondary;
        background-color: $gray-lightest;
        border-radius: 5px;

        @include mq($screen-m) {
            position: absolute;
            top: 15px;
            left: 0;
            right: 0;
            margin: 0 auto;
            max-width: 330px;
        }
    }
}
