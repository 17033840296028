$slider__margin                       : $spacer--medium !default;
$slider__transition                   : $transition-base !default;
$slider__item-width                   : 50% !default;
$slider__item-width\@medium           : 25% !default;
$slider__navigation-margin            : $spacer !default;
$slider__navigation-icon-size         : 48px !default;
$slider__navigation-icon-margin       : $spacer !default;
$slider__navigation-icon-background   : $white !default;
$slider__icon-size                    : 25px !default;
$slider__icon-fill                    : $color-primary !default;
$slider__icon-fill-hover              : darken($color-primary, 10%) !default;
$slider__dot-margin                   : $spacer--medium !default;
$slider__dot-padding                  : $spacer--medium !default;
$slider__dot-inside-size              : $spacer--medium !default;
$slider__dot-inside-background        : $gray-dark !default;
$slider__dot-inside-border-radius     : $spacer !default;
$slider__dot-inside-background--active: $color-primary !default;

.slider {
    margin-bottom: $slider__margin;
    position: relative;

    &__handler {
        overflow: hidden;
    }

    &__slides {
        display: flex;
    }

    &__item {
        position: relative;
        min-width: $slider__item-width;
        border: 0;

        @include mq($screen-m) {
            min-width: $slider__item-width\@medium;
        }
    }

    &__navigation {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: $slider__navigation-margin;
    }

    &__navigation-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: $slider__navigation-icon-size;
        width: $slider__navigation-icon-size;
        background-color: $slider__navigation-icon-background;
        border: 0;

        &:first-child {
            margin-right: $slider__navigation-icon-margin;
        }

        &:last-child {
            margin-left: $slider__navigation-icon-margin;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: $slider__navigation-icon-background;

            .slider__icon {
                fill: $slider__icon-fill-hover;
            }
        }
    }

    &__icon {
        width: $slider__icon-size;
        height: $slider__icon-size;
        fill: $slider__icon-fill;
        transition: $slider__transition;
        cursor: pointer;
    }

    &__dot-navigation {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        margin-top: $spacer;
    }

    &__dot {
        display: block;
        padding: $slider__dot-padding;
        margin-right: $slider__dot-margin;
        cursor: pointer;

        &--active {
            .slider__dot-inside {
                background-color: $slider__dot-inside-background--active;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }


    &__dot-inside {
        display: block;
        width: $slider__dot-inside-size;
        height: $slider__dot-inside-size;
        background-color: $slider__dot-inside-background;
        border-radius: $slider__dot-inside-border-radius;
    }
}
