.loyalty-contact {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 0 48px;
    margin: 0 0 48px;
    border-bottom: 1px solid #ededed;

    @include mq($screen-m) {
        align-items: center;
    }

    @include mq($screen-l) {
        flex-direction: row;
        padding: 0 0 15px;
    }

    &__wrapper {
        display: flex;
        align-items: center;
    }

    &__text {
        flex: 1;
        max-width: 800px;
        padding: 0 0 $spacer--extra-large 0;
        font-size: $font-size-medium;

        @include mq($screen-l) {
            padding: 0 $spacer--extra-large $spacer--extra-large 0;
        }

        h2 {
            margin: 0 0 24px;
            font-size: 32px;
            line-height: normal;

            @include mq($screen-m) {
                margin: 0 0 $spacer--medium;
            }
        }
    }

    &__icon {
        display: none;
        width: 63px;
        height: 63px;

        @include mq($screen-m) {
            display: flex;
            margin-right: 36px;
        }

        @include mq($screen-xl) {
            margin-right: 76px;
        }
    }

    &__button {
        min-width: 265px;
        border-radius: $spacer;
        background: $loyalty-orange;

        @include mq($screen-m) {
            min-width: 294px;
        }
    }
}
