.shopping-lists-view__table {
    tbody {
        td.dashboard-table__button {
            .shopping-lists-view__remove {
                width: auto;
                display: inline-flex;
            }
        }
        td.dashboard-table__cell--stock,
        td.dashboard-table__cell--remove {
            justify-content: flex-end;
            &:before {
                display: none;
            }
        }

        td.dashboard-table__cell--stock,
        td.dashboard-table__cell--qty-input {
            @include mq($screen-m) {
                padding: 0 $spacer--medium;
            }
            @include mq($screen-xl) {
                padding: 0 $spacer;
            }
            @include mq($screen-xxl) {
                padding: 0 $spacer--medium;
            }
        }
        td.dashboard-table__cell--remove {
            @include mq($screen-m) {
                .button__text {
                    display: none;
                }
            }
        }

        td.dashboard-table__cell--details {
            @include mq($screen-m) {
                width: 30%;
            }
            @include mq($screen-l) {
                width: 45%;
            }
        }

        td[data-th*="netto"],
        td[data-th*="brutto"] {
            white-space: nowrap;
            padding: 0 $spacer--medium;
        }

        tr.table__cell--child-content {
            .table__empty-heading:before {
                min-width: 0;
            }
            .child-table tr {
                background-color: $gray-lightest;
            }
        }
    }
    .cart-table-details {
        &__title {
            text-align: right;
            @include mq($screen-m) {
                text-align: left;
            }
        }

        &__description {
            display: none;
            @include mq($screen-m) {
                display: flex;
            }
        }
    }
    .badges {
        display: flex;
        flex-flow: column nowrap;
        .badge {
            margin-bottom: $spacer;
            justify-content: center;
        }
    }
}
