*:focus {
    outline: 0;
}

*,
*::after,
*::before {
    box-sizing: border-box;
}

html,
body {
    min-width: 320px;
    font-family: $font-family-base;
    font-weight: $font-weight-base;
    font-size: $font-size-base;
    color: $font-color-base;
    -webkit-font-smoothing: antialiased; // sass-lint:disable-line no-vendor-prefixes
    -moz-osx-font-smoothing: grayscale; // sass-lint:disable-line no-vendor-prefixes
    scroll-behavior: smooth;
}

p {
    margin: 0;
    padding: 0;
}

dl,
dt,
dd {
    margin: 0;
}
