.cart {
    &__actions {
        display: flex;
        justify-content: space-between;
        margin: $spacer--extra-large 0;
        padding: 0;
        .button {
            background: $white;
            color: $blue;
            justify-content: flex-start;
            @include mq($screen-m) {
                justify-content: center;
            }
            .icon {
                fill: $blue;
            }
        }
    }
}
