$input__margin-bottom                : $spacer;
$input__placeholder-color            : $gray-dark;

$input__label-height                 : 24px;

$input__field-height                 : 50px;
$input__field-padding                : $spacer--medium $spacer $spacer--medium $spacer--medium;
$input__field-border                 : 1px solid $form-elements-border-color;
$input__field-border-radius          : $form-elements-radius;

$input__min-height--textarea         : 100px;
$input__field-padding--textarea      : $spacer--medium;
$input__field-line-height--textarea  : $font-line-height;
$input__field-border-radius--textarea: $form-elements-radius--small;

.input {
    position: relative;
    margin-bottom: $input__margin-bottom;
    width: 100%;

    &--icon {
        position: relative;
    }

    &--floating-label {
        .input__label {
            font-weight: $font-weight-bold;
            color: $font-color-base;
            text-transform: uppercase;
            transform: scale(0.65) translateY(-12px);
            max-width: 100%;
        }

        .input__field {
            padding: $spacer--large $spacer--medium $spacer;

            &.input__field--textarea {
                padding-top: $spacer--extra-large;
            }
        }

        .label--hidden ~ .input__field {
            padding: $input__field-padding;
        }
    }

    &--label-top {
        .input__label {
            position: relative;
            left: 0;
            margin-bottom: $spacer--medium;
            top: 0;
        }
        .input__icon {
            top: 43px;
        }
    }

    &--required {
        .input__label {
            &:after {
                content: '*';
            }
        }
    }

    &--error {
        .input__field {
            border: 1px solid $color-danger;
        }
        .input__invalid-message {
            display: block;
            color: $color-danger;
            padding-left: 0;
            margin: 0;
        }
        .input__invalid-item {
            padding-top: $spacer;
            list-style: none;
        }
    }

    &--hidden-label {
        .input__label {
            @include visually-hidden();
        }
    }

    &--date-vue {
        // making vuejs-datepicker second input open from right-to left
        // to prevent it from overflowing screen
        // (invoice & orders views)
        &:nth-child(3) {
            .vdp-datepicker__calendar {
                right: 0;
            }
        }
        .input__icon {
            pointer-events: none;
        }
        .input__field {
            cursor: pointer;
            caret-color: transparent;
            padding: $spacer--medium 40px $spacer--medium $spacer--medium;
        }

        &.input--floating-label {
            .input__field {
                padding: $spacer--large $spacer--medium $spacer;
            }
        }
    }

    &__label {
        position: absolute;
        top: $spacer--large;
        left: $spacer--medium;
        height: $input__label-height;
        margin: auto;
        font-size: $spacer--large;
        line-height: $input__label-height;
        overflow: hidden;
        font-weight: $font-weight-thin;
        z-index: 1;
        text-transform: none;
        white-space: nowrap;
        transform-origin: 0 0;
        transform: scale(1);
        transition: transform 150ms;
    }

    &__message {
        display: block;
        color: $color-danger;
        margin-top: $spacer;

        & + .input__field {
            border: 1px solid $color-danger;
        }
    }

    &__field {
        display: block;
        width: 100%;
        height: $input__field-height;
        padding: $input__field-padding;
        font-size: $font-size-base;
        line-height: $spacer--extra-large;
        border-radius: $input__field-border-radius;
        background-clip: padding-box;
        outline: none;
        transition: border 0.3s ease;
        box-shadow: none;
        color: $input__placeholder-color;
        font-weight: $font-weight-thin;
        border: $input__field-border;

        // To hide all unnecesary placeholders
        &::placeholder {
            font-size: 0;
        }
        &:-webkit-autofill { // sass-lint:disable-line no-vendor-prefixes
            content: '\feff';
        }

        &[disabled] {
            background-color: $gray-lightest;
        }

        &--textarea {
            border-radius: $input__field-border-radius--textarea;
            min-width: 100%;
            max-width: 100%;
            min-height: $input__min-height--textarea;
            padding: $input__field-padding--textarea;
            line-height: $input__field-line-height--textarea;
        }
    }

    &__icon {
        position: absolute;
        right: $spacer--medium;
        top: 16px;
        width: $spacer--large;
        height: $spacer--large;
        fill: $gray-light;
        cursor: pointer;
    }

    &__error-msg {
        color: $color-danger;
        font-size: $font-size-extra-small;
        margin-bottom: $spacer;
    }
}

