.home-section-title {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin: 0 0 32px 0;

    @include mq($screen-m) {
        flex-direction: row;
        justify-content: space-between;
    }

    &__link {
        height: auto;
        padding: 0;
        text-transform: none;
        font-size: $font-size-medium;
        line-height: 1.375em;

        .icon {
            height: $spacer--medium;
            margin-left: $spacer--semi-large;
        }


        &:hover {
            background-color: transparent;

            .icon {
                fill: $color-primary--active;
            }
        }
    }

    &__heading {
        margin: 0 0 $spacer--medium 0;
        font-size: 26px;

        @include mq($screen-m) {
            margin: 0;
        }
    }
}
