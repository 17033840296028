.dashboard-stats {
    margin: 0 auto;
    font-size: $font-size-base;
    color: $gray-dark;
    @include mq($screen-l) {
        display: flex;
    }
    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-height: 250px;
        background: $white;
        @include mq($screen-l) {
            margin-right: $spacer--medium;
            flex-basis: 33.33%;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    &__container {
        min-height: 160px;
        width: 55%;
        @include mq($screen-l) {
            width: 80%;
        }
    }
    &__status {
        text-align: center;
        margin: 0 0 $spacer--medium;
        font-size: 25px;
        @include mq($screen-l) {
            font-size: 35px;
        }
    }
    &__list {
        padding-left: 0;
        list-style: none;
        margin-bottom: $spacer--extra-large;
    }
    &__label {
        font-weight: $font-weight-thin;
        padding-bottom: $spacer;

        &--red {
            color: $red;
        }

        span {
            font-weight: $font-weight-bold;
        }
    }
    &__text {
        font-weight: $font-weight-thin;
        margin-bottom: $spacer--medium;
    }

    .progress-bar {
        margin-bottom: $spacer--medium;
    }
}
