.date-notification {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    &__text {
        margin-right: $spacer;
    }

    .icon {
        width: 16px;
        height: 16px;
        fill: $gray-darker;
    }

    &--warning {
        color: $orange;
        .icon {
            fill: $orange;
        }
    }

    &--last {
        color: $color-danger;
        .icon {
            fill: $color-danger;
        }
    }

    &--outdated {
        color: $gray-light;
        .icon {
            fill: $gray-light;
        }
    }
}
