.notes {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    @include mq($screen-m) {
        flex-flow: row wrap;
    }
    &__trigger {
        background: $white;
        color: $blue;
        justify-content: flex-start;
        @include mq($screen-m) {
            justify-content: center;
        }
        .icon {
            fill: $blue;
        }
    }
    .note {
        width: 100%;
        margin-bottom: $spacer--large;
    }
}
