// Colors
$white                      : #fff !default;
$black                      : #000 !default;
$red                        : #e85b55 !default;
$orange                     : #fab216 !default;
$yellow                     : #ffd500 !default;
$green                      : #31e37d !default;
$blue                       : #2962ff !default;
$teal                       : #5bc0de !default;
$pink                       : #ff5b77 !default;
$purple                     : #613d7c !default;

// Grayscale colors
$gray-darkest               : #333333 !default;
$gray-darker                : #393243 !default;
$gray-dark                  : #4c4555 !default;
$gray                       : #73739c !default;
$gray-light                 : #c2c1df !default;
$gray-lighter               : #c9c9c9 !default;
$gray-lightest              : #f7f7f9 !default;

// Semantic color scheme
$color-primary              : $green !default;
$color-secondary            : $gray-darker !default;
$color-success              : $green !default;
$color-info                 : $teal !default;
$color-warning              : $orange !default;
$color-danger               : $red !default;
$color-inverse              : $gray-darker !default;

// Background
$bg-color-base              : $white !default;
$bg-color-dark              : $black !default;

// Border
$border-color-base          : $gray-darker !default;
$border-width-base          : 1px !default;
$border-style-base          : solid !default;

$border-base                : $border-width-base $border-style-base $border-color-base !default;

// Outline
$outline-base               : none !default;

// Spacers
$spacer                     : 8px !default;
$spacer--medium             : 16px !default;
$spacer--large              : 32px !default;
$spacer--extra-large        : 48px !default;

// Typography
$font-family-sans-serif     : "Montserrat", sans-serif !default;
$font-family-monospace      : Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;

$font-family-base           : $font-family-sans-serif !default;

$font-color-base            : $gray-darkest !default;
$font-weight-normal         : normal !default;
$font-weight-bold           : bold !default;
$font-weight-base           : $font-weight-normal !default;
$font-line-height           : 1.5 !default;

$font-size-extra-large      : 22px !default;
$font-size-large            : 18px !default;
$font-size-base             : 14px !default;
$font-size-small            : 12px !default;
$font-size-extra-small      : 10px !default;

// Layout
$max-content-width          : 1328px !default;

// Layout - breakpoints
$screen-xs                  : 320px !default;
$screen-s                   : 480px !default;
$screen-m                   : 768px !default;
$screen-l                   : 992px !default;
$screen-xl                  : 1200px !default;
$screen-xxl                 : 1600px !default;

// Components
$border-radius              : 4px !default;
$form-elements-radius       : 32px !default;
$form-elements-radius--small: 10px !default;
$form-elements-border-color : $gray !default;
$transition-base            : all 0.3s ease-in-out !default;
$transition-fade            : opacity 0.25s linear !default;
