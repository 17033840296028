$empty-content__min-height: 400px;

.empty-content {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: $empty-content__min-height;

    &__container {
        display: flex;
    }

    &__icon {
        height: 100px;
        width: 80px;
        fill: $gray-lighter;
        margin-right: $spacer--large;
    }

    &__text {
        display: flex;
        align-items: center;
        width: 200px;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        font-size: $font-size-extra-small;
        color: $gray-lighter;
        line-height: 20px;
    }

    &--horizontal {
        min-height: 500px;

        .empty-content__container {
            flex-direction: column;
            align-items: center;
        }

        .empty-content__text {
            width: 250px;
            text-align: center;
            font-size: 23px;
            line-height: 33px;
        }

        .empty-content__icon {
            height: 180px;
            width: auto;
            margin-bottom: $spacer--large;
            margin-right: 0;
        }
    }

    &--condensed {
        min-height: max-content;
        padding: $spacer--extra-large 0;
    }
}
