.notification-history-table-item {
    display: flex;
    align-items: flex-start;
    list-style: none;
    background-color: $gray-lightest;
    padding: $spacer--large $spacer--extra-large;
    margin-bottom: $spacer--large;
    @include mq($screen-xl) {
        align-items: center;
    }

    &__checkbox {
        flex: 0 0 auto;
    }
    &__data {
        display: flex;
        flex-direction: column;
        flex: 1 1;
        margin-left: $spacer;
        @include mq($screen-xl) {
            display: flex;
            flex-direction: row;
        }
    }
    &__title-1 {
        flex: 1;
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
        margin-bottom: $spacer--large;
        color: $gray-dark;
        @include mq($screen-xl) {
            margin-bottom: 0;
        }
    }
    &__title-2 {
        font-size: $font-size-medium;
        @include mq($screen-xl) {
            font-size: $font-size-medium;
            font-weight: $font-weight-bold;
        }
    }
    &[seen] {
        .notification-history-table-item__title-1 {
            font-weight: $font-weight-thin;
        }
    }
}
