.cms-advice-actions {
    @include mq($screen-m) {
        display: flex;
        justify-content: space-between;

        .button--fluid {
            width: inherit;
        }
    }
}
