$catalog-item__padding          : 0;

$catalog-item__font-size        : $font-size-small;
$catalog-item__font-size\@large : $font-size-base;

$catalog-item__border           : 1px solid #ededed;

.catalog-item {
    position: relative;
    display: flex;

    &--not-logged-in {
        .catalog--list & {
            .catalog-item {
                &__cta {
                    margin: 0;
                }

                &__cta-text {
                    white-space: nowrap;

                    @include mq($screen-xl) {
                        padding: 0 $spacer--large;
                    }
                }

                &__requisition-list {
                    @include mq($screen-xl) {
                        margin: 0;
                    }
                }
            }
        }
    }

    .catalog--grid & {
        flex-flow: column nowrap;
    }

    .catalog--list & {
        align-items: stretch;
        margin-bottom: $spacer--medium;
        padding: $spacer--medium;
        border-bottom: $catalog-item__border;

        @include mq($screen-xxl) {
            flex-flow: row nowrap;
        }
    }

    &__badges-wrapper {
        position: absolute;
        top: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 0 $spacer--extra-large;

        .catalog--grid &,
        .slider--products & {
            flex-direction: row-reverse;
        }

        .catalog--list & {
            flex-direction: column;

            .pim-icons {
                @include mq($screen-m) {
                    flex-direction: column;
                }
            }
        }
    }

    &__badges {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: $spacer; // sass-lint:disable-line no-misspelled-properties

        .catalog--list & {
            align-items: flex-start;
        }
    }

    &__cta {
        position: relative;
        margin: $spacer--medium 0 0;
        border-radius: $border-radius-secondary;

        &:hover,
        &:focus {
            .catalog-item__cta-icon {
                stroke: $color-primary;
            }
        }
    }

    &__cta-icon {
        stroke: $white;
    }

    &__cta-text {
        font-size: 11px;
        padding: 0 $spacer--medium;

        @include mq($screen-m) {
            padding: 0 $spacer--medium;
            font-size: 12px;
        }
    }

    &__link {
        .catalog--grid &,
        .slider--products & {
            padding: 0;
        }
    }

    &__image-wrapper {
        .catalog--list & {
            margin: 0 $spacer--medium 0 0;
            overflow: hidden;

            @include mq($screen-xl) {
                margin: 0 $spacer--medium 0 0;
            }
        }
    }

    &__image {
        mix-blend-mode: multiply;
        height: auto;

        .catalog--grid &,
        .slider--products & {
            width: 100%;
        }
    }

    &__top-wrapper {
        position: relative;

        .catalog--list & {
            display: flex;
            flex-flow: row nowrap;
            width: 40%;

            @include mq($screen-m) {
                width: auto;
            }

            @include mq($screen-xl) {
                padding-bottom: $spacer--medium;
                margin-bottom: $spacer--medium;
            }

            @include mq($screen-xxl) {
                border-bottom: 0;
                margin: 0;
                padding: 0;
            }
        }
        .catalog--grid.catalog--nophoto & {
            padding-top: $spacer--extra-large;
        }
    }

    &__list-wrapper {
        .catalog--list & {
            width: 60%;

            @include mq($screen-m) {
                width: 100%;
            }

            @include mq($screen-xl) {
                display: flex;
                justify-content: space-between;
            }
        }
    }

    &__name-wrapper {
        .catalog--grid &,
        .slider--products & {
            margin: 0;
            padding: $spacer--medium;
        }

        .catalog--list & {
            margin: 0 $spacer--medium 0 0;
            padding-bottom: $spacer--medium;

            @include mq($screen-m) {
                margin-bottom: 0;
                padding-bottom: $spacer--medium;
            }

            @include mq($screen-xxl) {
                padding: 0 $spacer--medium 0 0;
                border-bottom: none;
            }
        }

        .catalog--list.catalog--nophoto & {
            width: 100%;
        }
    }

    &__name {
        position: relative;

        .catalog--grid &,
        .slider--products & {
            line-height: 1.5;
            font-size: $catalog-item__font-size;
            font-weight: $font-weight-bold;

            .link {
                position: relative;
                color: $font-color-base;
                text-decoration: none;
                height: 1.5rem;
                overflow: hidden;

                &:before {
                    content: attr(data-name-small);
                    display: none;
                    position: relative;
                    font-size: $catalog-item__font-size;
                }

                @include mq($screen-s) {
                    &:before {
                        display: none;
                    }
                }
            }
        }

        .catalog--list & {
            font-weight: $font-weight-bold;
            font-size: $font-size-medium;
            line-height: 20px;
            .link {
                color: $font-color-base;
            }
        }
    }

    &__inner-value {
        font-weight: $font-weight-bold;
        white-space: nowrap;

        &.hint--html:hover.hint--top:hover,
        &.hint--html:focus.hint--top:focus {
            .hint__content {
                max-width: 200%;
                transform: translateX(-70%) translateY(-8px);
            }
        }
    }

    &__inner-label,
    &__inner-value {
        font-size: 12px;
    }

    &__label-icon {
        width: 14px;
        height: 14px;
        margin-left: $spacer;
        fill: $gray-light;
    }

    &__more-icon {
        width: 10px;
        height: 10px;
        margin-left: $spacer;
    }

    &__info {
        .catalog--grid &,
        .slider--products & {
            flex-grow: 10;
            padding: $spacer--medium;
            font-size: 14px;
            line-height: normal;
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;

            & > .list__item {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;

                &--action {
                    padding: 0;
                }
            }
        }

        .catalog--list & {
            font-size: 14px;
            line-height: 20px;
            justify-content: space-between;

            @include mq($screen-m) {
                margin-top: $spacer--medium;
                padding-right: $spacer--medium;
            }

            @include mq($screen-xl) {
                display: flex;
                flex-flow: column wrap;
                justify-content: space-between;
                padding: 0 $spacer--medium;
                margin-top: 0;
            }

            &-more {
                display: flex;
                align-items: center;
                font-weight: $font-weight-bold;
                font-size: 15px;
                color: $color-primary;
                cursor: pointer;
            }

            .list {
                padding: 0 0 $spacer--medium;

                &__item {
                    padding: 0;
                }
            }

            .catalog-item-stock-list {
                &__item-key {
                    font-size: 12px;
                }

                &__item-value {
                    &--availability,
                    &--in-stock {
                        color: #038c3e;
                        font-weight: $font-weight-bold;
                    }

                    &--out-of-stock {
                        color: #e51b2b;
                        font-weight: $font-weight-bold;
                    }
                }

                &__discount {
                    color: #e51b2b;
                    font-weight: $font-weight-bold;
                }
            }
        }
    }

    &__price-wrapper {
        display: flex;
        flex-direction: column;

        @include mq($screen-m) {
            gap: $spacer; // sass-lint:disable-line no-misspelled-properties
        }
    }

    &__price,
    &__omnibus {
        .catalog--grid &,
        .slider--products & {
            display: flex;
            flex-flow: row wrap;
            align-items: flex-end;
            justify-content: space-between;
            padding: $spacer--medium;
            font-size: 14px;
            line-height: 22px;

            @include mq($screen-xxl) {
                font-size: $catalog-item__font-size\@large;
            }

            &-label {
                display: flex;
                align-items: center;
                font-size: 14px;
                color: $gray;
                white-space: nowrap;
            }


            &-value {
                font-size: 16px;
                white-space: nowrap;
                font-weight: $font-weight-bold;
                text-transform: lowercase;
            }
        }

        .catalog--list & {
            display: flex;
            flex-flow: column wrap;

            @include mq($screen-m) {
                flex-flow: row nowrap;
                justify-content: flex-end;
                align-items: baseline;
                padding: 0 $spacer--extra-large;
            }

            &-label {
                display: flex;
                align-items: center;
                flex-flow: row nowrap;
                white-space: nowrap;
                margin-bottom: $spacer--medium;
                font-size: 12px;
                line-height: normal;

                @include mq($screen-m) {
                    margin: 0 $spacer 0 0;
                }
            }

            &-value {
                font-size: $font-size-extra-large;
                line-height: 1;
                font-weight: $font-weight-bold;
                color: $color-accent;
                margin: 0 0 $spacer--medium;

                @include mq($screen-m) {
                    margin: 0;
                }
            }

            &-value-details {
                text-transform: lowercase;
            }
        }
    }

    &__omnibus {
        .catalog--list & {
            &-label {
                color: $gray;

                @include mq($screen-m) {
                    font-size: 10px;
                }
            }

            &-value {
                position: relative;
                color: $color-primary--faded;
                font-size: $font-size-medium;
                width: fit-content;
            }

            &-hint {
                position: absolute;
                top: -2px;
                bottom: 0;
                right: -12px;
                cursor: pointer;

                .hint__content {
                    min-width: 200px;
                    text-transform: none;
                }

                .hint__icon {
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }

    &__stock {
        .catalog--grid &,
        .slider--products & {
            padding: $spacer--medium;
            border-top: $catalog-item__border;

            @include mq($screen-m) {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
            }
        }

        .catalog--list & {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            padding: $spacer--medium 0;
            border-bottom: $catalog-item__border;
            @include mq($screen-m) {
                width: 60%;
                padding-right: calc(10% + 10px);
                margin-top: $spacer--medium;
                border-bottom: 0;
                border-top: $catalog-item__border;
            }
            @include mq($screen-xl) {
                flex-flow: column nowrap;
                justify-content: center;
                width: 15%;
                padding: 0 $spacer--medium;
                margin-top: 0;
                border: 0 none;
                line-height: 22px;
            }
            @include mq($screen-xxl) {
                width: 13%;
            }
        }

        .catalog-item__more-icon {
            fill: $color-success;
        }

        // label for products in stock
        &-label {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: $font-weight-bold;
            color: $gray-light;
            text-transform: uppercase;
            white-space: nowrap;

            .catalog--list & {
                padding-bottom: $spacer;
                line-height: 12px;
                @include mq($screen-xl) {
                    margin-top: $spacer;
                }

                &:first-child {
                    cursor: pointer;
                }
            }
        }

        &-label--green {
            color: $color-success;
        }

        &-icon {
            color: $gray-light;
        }
    }

    &__actions {
        display: flex;
        width: 100%;
        margin-top: $spacer--medium;

        .catalog--grid &,
        .slider--products & {
            flex-wrap: wrap;
            padding: 0 $spacer--medium;
            margin-bottom: $spacer--medium;
        }

        .catalog--list & {
            flex-wrap: nowrap;

            @include mq($screen-xl) {
                width: 225px;
                align-items: center;
                margin-top: 0;
                border-top: 0;
            }
        }

        @include mq($screen-m) {
            flex-wrap: nowrap;
            margin-top: 0;
        }
    }

    &__actions-wrapper {
        position: relative;
        display: flex;
        width: 100%;

        .catalog-item__qty-input {
            input[type=number]::-webkit-inner-spin-button, // sass-lint:disable-line no-vendor-prefixes
            input[type=number]::-webkit-outer-spin-button { // sass-lint:disable-line no-vendor-prefixes
                opacity: 1;
            }
        }

        .catalog--list & {
            .catalog-item__qty-input {
                margin: 0 $spacer 0 0;
            }
        }
    }

    &__qty-input {
        .input__field {
            width: 54px;
            height: 44px;
            padding: 0 2px;
            text-align: center;

            &::placeholder {
                color: $font-color-base;
                font-weight: $font-weight-bold;
            }
            &[type="number"] {
                -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes
            }
        }

        .catalog--grid &,
        .slider--products & {
            position: static;
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            margin-bottom: $spacer--medium;

            @include mq($screen-m) {
                display: block;
                margin-bottom: 0;
                width: 54px;
            }

            .label {
                color: $gray;
                font-size: 14px;
                text-transform: none;
                @include mq($screen-m) {
                    @include visually-hidden($usePseudo: '');
                }
            }
        }

        .catalog--list & {
            display: block;
            margin-bottom: 0;
            width: 65px;

            .label {
                @include visually-hidden($usePseudo: '');
            }
        }
    }

    &__addto {
        display: flex;
        flex-flow: row nowrap;

        .catalog--grid &,
        .slider--products & {
            width: 100%;

            @include mq($screen-m) {
                width: calc(100% - 54px);
            }
        }

        .catalog--list & {
            width: 100%;

            @include mq($screen-xl) {
                width: 100%;
            }
        }


        button:disabled {
            opacity: 40%;
        }
    }

    &__primary-action {
        &:hover,
        &:focus {
            .icon {
                fill: $white;
            }
        }

        .icon {
            width: 15px;
            height: 15px;
            fill: $white;

            @include mq($screen-m) {
                width: 20px;
                height: 20px;
            }
        }

        .catalog--grid &,
        .slider--products & {
            height: 44px;
            padding: 0 $spacer;
            text-transform: uppercase;
            font-size: 11px;
            font-weight: $font-weight-bold;
            padding: 0 $spacer;
            flex-grow: 1;

            @include mq($screen-m) {
                font-size: 13px;
            }

            &:hover,
            &:focus {
                color: $white;
                background-color: $color-primary--active;
            }
        }

        .catalog--list & {
            width: 100%;
            min-width: 156px;
            height: 44px;
            padding: 0 $spacer;
            font-size: 13px;
            text-transform: uppercase;
            border-radius: $border-radius-secondary;

            &:hover,
            &:focus {
                color: $white;
                background-color: $color-primary--active;
            }

            .button__text {
                @include mq($screen-xl) {
                    padding: 0 $spacer--extra-large;
                }
            }
        }

        .button__text {
            margin: 0 !important; // sass-lint:disable-line no-important
            padding: 0 $spacer 0 0;
            font-size: 11px;
            line-height: normal;

            @include mq($screen-m) {
                font-size: 12px;
                padding: 0 $spacer--extra-large;
            }

            @include mq($screen-xl) {
                padding: 0 40px;
            }
        }
    }

    &__option-action {
        position: relative;
        width: 54px;
        height: 42px;
        background-color: $gray-lightest;
        border-radius: $border-radius-secondary;

        &:hover,
        &:focus {
            background-color: $color-primary;

            .icon {
                fill: $white;
            }
        }

        .icon {
            width: 18px;
            height: 21px;
            margin: 0 auto;
            fill: $black;
        }

        .catalog--grid &,
        .catalog--list & {
            width: 100%;
            color: $gray-darker;
            font-weight: $font-weight-base;
            text-transform: none;

            &:hover,
            &:focus {
                color: $white;
            }

            .icon {
                position: absolute;
                right: $spacer--medium;
            }
        }
    }

    .hint__content {
        min-width: 90%;
        max-width: 100%;
        white-space: normal;
        line-height: 1.3;
    }

    &__name-dialog {
        min-width: 90%;
        max-width: 200px;
        white-space: normal;
        line-height: 1.3;
    }

    &__name-dialog-trigger {
        display: inline-flex;
        position: absolute;
        bottom: 0;

        .data-dialog__trigger {
            padding: 0;
        }
    }

    .data-dialog__trigger-icon {
        margin-top: 2px;
    }

    &__details {
        display: flex;
        margin-top: $spacer--medium;
        flex-direction: column;

        @include mq($screen-m) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__info-attributes {
        @include mq($screen-m) {
            display: flex;
            gap: 20px; // sass-lint:disable-line no-misspelled-properties
        }
    }

    &__requisition-list-wrapper {
        position: relative;
    }

    &__requisition-list {
        position: relative;

        @include mq($screen-m) {
            position: absolute;
            bottom: 50px;
            width: 100%;
        }

        @include mq($screen-xl) {
            position: relative;
            bottom: auto;
            width: 100%;
            margin: 0 0 $spacer;
        }

        .catalog-requisition-lists {
            top: 48px;
        }
    }

    &__bottom-wrapper {
        @include mq($screen-m) {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            padding-bottom: $spacer--large;
        }
    }

    &--middle {
        background-color: transparent;
    }

    &--list {
        display: block;
    }

    &--limit-12 {
        @include item-position(12)
    }

    &--limit-15 {
        @include item-position(15)
    }

    &--limit-18 {
        @include item-position(18)
    }

    &--limit-32 {
        @include item-position(32)
    }

    &--limit-45 {
        @include item-position(45)
    }

    &--limit-72 {
        @include item-position(72)
    }

    &--limit-90 {
        @include item-position(90)
    }
}
